import React, { useState } from "react";
import * as S from "./style";

import Checkbox from "../../@DesignSystem/Checkbox";

import { ReactComponent as ShowIcon } from "./visibility.svg";
import { ReactComponent as HideIcon } from "./visibility_off.svg";

export default function FormInput({
  className,
  name,
  label,
  negative,
  big,
  short,
  center,
  placeholder,
  mt,
  mb,
  color = "primary",
  error,
  errorMsg,
  type = "text",
  password,
  readonly,
  width,
  small,
  ...props
}) {
  const [passVisible, setPassVisible] = useState(false);

  return (
    <S.Input
      className={className}
      $big={big}
      $short={short}
      $negative={negative}
      $center={center}
      $color={color}
      $mt={mt}
      $mb={mb}
      error={error}
      readonly={readonly}
      $type={type}
      width={width}
      small={small}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <div className="input">
        <input
          id={name}
          name={name}
          placeholder={placeholder}
          type={passVisible === true ? "text" : type}
          readonly={readonly}
          {...props}
        />
        {type === "password" && (
          <>
            <S.ShowPass onClick={() => setPassVisible(!passVisible)}>
              {passVisible ? <HideIcon /> : <ShowIcon />}
            </S.ShowPass>
          </>
        )}
      </div>
      {/* {type === "password" && (
        <Checkbox
          label="Mostrar senha"
          mt={16}
          checked={passVisible}
          onChange={() => setPassVisible(!passVisible)}
          size="small"
          bg="primary"
        />
      )} */}
      {error && <S.Error>{errorMsg}</S.Error>}
    </S.Input>
  );
}
