import React from "react";
import * as S from "./style";

import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as SystemMessageIcon } from "./announcement.svg";
import { ReactComponent as SuccessIcon } from "./check_circle.svg";
import { ReactComponent as ReportIcon } from "./report.svg";

export default function InlineMessage({ type = "default", iconColor, color, children, mt, mb, ml, mr, size = "default" }) {
  if (!iconColor) {
    iconColor =
      type === "success"
        ? "success"
        : type === "error"
        ? "danger"
        : type === "system"
        ? "amareloEscuro"
        : "primary";
  }

  return (
    <S.Message
      $color={color || "text"}
      $iconColor={iconColor}
      type={type}
      $mt={mt}
      $mb={mb}
      $ml={ml}
      $mr={mr}
      $size={size}
    >
      {type === "success" ? (
        <SuccessIcon />
      ) : type === "error" ? (
        <ReportIcon />
      ) : type === "system" ? (
        <SystemMessageIcon className="mt-extra" />
      ) : (
        <InfoIcon />
      )}
      {children}
    </S.Message>
  );
}
