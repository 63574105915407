import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { mask } from "../CriarVenda";
import CadastroService from "../../../services/CadastroService";
import VendaService from "../../../services/VendaService";
import UsuarioService from "../../../services/UsuarioService";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
} from "../../ToastNotification";
import moment from "moment";

import Modal from "../../Modal";
import LoadingIcon from "../../LoadingIcon";

import MoreButton from "../../@DesignSystem/MoreButton";

import SwitchButton from "../../@DesignSystem/SwitchButton";
import DSFormInput from "../../@DesignSystem/FormInput";
import DSButton from "../../@DesignSystem/Button";
import DSLink from "../../@DesignSystem/StyledLink";
import DSInput from "../../@DesignSystem/Input";

import { useInput } from "../../../hooks/useInput";
import Table from "../../@DesignSystem/Table";
import Dropdown from "../../@DesignSystem/Dropdown";

import AprovaTeste from "../AreaVendedores/AprovaTeste";

import { ReactComponent as BlockIcon } from "../../../icons/block.svg";
import { ReactComponent as DelayIcon } from "../../../icons/query_builder.svg";

import * as S from "./style";
import { AnimatePresence } from "framer-motion";

import ExpandRowComponent from "./ExpandRowComponent";
import CriarVenda from "./CriarVenda";

const client = axios.create({
  baseURL: process.env.REACT_APP_LICENSE_URL,
  headers: {
    "Content-Type": `application/x-www-form-urlencoded`,
  },
});

const planos = [
  {
    label: "Padrão",
    value: {
      usuarios: 3,
      capacidade: 10,
    },
  },
  {
    label: "Capacidade média",
    value: {
      usuarios: 5,
      capacidade: 30,
    },
  },
  {
    label: "Capacidade alta",
    value: {
      usuarios: 10,
      capacidade: 60,
    },
  },
  {
    label: "Personalizado",
    value: "Personalizado",
  },
];

export function AreaVendedores(props) {
  const columnsVendas = React.useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nomeEmpresa",
        stretch: 2,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.nomeEmpresa}
            <S.AditionalInfo>{row.email}</S.AditionalInfo>
          </>
        ),
        class: "tooltip",
        maxWidth: 600,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "CNPJ/CPF",
        accessor: "cnpj",
        tooltip: true,
        stretch: 1,
        maxWidth: 600,
        align: "right",
      },
      {
        Header: "Último login",
        accessor: "lastLoginDate",
        width: 120,
        sortDescFirst: true,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.lastLogin}
            <S.AditionalInfo>
              {row.numberOfLogins} logins realizados
            </S.AditionalInfo>
          </>
        ),
      },
      {
        Header: "Expira em",
        accessor: "restante",
        width: 100,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.expiresAt}
            {row.restante > 0 ? (
              <S.AditionalInfo>Em {row.restante} dias</S.AditionalInfo>
            ) : (
              <S.AditionalInfo $error>
                Expirado há {row.restante * -1} dias
              </S.AditionalInfo>
            )}
          </>
        ),
      },
      {
        Header: "Criado há",
        accessor: "createdAtDate",
        width: 100,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.createdAtFromNow}
            <S.AditionalInfo>{row.createdAt}</S.AditionalInfo>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "expired",
        sortType: "basic",
        id: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        align: "right",
        class: "td-actions",
        Cell: ({ row: { original: row } }) =>
          row.expired ? (
            <>
              <S.ExpiredPill>Inativo</S.ExpiredPill>
              <MoreButton>
                <button
                  onClick={() =>
                    reativarVenda(row.id, row.email, row.nomeEmpresa)
                  }
                >
                  Reativar venda
                </button>
              </MoreButton>
            </>
          ) : (
            <>
              <S.ActionButton
                onClick={() => bloquearTeste(row.id)}
                color="danger"
              >
                <BlockIcon />
              </S.ActionButton>
              <MoreButton>
                <button onClick={() => reenviaEmail(row.email)}>
                  Reenviar email
                </button>
              </MoreButton>
            </>
          ),
      },
    ],
    []
  );
  const columnsTestes = React.useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nomeEmpresa",
        stretch: 2,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.nomeEmpresa}
            <S.AditionalInfo>{row.email}</S.AditionalInfo>
          </>
        ),
        class: "tooltip",
        maxWidth: 600,
      },
      {
        Header: "CNPJ/CPF",
        accessor: "cnpj",
        tooltip: true,
        stretch: 1,
        maxWidth: 600,
        align: "right",
      },
      {
        Header: "Último login",
        accessor: "lastLoginDate",
        width: 120,
        sortDescFirst: true,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.lastLogin}
            <S.AditionalInfo>
              {row.numberOfLogins} logins realizados
            </S.AditionalInfo>
          </>
        ),
      },
      {
        Header: "Expira em",
        accessor: "restante",
        width: 100,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.expiresAt}
            {row.restante > 0 ? (
              <S.AditionalInfo>Em {row.restante} dias</S.AditionalInfo>
            ) : (
              <S.AditionalInfo $error>
                Expirado há {row.restante * -1} dias
              </S.AditionalInfo>
            )}
          </>
        ),
      },
      {
        Header: "Criado há",
        accessor: "createdAtDate",
        width: 100,
        Cell: ({ row: { original: row } }) => (
          <>
            {row.createdAtFromNow}
            <S.AditionalInfo>{row.createdAt}</S.AditionalInfo>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "expired",
        sortType: "basic",
        id: "actions",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        align: "right",
        class: "td-actions",
        Cell: ({ row: { original: row } }) =>
          row.expired ? (
            <>
              <S.ExpiredPill>Inativo</S.ExpiredPill>
              <MoreButton>
                <button
                  onClick={() =>
                    reativarVenda(row.id, row.email, row.nomeEmpresa)
                  }
                >
                  Reativar teste
                </button>
              </MoreButton>
            </>
          ) : (
            <>
              <S.ActionButton
                onClick={() => bloquearTeste(row.id)}
                color="danger"
              >
                <BlockIcon />
              </S.ActionButton>
              <S.ActionButton
                onClick={() =>
                  openModalProrroga(row.id, row.email, row.nomeEmpresa)
                }
                color="primary"
              >
                <DelayIcon />
              </S.ActionButton>
              <MoreButton>
                <button onClick={() => reenviaEmail(row.email)}>
                  Reenviar email
                </button>
              </MoreButton>
            </>
          ),
      },
    ],
    []
  );

  const openModalProrroga = (id, email, nomeEmpresa) => {
    setIsModalProrroga(true);
    setIdProrroga(id);
    setContatoProrroga(email);
    setEmpresaProrroga(nomeEmpresa);
  };

  const reenviaEmail = (email) => {
    UsuarioService.reenviarEmail(props.tokenLogin, email)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Email enviado com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        console.log(err);
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível reenviar o email
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const bloquearTeste = (id) => {
    store.addNotification({
      content: (
        <ToastNotification
          bg="primary"
          textoAuxiliar="Clique aqui para cancelar"
        >
          Bloqueando {isVendas ? "venda" : "teste"}!
        </ToastNotification>
      ),
      onRemoval: (idToast, removedBy) => {
        if (removedBy === "timeout") {
          VendaService.desativarVenda(id, props.tokenLogin)
            .then((resp) => {
              getTestes();
              store.addNotification({
                content: (
                  <ToastNotification bg="primary" textoAuxiliar="">
                    {isVendas ? "Venda" : "Teste"} bloqueado com sucesso!
                  </ToastNotification>
                ),
                onRemoval: (id, removedBy) => {
                  console.log(removedBy);
                },
                ...notificationTopRight,
              });
            })
            .catch((err) => {
              console.log(err);
              store.addNotification({
                content: (
                  <ToastNotification bg="secondary" textoAuxiliar="">
                    Falha ao bloquear {isVendas ? "venda" : "teste"}.
                  </ToastNotification>
                ),
                // onRemoval: (id, removedBy) => //console.log(removedBy),
                ...notificationTopRight,
              });
            });
        } else {
          store.addNotification({
            content: (
              <ToastNotification bg="primary" textoAuxiliar="">
                Bloqueio cancelado!
              </ToastNotification>
            ),
            ...notificationTopRight,
          });
        }
      },
      ...notificationTopRight,
    });
  };

  const handleClickProrrogar = () => {
    VendaService.adiarTeste(props.tokenLogin, idProrroga)
      .then((resp) => {
        // console.log(resp);
        getTestes();

        setqtdDiasProrroga("15");
        //success
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Teste prorrogado por {qtdDiasProrroga} dias!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        let msg = err.response.data.descricao;
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível prorrogar {isVendas ? "a venda" : "o teste"}.{" "}
              {msg ? msg : ""}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
    setIsModalProrroga(false);
  };

  const reativarVenda = (id, email, nomeEmpresa) => {
    setIsModalReativa(true);
    setIdProrroga(id);
    setContatoProrroga(email);
    setEmpresaProrroga(nomeEmpresa);
  };

  const rowSubComponent = ({ row }) => (
    <div className="content">
      <div>
        <p>
          <span className="label">Nome do contato: </span>
          {row.original.nome}
        </p>
      </div>
      <div>
        <p>
          <span className="label">Telefone: </span>
          {row.original.telefone}
        </p>
      </div>
      <div>
        <DSButton
          onClick={() =>
            getListaUsuarios(row.original.cnpjNoMask, row.original.id)
          }
          size="small"
          secondary
          color="primary"
          bg="primaryLight"
        >
          Mostrar detalhes
        </DSButton>
      </div>
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalProrroga, setIsModalProrroga] = useState(false);
  const [isModalReativa, setIsModalReativa] = useState(false);
  const [isModalConsulta, setIsModalConsulta] = useState(false);
  const [idProrroga, setIdProrroga] = useState("");
  const [contatoProrroga, setContatoProrroga] = useState("");
  const [empresaProrroga, setEmpresaProrroga] = useState("");
  const [dataProrroga, setDataProrroga] = useState("");

  const [idPesquisa, setIdPesquisa] = useState("");
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [maxUsuarios, setMaxUsuarios] = useState(0);
  const [slotUsuarios, setSlotUsuarios] = useState(0);
  const [capacidadeBd, setCapacidadeBd] = useState(0);
  const [capacidadeStorage, setCapacidadeStorage] = useState(0);
  const [tipoLogin, setTipoLogin] = useState("");

  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaLeitores, setListaLeitores] = useState([]);
  const [usuarioAdm, setUsuarioAdm] = useState([]);

  const [loading, setLoading] = useState(false);

  const [testes, setTestes] = useState([]);
  const [pedidosTestes, setPedidosTestes] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [isVendas, setIsVendas] = useState(true);
  const [criandoVenda, setCriandoVenda] = useState(false);
  const [filterExpired, setFilterExpired] = useState(null);
  const [temNovo, setTemNovo] = useState(0);

  // const [selectedRows, setSelectedRows] = useState([]);

  const { value: globalFilterValue, bind: bindGlobalFilter } = useInput(
    "",
    "",
    "alwaysValid"
  );

  const {
    value: qtdDiasProrroga,
    bind: bindqtdDiasProrroga,
    setValue: setqtdDiasProrroga,
  } = useInput("15", "", "vazio");

  useEffect(() => {
    let date = moment().add(qtdDiasProrroga, "days").calendar();
    setDataProrroga(date);
  }, [qtdDiasProrroga]);

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      setIsVendas(location.pathname.endsWith("vendas"));
    });
  }, [history]);

  useEffect(() => {
    setIsVendas(props.isVendas);

    getListaTestes();
    getTestes();
    getListaVendas();
    let dateToday = moment().format();
    localStorage.setItem("lastLoginOpen", dateToday);

    return () => {
      let dateToday = moment().format();
      localStorage.setItem("lastLoginClose", dateToday);
    };
  }, []);

  useEffect(() => {
    if (isVendas) getListaVendas();
    else getTestes();
  }, [isVendas]);

  const getListaVendas = () => {
    VendaService.getListaVendas(0, props.tokenLogin)
      .then((resp) => {
        // console.log(resp);
        let vendasFormated = resp.data.vendas.map((venda) => ({
          id: venda.venda_id,
          email: venda.contato,
          nome: venda.nome_contato,
          nomeEmpresa: venda.empresa,
          cnpj: mask.cnpj(venda.cnpj),
          cnpjNoMask: venda.cnpj,
          lastLoginDate:
            venda.ultimo_login === "Nunca"
              ? "-"
              : moment(venda.ultimo_login, "DD/MM/YYYY hh:mm").format(),
          lastLogin:
            venda.ultimo_login === "Nunca"
              ? "-"
              : moment(venda.ultimo_login, "DD/MM/YYYY hh:mm").fromNow(),
          numberOfLogins: venda.logins,
          restante: venda.restante,
          expiresAt: moment().add(venda.restante, "days").format("DD/MM/YYYY"),
          createdAt: moment(venda.dt_ini).format("DD/MM/YYYY"),
          createdAtFromNow: moment(venda.dt_ini).fromNow(),
          createdAtFromDate: moment(venda.dt_ini).format(),
          expired: venda.status !== "Ativada",
          telefone: "11111111",
          // telefone: mask.telEmpresa(venda.telefone),
        }));

        // console.log(vendasFormated);

        setVendas(vendasFormated);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTestes = () => {
    VendaService.getListaVendas(1, props.tokenLogin)
      .then((resp) => {
        console.log(resp);
        let testes = resp.data.vendas
          .filter((venda) => venda !== null || venda !== undefined)
          .map((venda) => ({
            id: venda.venda_id,
            email: venda.contato,
            nome: venda.nome_contato,
            nomeEmpresa: venda.empresa,
            cnpj: mask.cnpj(venda.cnpj),
            cnpjNoMask: venda.cnpj,
            // 04/02/2022 12:02
            lastLoginDate:
              venda.ultimo_login === "Nunca"
                ? "-"
                : moment(venda.ultimo_login, "DD/MM/YYYY hh:mm").format(),
            lastLogin:
              venda.ultimo_login === "Nunca"
                ? "-"
                : moment(venda.ultimo_login, "DD/MM/YYYY hh:mm").fromNow(),
            numberOfLogins: venda.logins,
            restante: venda.restante,
            expiresAt: moment().add(venda.restante, "days").format("L"),
            createdAt: moment(venda.dt_ini).format("DD/MM/YYYY"),
            createdAtFromNow: moment(venda.dt_ini).fromNow(),
            createdAtFromDate: moment(venda.dt_ini).format(),
            expired: venda.status !== "Ativada",
            telefone: "11111111",
            adiado: venda.adiado,
            // telefone: mask.telEmpresa(venda.telefone),
          }));
        // console.log(testes);
        setTestes(testes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListaTestes = () => {
    CadastroService.getCadastrosTeste(props.tokenLogin)
      .then((res) => {
        let novos = 0;
        let testesList = res.data.cadastros.map((cadastro) => {
          if (
            moment(cadastro.createdAt).isAfter(
              localStorage.getItem("lastLoginClose")
            )
          ) {
            novos++;
          }

          return {
            id: cadastro._id,
            email: cadastro.email,
            cnpj: mask.cnpj(cadastro.cnpj),
            cnpjNoMask: cadastro.cnpj,
            nome: cadastro.empresa,
            nomeEmpresa: cadastro.razao_social,
            telefone: mask.telEmpresa(cadastro.telefone),
            linkedin: cadastro.linkedin,
            date: cadastro.createdAt,
            novo: moment(cadastro.createdAt).isAfter(
              localStorage.getItem("lastLoginClose")
            ),
            // repetido: cadastro.repetido,
          };
        });
        setTemNovo(novos);
        setPedidosTestes(testesList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListaUsuarios = (cnpj, idVenda) => {
    toggleModalConsulta();
    setLoading(true);
    VendaService.getVenda(cnpj, props.tokenLogin)
      .then((res) => {
        console.log(res);
        setNomePesquisa(res.data.venda.razao_social);
        setMaxUsuarios(res.data.venda.max_usuarios);
        setCapacidadeBd(res.data.venda.capacidade_db);
        setCapacidadeStorage(res.data.venda.capacidade_storage);
        setTipoLogin(res.data.venda.descricao_sso);

        setSlotUsuarios(
          res.data.venda.max_usuarios -
            (res.data.venda.contatos.length +
              res.data.venda.comuns.length +
              res.data.venda.leitores.length)
        );
        setListaUsuarios(res.data.venda.comuns);
        setListaLeitores(res.data.venda.leitores);
        // console.log([...res.data.venda.comuns, ...res.data.venda.leitores]);
        setUsuarioAdm(res.data.venda.contatos);

        VendaService.getUsuariosVenda(idVenda, props.tokenLogin)
          .then((listas) => {
            console.log(listas);
          })
          .catch((err) => {
            console.log(err);
          });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleModal = () => {
    let dateToday = moment().format();
    localStorage.setItem("lastLoginClose", dateToday);
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalProrroga = () => {
    setIsModalProrroga(!isModalProrroga);
  };

  const toggleModalReativa = () => {
    setIsModalReativa(!isModalReativa);
  };

  const toggleModalConsulta = () => {
    setIsModalConsulta(!isModalConsulta);
  };

  const handleChangeView = (index) => {
    setIsVendas(index === 0);
  };

  return (
    <S.Wrapper>
      {isModalOpen && (
        <Modal modalSize="lg" isOpen={isModalOpen} onClose={toggleModal}>
          <S.PedidosWrapper>
            <h3>Pedidos de teste</h3>
            {/* <Dropdown
              placeholder="Seleciona o filtro"
              onChange={(selectedOption) => console.log(selectedOption.value)}
              options={[
                { label: "Todos os testes", value: 1 },
                { label: "Testes ativos", value: 2 },
                { label: "Testes inativos", value: 3 },
              ]}
            /> */}
            {pedidosTestes.length > 0 ? (
              <S.Pedidos layout>
                {pedidosTestes.map((pedido) => (
                  <AprovaTeste
                    tokenLogin={props.tokenLogin}
                    pedido={pedido}
                    refreshLista={getListaTestes}
                    key={pedido.id}
                  />
                ))}
              </S.Pedidos>
            ) : (
              <p>Nenhum pedido de teste para aprovar</p>
            )}
          </S.PedidosWrapper>
        </Modal>
      )}
      {isModalProrroga && (
        <Modal
          modalSize="md"
          isOpen={isModalProrroga}
          onClose={toggleModalProrroga}
        >
          <S.ModalProrroga>
            <h3>Prorrogar teste</h3>
            <div className="info-prorroga">
              <p>
                Contato: <span>{contatoProrroga}</span>
              </p>
              <p>
                Empresa: <span>{empresaProrroga}</span>
              </p>
            </div>
            <div className="flex">
              <p>Prorrogar por </p>
              <DSFormInput
                required
                name="prorroga-teste-dias"
                width={48}
                small
                readonly
                // {...bindqtdDiasProrroga}
                value={15}
              />
              <p>dias</p>
            </div>
            <p>Teste prorrogado até: {dataProrroga}</p>
            <div className="buttons">
              <DSButton fluid fullWidth onClick={handleClickProrrogar}>
                Prorrogar teste
              </DSButton>
            </div>
          </S.ModalProrroga>
        </Modal>
      )}
      {isModalReativa && (
        <Modal
          modalSize="md"
          isOpen={isModalReativa}
          onClose={toggleModalReativa}
        >
          <S.ModalProrroga>
            <h3>Reativar {isVendas ? "venda" : "teste"}</h3>
            <div className="info-prorroga">
              <p>
                Contato: <span>{contatoProrroga}</span>
              </p>
              <p>
                Empresa: <span>{empresaProrroga}</span>
              </p>
            </div>
            <div className="flex">
              <p>Reativar por </p>
              <DSFormInput
                required
                name="prorroga-teste-dias"
                width={48}
                small
                readonly
                // {...bindqtdDiasProrroga}
                value={15}
              />
              <p>dias</p>
            </div>
            <p>
              {" "}
              {isVendas ? "Venda reativada " : "Teste reativado "}até:{" "}
              {dataProrroga}
            </p>
            <div className="buttons">
              <DSButton fluid fullWidth onClick={handleClickProrrogar}>
                Reativar {isVendas ? "venda" : "teste"}
              </DSButton>
            </div>
          </S.ModalProrroga>
        </Modal>
      )}
      {isModalConsulta && (
        <Modal
          modalSize="md"
          isOpen={isModalConsulta}
          onClose={toggleModalConsulta}
        >
          <S.ModalConsulta>
            {loading && (
              <S.LoadingBox>
                <LoadingIcon big></LoadingIcon>
              </S.LoadingBox>
            )}
            <>
              <h3>Detalhes {isVendas ? "da venda" : "do teste"}</h3>
              <div className="info">
                <p>
                  Empresa: <b>{nomePesquisa}</b>
                </p>
                <p>
                  Máx. de usuários: <b>{maxUsuarios} usuários</b> (
                  {maxUsuarios -
                    (1 + listaUsuarios.length + listaLeitores.length)}{" "}
                  slots disponíveis)
                </p>
                <p>Capacidade do banco de dados: {capacidadeBd} GB</p>
                <p>Capacidade do storage: {capacidadeStorage} GB</p>
                <p>Tipo de login da venda: {tipoLogin}</p>
              </div>
              <h5>Contato</h5>
              {usuarioAdm.map((user, index) => (
                <S.UsuariosVenda contato key={index}>
                  {user}
                </S.UsuariosVenda>
              ))}
              <h5>Escritores</h5>
              {listaUsuarios.map((user, index) => (
                <S.UsuariosVenda key={index}>{user}</S.UsuariosVenda>
              ))}
              <h5>Leitores</h5>
              {listaLeitores.map((user, index) => (
                <S.UsuariosVenda key={index}>{user}</S.UsuariosVenda>
              ))}
            </>
          </S.ModalConsulta>
        </Modal>
      )}
      <S.Header $vendas={isVendas}>
        <h2>
          Área de vendedor <span>{isVendas ? "Vendas" : "Testes"}</span>
        </h2>
        {!isVendas && (
          <S.PedidosButton
            $temNovo={temNovo}
            onClick={() => setIsModalOpen(true)}
          >
            {temNovo > 0 && (
              <span className="novo">{`${temNovo} ${
                temNovo > 1 ? "novos" : "novo"
              }`}</span>
            )}
            Pedidos de teste{" "}
            <span className="numPedidos">{pedidosTestes.length}</span>
          </S.PedidosButton>
        )}
      </S.Header>
      {/* <SwitchButton
        variant={1}
        selection={isVendas ? 0 : 1}
        handleChange={handleChangeView}
        options={["Vendas", "Testes"]}
      /> */}
      <AnimatePresence>
        {criandoVenda && (
          <CriarVenda
            tokenLogin={props.tokenLogin}
            teste={!isVendas}
            closeCriar={() => setCriandoVenda(false)}
            refreshListaTestes={getTestes}
            refreshListaVendas={getListaVendas}
          />
        )}
      </AnimatePresence>
      <S.TableSection>
        {criandoVenda ? (
          <></>
        ) : isVendas ? (
          <DSButton onClick={() => setCriandoVenda(true)}>
            Criar nova venda
          </DSButton>
        ) : (
          <DSButton onClick={() => setCriandoVenda(true)} bg="secondary">
            Criar novo teste
          </DSButton>
        )}
        <DSFormInput
          required
          width={320}
          name="pesquisaVenda"
          placeholder={`Pesquisar ${isVendas ? "venda" : "teste"}`}
          {...bindGlobalFilter}
        />
        {isVendas ? (
          <Dropdown
            className="alignLeft"
            placeholder="Seleciona o filtro"
            defaultValue={{ label: "Todas as vendas", value: null }}
            onChange={(selectedOption) =>
              setFilterExpired(selectedOption.value)
            }
            options={[
              { label: "Todos as vendas", value: null },
              { label: "Vendas ativas", value: false },
              { label: "Vendas inativas", value: true },
            ]}
          />
        ) : (
          <Dropdown
            className="alignLeft"
            placeholder="Seleciona o filtro"
            defaultValue={{ label: "Todos os testes", value: null }}
            onChange={(selectedOption) =>
              setFilterExpired(selectedOption.value)
            }
            options={[
              { label: "Todos os testes", value: null },
              { label: "Testes ativos", value: false },
              { label: "Testes inativos", value: true },
            ]}
          />
        )}
      </S.TableSection>
      {isVendas ? (
        <>
          <Table
            globalFilterValue={globalFilterValue}
            filterValue={[{ id: "expired", value: filterExpired }]}
            expiredRows
            expandRows
            propsColumns={columnsVendas}
            data={vendas}
            rowSubComponent={rowSubComponent}
            hiddenColumns={["email"]}
            // selectRows
            // setSelectedRows={(value) => setSelectedRows(value)}
          />
        </>
      ) : (
        <Table
          globalFilterValue={globalFilterValue}
          filterValue={[{ id: "expired", value: filterExpired }]}
          expiredRows
          expandRows
          propsColumns={columnsTestes}
          data={testes}
          rowSubComponent={rowSubComponent}
          hiddenColumns={["email"]}
          // selectRows
          // setSelectedRows={(value) => setSelectedRows(value)}
        />
      )}
    </S.Wrapper>
  );
}
