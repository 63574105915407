import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  cursor: pointer;
  color: ${(p) => p.$active ? p.theme.text : "#909090"};

  &:hover {
    color: ${(p) => p.theme.text};
  }
`;

export const Tooltip = styled(motion.div)`
  position: absolute;
  z-index: 1111;
  top: -12px;
  right: 0;
  background: ${(p) => p.theme.text};
  padding: 4px 16px;
  border-radius: 4px;

  & button {
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    white-space: nowrap;
    text-align: left;
    padding: 8px 4px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  & button:last-child {
    border-bottom: none;
  }
`;
