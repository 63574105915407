import React from "react";
import styled from "styled-components/macro";

import NotificationBubble from "./NotificationBubble";
import Button from "../Button";

import { ReactComponent as CancelIcon } from "../../icons/cancel-icon.svg";

import moment from "moment";
import "moment/locale/pt-br";

const NotificationContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 304px;
  border: none;
  box-shadow: 0px 3px 10px #00000040;
  background: #f3f3f3;
  /* display: ${(props) => (props.active ? "block" : "none")}; */
  transform: ${(props) =>
    props.active ? "translateX(0)" : "translateX(100%)"};
  transition: all 200ms ease-in-out;

  .notification-wrapper {
    height: 100vh;
    overflow: auto;
    padding: 1.25rem;
    box-sizing: border-box;

      /* The emerging W3C standard
   that is currently Firefox-only */
   scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.grayDark} ${(props) => props.theme.grayLight};;

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.gray};
    border-radius: 20px;
    border: 2px solid ${(props) => props.theme.grayLight};
  }

    .footer {
      button {
        width: 100%;
      }
    }
  }
`;

const CloseNotification = styled.button`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  position: absolute;
  top: 1em;
  left: -3em;
  font-size: 1rem;
  text-align: center;
  color: #ffffff85;
  cursor: pointer;
  transition: all 0.3s ease;
  background: black;
  border: none;
  box-shadow: 0px 3px 6px #00000040;
  display: ${(props) => (props.active ? "block" : "none")};

  &:hover {
    color: #ffffff;
  }
`;

const HeaderNotification = styled.h4`
  margin-bottom: 0.75em;
  font-size: 2.375rem;
  text-align: center;
`;

export class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick() {
    this.props.closeNotifications();
  }

  handleDeleteAll = () => {
    this.props.deleteAllnotifications();
  }

  handleDeleteNotification = (id) => {
    this.props.deleteNotificaton(id);
  }
  
  convertTimestamp = (time) => {
    let formatedTime = moment(time);
    formatedTime.locale("pt-br");
    return formatedTime.calendar();
  };

  render() {
    return (
      <NotificationContainer active={this.props.active}>
        <CloseNotification
          active={this.props.active}
          onClick={this.handleClick}
        >
          <CancelIcon className="icon" />
        </CloseNotification>
        <div className="notification-wrapper">
          <HeaderNotification>Notificações</HeaderNotification>
          {this.props.notificationsList
            .filter((element) => (!element.visualizado && element.descricao.msg !== "Mensagem não definida" && typeof element.descricao.msg === "string"))
            .map((notification) => (
              <NotificationBubble
                key={notification._id}
                type={notification.erro ? "error" : "success"}
                tempo={this.convertTimestamp(notification.createdAt)}
                handleClick={() => {
                  this.handleDeleteNotification(notification._id);
                }}
              >
                {notification.descricao.msg}
              </NotificationBubble>
            ))}
          <div className="footer">
            <Button
              className="limpa"
              invert
              background="danger"
              onClick={this.handleDeleteAll}
            >
              Limpar tudo
            </Button>
          </div>
        </div>
      </NotificationContainer>
    );
  }
}
