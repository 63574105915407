import { motion } from "framer-motion";
import styled from "styled-components";

export const Pedido = styled(motion.div)`
  background: #ffffff;
  border: 1px solid #dae2e9;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 32px;
  position: relative;

  .novo {
    position: absolute;
    top: 0;
    left: 0;
    background: ${(p) => p.theme.primary};
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    padding: 2px 4px;
    transform: translate(-25%, -50%);
  }

  .header {
    display: flex;
    align-items: center;
    padding: 16px 24px;

    p.title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: ${(p) => p.theme.text};
    }

    p.text {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: ${(p) => p.theme.text};
      margin-left: auto;
      flex-shrink: 0;
    }

    .pill {
      padding: 4px 12px;
      background: #ffd6dd;
      border-radius: 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #cc2241;
      margin-left: 24px;
      margin-right: 16px;
    }
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .dados {
      color: #5a5c63;
      font-size: 16px;
      line-height: 20px;

      p {
        margin-bottom: 4px;
      }
    }
  }

  .links {
    display: flex;
    gap: 32px;
    padding: 16px 24px;
  }
`;

export const FooterAprova = styled(motion.div)`
  padding: 16px;
  background: #f7f9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCria = styled(motion.div)`
  padding: 16px;
  background: #f7f9fa;

  .body-cria {
    padding: 16px;

    .info {
      display: flex;
      margin-top: 16px;

      p {
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.65);
      }

      p:last-child {
        margin-left: 8px;
        font-weight: 600;
      }

      p.extra-margin {
        margin-left: 34px;
      }

      &.no-margin {
        margin-top: 8px;
      }
    }

    .flex-nao-criar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .editData {
      margin: 16px 0;
    }

    .info-edit {
      display: flex;
      margin-top: 16px;

      p:first-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: ${(p) => p.theme.text};
      }

      p:nth-child(2) {
        margin-left: 8px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
`;

export const EditButton = styled.button`
  border: none;
  background: #dcebf7;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${(p) => p.theme.primary};
  cursor: pointer;
  padding: 0;
  margin-left: 16px;
`;

export const AddUsuarios = styled.div`
  display: flex;
  align-items: flex-end;
  /* justify-content: space-between; */

  button {
    margin-left: 32px;
  }
`;
