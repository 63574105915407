import React, { useState } from "react";
import RadioInput from "./RadioInput";

import * as S from "./style";

export default function Radio(props) {
  const [selectedInput, setSelectedInput] = useState(props.selected || "");

  const handleChange = (inputValue) => {
    setSelectedInput(inputValue);
    props.handleChangeParent(inputValue);
  };

  return (
    <S.RadioGroup $size={props.size} $mt={props.mt || 0}>
      <p>{props.children}</p>
      <div className="radio-buttons">
        {props.options.map((option) => (
          <RadioInput
            size={props.size}
            key={option.value}
            name={props.name}
            value={option.value}
            label={option.label}
            isChecked={selectedInput == option.value}
            handleChange={handleChange}
          />
        ))}
      </div>
    </S.RadioGroup>
  );
}
