import React from "react";
import styled from "styled-components";

const Button = styled.button`
  grid-column: 1;
  width: ${(props) => (props.big ? "64px" : "56px")};
  height: ${(props) => (props.big ? "64px" : "56px")};
  border-radius: 32px;
  margin: 0;
  cursor: pointer;
  border: none;
  background: ${(props) =>
    props.white
      ? props.theme.background
      : (props.background === "primary" && props.theme.primary) ||
        (props.background === "gray" && props.theme.gray) ||
        (props.background === "danger" && props.theme.danger) ||
        (props.background === "success" && props.theme.success)};
  color: ${(props) =>
    props.white
      ? props.theme.text
      : (props.background === "primary" && props.theme.background) ||
        (props.background === "danger" && props.theme.text) ||
        (props.background === "success" && props.theme.text)};
  box-shadow: ${(p) =>
    p.white
      ? "0px 2px 4px rgba(117, 150, 166, 0.08), 0px 8px 32px -8px rgba(10, 29, 52, 0.15)"
      : "0px 2px 4px rgba(117, 150, 166, 0.25), 0px 8px 32px -8px rgba(10, 29, 52, 0.5)"};
  white-space: nowrap;
  font-size: 28px;
  text-align: center;
  position: relative;
  transition: all 250ms ease-in-out;

  img {
    height: 32px;
    margin-top: 12px;
    margin-left: 0;
    filter: ${(props) =>
      props.white
        ? "invert(0%)"
        : (props.background === "primary" && "invert(100%)") ||
          (props.background === "danger" && "invert(0%)") ||
          (props.background === "success" && "invert(0%)")};
  }

  &::after {
    content: "${(props) => props.texto}";
    display: block;
    background: ${(props) => props.theme.background};
    position: absolute;
    right: ${(props) => (props.big ? "5rem" : "4rem")};
    top: ${(props) => (props.big ? "1rem" : ".75rem")};
    font-size: ${(props) => (props.big ? "1.3125rem" : "1.25rem")};
    padding: 0.25rem 0.5rem;
    color: ${(props) => props.theme.text};
    opacity: 0;
    visibility: hidden;
    transform: translateX(25%);
    transition: all 250ms ease-in;
    box-shadow: 0px 3px 6px #00000025;
    z-index: -1;
  }

  &:hover {
    background: ${(props) =>
      props.white
        ? props.background === "success"
          ? props.theme.success
          : props.background === "danger"
          ? props.theme.danger
          : props.theme.primary
        : props.theme.background};
    background: ${(props) =>
      props.white
        ? (props.background === "primary" && props.theme.primary) ||
          (props.background === "danger" && props.theme.danger) ||
          (props.background === "gray" && props.theme.grayLight) ||
          (props.background === "success" && props.theme.success)
        : props.theme.white};
    color: ${(props) =>
      props.background === "primary"
        ? props.white
          ? props.theme.background
          : props.theme.text
        : props.theme.text};

    img {
      filter: ${(props) =>
        props.background === "primary" ? "invert(100%)" : "invert(0%)"};
    }
  }

  &:hover::after,
  &:focus::after {
    z-index: -1;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    display: block;
  }
`;

const NewBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 16px;
  transform: translate(0%);
`

const FloatButton = (props) => (
  <Button
    onClick={props.onClick}
    big={props.big}
    background={props.background}
    white={props.white}
    texto={props.texto}
    newBadge={props.new}
  >
    {props.new && <NewBadge />}
    {props.children}
  </Button>
);

export default FloatButton;
