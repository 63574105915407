import styled from "styled-components";

export const Label = styled.label`
  transition: all 150ms ease-in-out;
  display: block;
  color: ${(p) => p.theme.textGray};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;
