import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import App from "./App";
import Home from "./Home";
import Manutencao from "./components/Manutencao";
import Teste from "./TesteCadastro";

import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { GlobalStyle, themeDefault } from "./theme/GlobalStyle";
import styled, { ThemeProvider } from "styled-components/macro";

import GerenciaTokenCliente from "./services/config/GerenciaTokenCliente";
import UsuarioService from "./services/UsuarioService";

import mixpanel from "mixpanel-browser";
import VendaMarketplace from "./components/VendaMarketplace";
import Status from "./Status";

let screen = null;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      isAdm: false,
      tokenLogin: "",
      tentouLogin: false,
      usuario: "",
    };
    this.doLogin = this.doLogin.bind(this);
  }

  componentDidMount() {
    mixpanel.init("b0e23bded11ea4857ace89778a304db0");
    GerenciaTokenCliente.verificaUserToken().then((userToken) => {
      //console.log(userToken);
      if (userToken) {
        UsuarioService.validarTokenLogin(userToken)
          .then((res) => {
            this.doLogin(userToken, res.data.email);
            mixpanel.track("Log in", {
              email: res.data.email,
            });
          })
          .catch((err) => {
            this.setState({ tentouLogin: true });
          });
        UsuarioService.verficaPermissao(userToken)
          .then((resp) => {
            // alert(resp.data.permissao);
            if (resp.data.permissao === "vendedor") {
              this.setState({
                isAdm: true,
              });
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      } else {
        this.setState({
          tentouLogin: true,
        });
      }
    });
  }

  doLogin(token, email = "") {
    if (this.state.isLogged) {
      GerenciaTokenCliente.limpaToken().then((resp) => {
        // console.log("limpou token");
      });
      this.setState({
        usuario: "",
      });
      if (window.intervalNotification) {
        //console.log("limpa loop das notificações");
        clearInterval(window.intervalNotification);
      }
    }
    UsuarioService.verficaPermissao(token)
      .then((resp) => {
        // alert(resp.data.permissao);
        if (resp.data.permissao === "vendedor") {
          this.setState({
            isAdm: true,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });

    this.setState((prevState, prevProps) => ({
      isLogged: !prevState.isLogged,
      tokenLogin: token,
      tentouLogin: true,
      usuario: email,
    }));
  }

  toggleAdm = () => {
    this.setState((prevState, prevProps) => ({
      isAdm: !prevState.isAdm,
    }));
  };

  render() {
    if (this.state.isLogged) {
      screen = (
        <App
          doLogin={this.doLogin}
          isAdm={this.state.isAdm}
          tokenLogin={this.state.tokenLogin}
          usuario={this.state.usuario}
          toggleAdm={this.toggleAdm}
        />
      );
    } else {
      screen = (
        <>
          <ThemeProvider theme={themeDefault}>
            <>
              <GlobalStyle />
              <ReactNotifications />
              <Router>
                <Switch>
                  <Route
                    path="/manutencao"
                    render={(props) => (
                      <Manutencao
                        {...props}
                        tentouLogin={this.state.tentouLogin}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/recuperar/:token"
                    render={(props) => (
                      <Home
                        {...props}
                        tentouLogin={this.state.tentouLogin}
                        recuperacao
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/usuarios/confirmar/:token"
                    render={(props) => (
                      <Home
                        {...props}
                        tentouLogin={this.state.tentouLogin}
                        confirmar
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/marketplace/"
                    render={(props) => <VendaMarketplace {...props} />}
                  />
                  <Route
                    exact
                    path="/teste"
                    render={(props) => <Teste {...props} />}
                  />
                  <Route
                    exact
                    path="/status"
                    render={(props) => <Status {...props} />}
                  />
                  <Route
                    path="*"
                    render={(props) => (
                      <Home
                        {...props}
                        tentouLogin={this.state.tentouLogin}
                        doLogin={this.doLogin}
                        tokenLogin={this.state.tokenLogin}
                        login
                      />
                    )}
                  ></Route>
                </Switch>
              </Router>
            </>
          </ThemeProvider>
        </>
      );
    }

    return <>{screen}</>;
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
