import React from "react";
import styled from "styled-components/macro";

import logoTaxcelBranco from "./images/Logo TaxDashs.png";

import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { Notification } from "./notificacoes/Notification";

import NotificacaoService from "../services/NotificacaoService";

import sound from "../sounds/notification.mp3";

import { CSSTransition } from "react-transition-group";
import "animate.css";
import LoadingIcon from "./LoadingIcon";

import { ReactComponent as EmojiIcon } from "../icons/emoji-icon.svg";
import { ReactComponent as RingerIcon } from "../icons/ringer-icon.svg";
import { ReactComponent as ContactIcon } from "../icons/contact-icon.svg";
import { ReactComponent as AjudaIcon } from "../icons/ajuda.svg";
import { ReactComponent as VideoIcon } from "../icons/video-icon.svg";
import { ReactComponent as GiftIcon } from "../icons/gift.svg";

import TopnavPerfilMenu from "./TopnavPerfilMenu";

const audio = new Audio(sound);
audio.volume = 0.5;

const Navbar = styled.nav`
  position: fixed;
  display: flex;
  z-index: 1002;
  width: 100%;
  height: 56px;
  background: ${(props) => props.theme.topnav};
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0px 5px 10px #00000040;
`;

const Logo = styled(Link)`
  width: 216px;
  text-align: center;
  text-decoration: none;

  img {
    width: 110px;
  }
`;

const NotificationButton = styled.button`
  text-align: center;
  margin: 0 0.5em;
  position: relative;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.topnav};
  cursor: pointer;
  width: 56px;
  height: 56px;
  font-size: 1em;
  border: none;

  svg.icon {
    margin-top: 5px;
    position: relative;
  }

  span {
    position: relative;
  }

  &.newNotification {
    span::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: -6px;
      right: -2px;
      position: absolute;
      background: ${(props) => props.theme.secondary};
    }
  }

  &:hover {
    background: ${(props) => props.theme.sidenav};
    color: #ffffff;
  }

  &.active {
    background: white;
    color: ${(props) => props.theme.topnav};
  }

  &:focus {
    outline: 3px solid lightblue;
  }
`;

const RightButtons = styled.div`
  margin-right: 2rem;
`;

const PerfilButton = styled.button`
  text-align: center;
  position: relative;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.topnav};
  cursor: pointer;
  width: 56px;
  height: 56px;
  font-size: 1.2em;
  border: none;

  svg.icon {
    margin-top: 5px;
    position: relative;
  }

  &:hover {
    background: ${(props) => props.theme.sidenav};
    color: #ffffff;
  }

  &.active {
    background: ${(props) => props.theme.sidenav};
    color: #ffffff;
  }

  &:focus {
    outline: 3px solid lightblue;
  }
`;

const FeedbackButton = styled.button`
  text-align: center;
  position: relative;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.topnav};
  cursor: pointer;
  width: 56px;
  height: 56px;
  font-size: 1.2em;
  border: none;
  margin-left: 8px;

  svg.icon {
    margin-top: 5px;
    position: relative;
  }

  &:hover {
    background: ${(props) => props.theme.sidenav};
    color: #ffffff;
  }

  &.active {
    background: ${(props) => props.theme.sidenav};
    color: #ffffff;
  }

  &:focus {
    outline: 3px solid lightblue;
  }
`;

const OperacaoEmAndamento = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
  }
`;

class Topnav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      notificationsList: [],
      newNotification: false,
      perfil: false,
      tamanhoOcupado: 2,
      tamanhoTotal: 10,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePerfilClick = this.handlePerfilClick.bind(this);
    this.startaNPS = this.startaNPS.bind(this);
  }

  componentDidMount() {
    NotificacaoService.getNotificacoes(50, this.props.tokenLogin, 0).then(
      (resp) => {
        //console.log(resp.data.notificacoes);
        this.setState({
          notificationsList: resp.data.notificacoes,
          newNotification: resp.data.notificacoes.length > 0,
        });
        if (window.intervalNotification) {
          clearInterval(window.intervalNotification);
        }
        window.intervalNotification = setInterval(
          this.atualizaNotificacoes,
          15000
        );
      }
    );
  }

  startaNPS() {
    //console.log(this.props.location.pathname);
    if (window.Conpass) {
      //console.log("Starta NPS");
      // NPS
      //console.log(window.Conpass);
      window.Conpass.startNps("I9Vm__5kfN-SG", { show: true });
    }
  }

  handlePerfilClick() {
    this.setState((prevState, props) => ({
      perfil: !prevState.perfil,
      notification: false,
    }));
  }

  closePerfilMenu = () => {
    this.setState({
      perfil: false,
    });
  };

  handleClick() {
    this.setState({
      perfil: false,
      notification: !this.state.notification,
      newNotification: false,
    });
  }

  deleteNotificaton = (notId) => {
    //console.log("apagar notific: " + notId);
    let newNotifList = this.state.notificationsList.filter(
      (notification) => notification._id !== notId
    );
    //console.log(notId);
    NotificacaoService.trocaFlagVisualizado(notId, this.props.tokenLogin);
    this.setState({
      notificationsList: newNotifList,
    });
    // this.atualizaNotificacoes();
  };

  deleteAllnotifications = () => {
    if (this.state.notificationsList.length) {
      this.state.notificationsList.forEach((element) => {
        //console.log(element._id);
        NotificacaoService.trocaFlagVisualizado(
          element._id,
          this.props.tokenLogin
        );
      });
      this.setState({
        notificationsList: [],
      });
      //   this.atualizaNotificacoes();
    } else {
      //console.log("vazio");
    }
  };

  atualizaNotificacoes = () => {
    NotificacaoService.getNotificacoes(50, this.props.tokenLogin, 0).then(
      (resp) => {
        // console.log(
        //   this.state.notificationsList.length +
        //     "-" +
        //     resp.data.notificacoes.length
        // );
        // console.dir(resp.data);
        if (
          this.state.notificationsList.length < resp.data.notificacoes.length
        ) {
          this.setState({
            notificationsList: resp.data.notificacoes,
            newNotification: true,
          });
          audio.play();
        } else {
          this.setState({
            newNotification: false,
          });
        }
      }
    );
  };

  render() {
    return (
      <Navbar>
        <Logo to="/">
          <img src={logoTaxcelBranco} alt="Logo Taxcel"></img>
        </Logo>
        {/* <button onClick={this.props.toggleAdm}>Toggle Adm</button> */}
        {this.props.temOperacao && (
          <OperacaoEmAndamento>
            <LoadingIcon />
            <p>
              {this.props.operacaoEmAndamento.startsWith("post/arquivos") ||
              this.props.operacaoEmAndamento.startsWith("arquivos/post/arquivo")
                ? "Carregando arquivos"
                : this.props.operacaoEmAndamento.startsWith(
                    "moduloarquivos/delete/comum/arquivos"
                  )
                ? "Apagando arquivos"
                : this.props.operacaoEmAndamento === "powerbi/post/refresh"
                ? "Atualizando dashboard"
                : this.props.operacaoEmAndamento === "arquivos/post/zip"
                ? "Descompactando arquivos"
                : this.props.operacaoEmAndamento === "arquivos/get/importar"
                ? "Importando arquivos"
                : this.props.operacaoEmAndamento === "delete/arquivos"
                ? "Deletando arquivos do banco"
                : this.props.operacaoEmAndamento}
            </p>
          </OperacaoEmAndamento>
        )}
        <RightButtons>
          <FeedbackButton
            id="indique-taxcel"
            title="Indique e Ganhe"
            onClick={() => {
              window.open("https://indique.taxcel.com.br/?bl_ref=Z163");
            }}
          >
            <GiftIcon className="icon" />
          </FeedbackButton>
          <FeedbackButton
            id="tutoriais"
            title="Tutoriais"
            onClick={() => {
              window.open(
                "https://taxceladdins.zendesk.com/hc/pt-br/sections/360012200012--TaxBI-Cloud-V%C3%ADdeos-Tutoriais-"
              );
            }}
          >
            <VideoIcon className="icon" />
          </FeedbackButton>
          <FeedbackButton
            id="pesquisa-nps"
            title="Pesquisa de Satisfação"
            onClick={this.startaNPS}
          >
            <EmojiIcon className="icon" />
          </FeedbackButton>
          <NotificationButton
            id="notificacao"
            title="Notificações"
            className={this.state.newNotification ? "newNotification" : ""}
            onClick={this.handleClick}
          >
            <span>
              <RingerIcon className="icon icon-big" />
            </span>
          </NotificationButton>
          <PerfilButton
            id="meu-perfil-button"
            title="Meu Perfil"
            className={this.state.perfil ? "active" : ""}
            onClick={this.handlePerfilClick}
          >
            <ContactIcon className="icon" />
          </PerfilButton>
        </RightButtons>
        <Notification
          active={this.state.notification}
          closeNotifications={this.handleClick}
          notificationsList={this.state.notificationsList}
          deleteAllnotifications={this.deleteAllnotifications}
          deleteNotificaton={this.deleteNotificaton}
        ></Notification>
        <CSSTransition
          in={this.state.perfil}
          timeout={0}
          classNames=""
          unmountOnExit
        >
          <TopnavPerfilMenu
            usuario={this.props.usuario}
            doLogin={this.props.doLogin}
            tokenLogin={this.props.tokenLogin}
            closeMenu={this.closePerfilMenu}
            isContato={this.props.isContato}
            isLeitor={this.props.isLeitor}
          />
        </CSSTransition>
      </Navbar>
    );
  }
}

export default withRouter(Topnav);
