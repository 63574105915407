import api from "./config/Axios";

export default class AgendamentoService {
  static async getAgendamentos(token) {
    return api({
      method: "get",
      url: `/agendamentos/usuario`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async apagarAgendamento(token, id) {
    return api({
      method: "delete",
      url: `/agendamentos/usuario`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_agendamento: id,
      },
    });
  }

  static async alteraDataAgendamento(token, id, data) {
    return api({
      method: "put",
      url: "/agendamentos/usuario",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_agendamento: id,
        data_agendamento: data,
      },
    });
  }

  static async criarAgendamento({
    tokenLogin,
    ehProcurador,
    ehCpf,
    num_doc,
    nome_certificado,
    tipo,
    data_agendamento,
    periodo,
    intervalo,
  }) {
    console.log({
      ehProcurador: ehProcurador,
      ehCpf: ehCpf,
      num_doc: num_doc,
      nome_certificado: nome_certificado,
      tipo: tipo,
      data_agendamento: data_agendamento,
      periodo: periodo,
      intervalo: intervalo,
    });
    return api({
      method: "post",
      url: `/arquivos/agendar`,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: {
        ehProcurador: ehProcurador,
        ehCpf: ehCpf,
        num_doc: num_doc,
        nome_certificado: nome_certificado,
        tipo: tipo,
        data_agendamento: data_agendamento,
        periodo: periodo,
        intervalo: intervalo,
      },
    });
  }
}
