import React from "react";
import styled from "styled-components/macro";

import Button from "../Button";
import Select from "react-select";
import { FormInput } from "../FormInput";

import { ReactComponent as MinusIcon } from "../../icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus-icon.svg";

import LoadingIcon from "../LoadingIcon";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "../ToastNotification";
import VendaService from "../../services/VendaService";

import cep from "cep-promise";
//https://www.npmjs.com/package/cep-promise

const LoadingBox = styled.div`
  margin-top: 1rem;
`;
const Container = styled.section`
  max-width: 420px;

  h4 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  h5 {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 2rem 0 0;
  }

  .select-wrapper {
    margin: 1rem 0 1.5rem;
  }

  .data {
    font-size: 1.125rem;
    color: ${(props) => props.theme.grayDark};
    margin: 1rem 0 2rem;

    input {
      margin-left: 1rem;
    }

    &:focus-within {
      color: ${(props) => props.theme.primary};
    }
  }

  .contas {
    margin: 2rem 0;

    h5 {
      font-weight: 600;
      margin-top: 2rem;
      font-size: 1.125rem;
    }

    .flex-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0.5rem 0;

      p {
        color: ${(props) => props.theme.grayDark};
        margin-right: 3rem;
      }
    }

    .custom-conta {
      button {
        margin-top: 1.5rem;
      }
    }
  }

  label.teste {
    color: ${(props) => props.theme.grayDark};
    margin: 2rem 0 1rem;
    font-size: 1.125rem;
    display: inline-block;
    user-select: none;
  }
`;

const ContainerNumberInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  label {
    color: ${(props) => props.theme.grayDark};
  }

  &:focus-within {
    label {
      color: ${(props) => props.theme.primary};
    }
  }
`;

const NumberInputStyled = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  width: 100px;

  input {
    flex-grow: 1;
    text-align: center;
    width: 100%;
    font-size: 1rem;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  button {
    border: none;
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.background};
    border-radius: 4px;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;

    svg.icon {
      margin-top: 4px;
    }
  }
`;

export const mask = {
  telEmpresa: function (value) {
    let v = value.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

    if (value.length < 15) v = v.replace(/(\d{4})(\d)/, "$1-$2");
    else v = v.replace(/(\d{5})(\d)/, "$1-$2");

    v = v.substring(0, 15);

    return v;
  },
  cnpj: function (value) {
    let v = value.replace(/\D/g, "");
    if (value.length < 14) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      // v = v.replace(/(\d{2})(\d)/, "$1-$2");
      v = v.substring(0, 18);
      return v;
    }

    v = v.replace(/(\d{2})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
    v = v.substring(0, 18);

    return v;
  },
  cepEmpresa: function (value) {
    let v = value.replace(/\D/g, "");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
    v = v.substring(0, 9);

    return v;
  },
  estadoEmpresa: function (value) {
    let v = value.replace(/[^a-zA-Z]/g, "");
    // v = v.replace(/(\d{2})(\d)/, "$1-$2");
    v = v.substring(0, 2);
    v = v.toUpperCase();

    return v;
  },
};

export class CriarVenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planos: [],
      selectedOption: null,
      customPBI: false,
      customStorage: false,
      customBD: false,
      emailError: false,
      foiFocado: false,
      qtdContato: 1,
      qtdUsuarios: 1,
      capacidade: 10,
      vendaTeste: false,
      criarBanco: true,
      clienteNovo: true,
      pegouCnpj: false,
      pegouEndereco: false,
      loadingDados: false,
      loadingEndereco: false,
      criandoVenda: false,
      erroNomeBd: false,
      erros: {
        cnpj: { isValid: false, texto: "" },
        nomeEmpresa: { isValid: false, texto: "" },
        telEmpresa: { isValid: false, texto: "" },
        cepEmpresa: { isValid: false, texto: "" },
        endEmpresa: { isValid: false, texto: "" },
        email: { isValid: false, texto: "" },
        nomeBD: { isValid: false, texto: "" },
      },
    };

    this.refCriarBtn = React.createRef();
  }

  componentDidMount() {
    VendaService.getPlanos(this.props.tokenLogin)
      .then((resp) => {
        let planos = [];
        resp.data.planos.map((plano) => {
          planos.push({
            value: plano.id,
            label: plano.nome,
          });
        });
        this.setState({ planos: planos });
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({
      [name]: value,
    });

    if (name === "nome_banco") {
      if (this.state.nome_banco.length > 0) {
        this.setState({ erroNomeBd: false });
      }
    }
    if (name === "email") {
      this.setState({
        emailError: this.checkEmail(value),
      });
    }
  };

  focusNomeBD = (e) => {
    const {
      target: { name },
    } = e;
    if (this.state.email && !this.state[name] && !this.state.emailError) {
      this.setState({
        [name]: `Banco de ${this.state.email.substring(
          0,
          this.state.email.indexOf("@")
        )}`,
      });
    }
  };

  checkEmail(email) {
    return !email.match(/\S+@\S+\.\S+/);
  }

  maskTel(value) {
    let v = value.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

    if (value.length < 15) v = v.replace(/(\d{4})(\d)/, "$1-$2");
    else v = v.replace(/(\d{5})(\d)/, "$1-$2");

    v = v.substring(0, 15);

    return v;
  }

  maskCNPJ(value) {
    let v = value.replace(/\D/g, "");
    if (value.length < 16) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{2})(\d)/, "$1-$2");
      v = v.substring(0, 16);
      return v;
    }
    v = v.replace(/(\d{2})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
    v = v.substring(0, 18);

    return v;
  }

  maskCEP(value) {
    let v = value.replace(/\D/g, "");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
    v = v.substring(0, 9);

    return v;
  }

  maskEstado(value) {
    let v = value.replace(/[^a-zA-Z]/g, "");
    // v = v.replace(/(\d{2})(\d)/, "$1-$2");
    v = v.substring(0, 2);
    v = v.toUpperCase();

    return v;
  }

  handleChangeMask = (e) => {
    const {
      target: { name, value },
    } = e;
    //  console.log(name);

    let maskValue = mask[name](value);

    this.setState({
      [name]: maskValue,
    });
  };

  handleClick = (oper, campo, step = 1) => {
    // for (let campos in this.state.erros) {
    //   if(!this.state.erros[campos].isValid) {
    //     console.log("yes");
    //     console.log(campos);
    //   }
    // }
    let btn = this.refCriarBtn.current;
    btn.disabled = true;

    setTimeout(function () {
      btn.disabled = false;
    }, 2000);

    let cnpj = this.state.cnpj?.replace(/\D/g, "");
    if (
      this.state.cepEmpresa?.length < 9 ||
      (cnpj.length !== 14 && cnpj.length !== 11) ||
      this.state.telEmpresa?.length < 14 ||
      !this.state.nomeEmpresa ||
      !this.state.endEmpresa ||
      this.state.emailError
    ) {
      // console.log(cnpj.length);
      this.adicionaNotificacao("Preencha todos campos obrigatórios.", true);
    } else {
      //alert("venda criada");
      this.criarVenda();
    }
    //console.log(oper, campo);
    // if (oper === "+") {
    //   this.setState((prevState) => ({
    //     [campo]: parseInt(prevState[campo]) + step,
    //   }));
    // } else if (oper === "-") {
    //   this.setState((prevState) => {
    //     if (parseInt(prevState[campo]) - step <= 0) {
    //       return {
    //         [campo]: 0,
    //       };
    //     }
    //     return {
    //       [campo]: parseInt(prevState[campo]) - step,
    //     };
    //   });
    // }
  };

  handlePlanoChange = (selectedOption) => {
    this.setState({
      selectedOption,
    });
  };

  toggleCustom = (section) => {
    this.setState((prevState, prevProps) => ({
      [section]: !prevState[section],
    }));
  };

  criarVenda = () => {
    this.criarPlano();
    this.setState({
      criandoVenda: true,
    });

    let doc = document.querySelector("#venda_data_fim");
    let date = doc ? doc.value : new Date().toISOString();
    let obj = {
      cliente: {
        cnpj: this.state.cnpj.replace(/\D/g, ""),
        razao_social: this.state.nomeEmpresa,
        telefone: this.state.telEmpresa.replace(/\D/g, ""),
        endereco: `${this.state.endEmpresa} - ${this.state.bairroEmpresa} - ${this.state.cidadeEmpresa}, ${this.state.estadoEmpresa}`,
        cep: this.state.cepEmpresa.replace(/\D/g, ""),
        tipo: "1",
      },
      venda: {
        data_fim: date,
        teste: this.state.vendaTeste,
      },
      usuario: {
        email: this.state.email,
        nome_banco: this.state.criarBanco ? this.state.nome_banco : null,
        novo_banco: this.state.criarBanco,
      },
      plano: {
        nome: "Plano de " + this.state.nomeEmpresa,
        num_bancos: this.state.qtdUsuarios,
        capacidade_bd: this.state.capacidade,
        capacidade_storage: this.state.capacidade,
      },
    };

    if (this.state.customPBI) {
      obj.conta_pbi = {
        conta_pbi: this.state.contaPBI,
        chave_pbi: this.state.chavePBI,
        group_id: this.state.groupID,
        client_id: this.state.clientID,
      };
    }

    if (this.state.customBD) {
      obj.conta_bd = {
        usuario: this.state.usuarioMestre,
        senha: this.state.senhaMestre,
        servidor: this.state.servidorBD,
        porta: this.state.portaBD,
      };
    }

    if (this.state.customStorage) {
      obj.conta_storage = {
        conta_storage: this.state.contaStorage,
        chave_storage: this.state.chaveStorage,
      };
    }
    // console.log(obj);

    if (
      !obj.venda.data_fim ||
      !(obj.plano.capacidade_bd > 0) ||
      !(obj.plano.capacidade_storage > 0)
    ) {
      console.log(2, obj.venda.data_fim, obj.plano.capacidade_bd, obj.plano.capacidade_storage);
      this.adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      this.setState({
        criandoVenda: false,
      });
      return;
    }

    if (obj.usuario.novo_banco && !obj.usuario.nome_banco) {
      this.setState({
        erroNomeBd: true,
        criandoVenda: false,
      });
      return;
    }

    VendaService.criarVenda(obj, this.props.tokenLogin)
      .then((resp) => {
        this.adicionaNotificacao("Venda criada com sucesso", false);
        this.setState({
          cnpj: "",
          nomeEmpresa: "",
          telEmpresa: "",
          cepEmpresa: "",
          endEmpresa: "",
          bairroEmpresa: "",
          cidadeEmpresa: "",
          estadoEmpresa: "",
          email: "",
          nome_banco: "",
          criandoVenda: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 400)
          this.adicionaNotificacao(err.response.data.descricao, true);
        this.setState({
          criandoVenda: false,
        });
        // console.log(err.response);
      });
  };

  adicionaNotificacao(texto, erro) {
    store.addNotification({
      content: (
        <ToastNotification bg={erro ? "danger" : "primary"} textoAuxiliar="">
          {texto}{" "}
        </ToastNotification>
      ),
      // onRemoval: (id, removedBy) => //console.log(removedBy),
      ...notificationTopRight,
    });
  }

  //----------------PLANO---------------------

  handleClickIncrement = (oper, campo, step = 1) => {
    //console.log(oper, campo);
    if (oper === "+") {
      this.setState((prevState) => ({
        [campo]: parseInt(prevState[campo]) + step,
      }));
    } else if (oper === "-") {
      this.setState((prevState) => {
        if (parseInt(prevState[campo]) <= step) {
          return {
            [campo]: 0,
          };
        }
        return {
          [campo]: parseInt(prevState[campo]) - step,
        };
      });
    }
  };

  criarPlano = () => {
    /*  let plano = {
      nome: this.state.nomePlano,
      num_bancos:
        parseInt(this.state.qtdContato, 10) +
        parseInt(this.state.qtdUsuarios, 10),
      capacidade_bd: this.state.capacidade,
      capacidade_storage: this.state.capacidade,
      isTeste: this.state.vendaTeste,
      permissoes: [
        { ID_PERMISSAO: 3, QUANTIDADE: this.state.qtdContato },
        {
          ID_PERMISSAO: 4,
          QUANTIDADE:
            parseInt(this.state.qtdUsuarios, 10) +
            parseInt(this.state.qtdContato, 10),
        },
        { ID_PERMISSAO: 5, QUANTIDADE: 999 },
      ],
    };

    console.log(plano);

    // VendedorService.criarPlano(plano, this.props.tokenLogin)
    //   .then((resp) => {
    //     store.addNotification({
    //       content: (
    //         <ToastNotification bg="primary" textoAuxiliar="">
    //           Plano criado com sucesso!{" "}
    //         </ToastNotification>
    //       ),
    //       // onRemoval: (id, removedBy) => //console.log(removedBy),
    //       ...notificationTopRight,
    //     });
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });*/
  };

  somaQtd = () => {
    this.setState((prevState) => ({
      qtdContato: parseInt(prevState.qtdContato) + 1,
    }));
  };

  getEndereco = () => {
    this.setState({
      loadingEndereco: true,
      pegouEndereco: false,
    });

    cep(this.state.cepEmpresa)
      .then((resp) => {
        this.setState({
          pegouEndereco: true,
          loadingEndereco: false,
          // cepEmpresa: resp.cep,
          endEmpresa: resp.street,
          bairroEmpresa: resp.neighborhood,
          cidadeEmpresa: resp.city,
          estadoEmpresa: resp.state,
        });
      })
      .catch((err) => {
        this.setState({
          pegouEndereco: true,
          loadingEndereco: false,
          endEmpresa: "",
          bairroEmpresa: "",
          cidadeEmpresa: "",
          estadoEmpresa: "",
        });
      });
  };

  getCliente = () => {
    this.setState({
      loadingDados: true,
      pegouCnpj: false,
    });
    if (!this.state.cnpj?.length) {
      this.setState({
        cnpjVazio: true,
        pegouCnpj: false,
        loadingDados: false,
      });
      return;
    }

    VendaService.getCliente(this.state.cnpj, this.props.tokenLogin)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          pegouCnpj: true,
          pegouEndereco: true,
          clienteNovo: true,
          cnpjVazio: false,
          loadingDados: false,
        });
        if (!resp.data.cliente) {
          this.setState({
            clienteNovo: true,
            cepEmpresa: "",
            nomeEmpresa: "",
            telEmpresa: "",
            endEmpresa: "",
            bairroEmpresa: "",
            cidadeEmpresa: "",
            estadoEmpresa: "",
          });
          return;
        }

        let endereco = resp.data.cliente.ENDERECO;
        // separa em 3: endereço/complemento, bairro, cidade/estado
        let split = endereco.split(" - ");
        let cidade, estado;

        if (split.length > 0 && split[2]) {
          [cidade, estado] = split[2].split(", ");
        }

        this.setState({
          clienteNovo: false,
          cepEmpresa: this.maskCEP(resp.data.cliente.CEP),
          nomeEmpresa: resp.data.cliente.RAZAO_SOCIAL,
          telEmpresa: this.maskTel(resp.data.cliente.TELEFONE),
          endEmpresa: split[0],
          bairroEmpresa: split[1],
          cidadeEmpresa: cidade,
          estadoEmpresa: estado ? this.maskEstado(estado) : "",
        });
      })
      .catch((err) => {
        this.setState({
          pegouCnpj: true,
          cnpjVazio: false,
          loadingDados: false,
        });
      });
  };

  subtraiQtd = () => {
    this.setState((prevState) => {
      if (!(parseInt(prevState.qtdContato) > 0)) return;
      return {
        qtdContato: parseInt(prevState.qtdContato) - 1,
      };
    });
  };

  toggleTeste = () => {
    this.setState((prevState, prevProps) => ({
      vendaTeste: !prevState.vendaTeste,
    }));
  };

  toggleCriarBanco = () => {
    this.setState((prevState, prevProps) => ({
      criarBanco: !prevState.criarBanco,
    }));
  };

  render() {
    return (
      <Container>
        <h4>Plano</h4>
        {/* <ContainerNumberInput>
          <label for="qtdContato">Quantidade de Administradores:</label>
          <NumberInputStyled>
            <button
              onClick={() => this.handleClickIncrement("-", "qtdContato")}
            >
              <MinusIcon className="icon" />
            </button>
            <input
              type="number"
              name="qtdContato"
              id="qtdContato"
              required={true}
              value={this.state.qtdContato}
              onChange={this.handleChange}
            ></input>
            <button
              onClick={() => this.handleClickIncrement("+", "qtdContato")}
            >
              <PlusIcon className="icon" />
            </button>
          </NumberInputStyled>
        </ContainerNumberInput> */}
        <ContainerNumberInput>
          <label for="qtdUsuarios">Quantidade de Usuários:</label>
          <NumberInputStyled>
            <button
              onClick={() => this.handleClickIncrement("-", "qtdUsuarios", 5)}
            >
              <MinusIcon className="icon" />
            </button>
            <input
              type="number"
              name="qtdUsuarios"
              id="qtdUsuarios"
              required={true}
              value={this.state.qtdUsuarios}
              onChange={this.handleChange}
            ></input>
            <button
              onClick={() => this.handleClickIncrement("+", "qtdUsuarios", 5)}
            >
              <PlusIcon className="icon" />
            </button>
          </NumberInputStyled>
        </ContainerNumberInput>
        <ContainerNumberInput>
          <label for="capacidade">Capacidade do Banco de Dados (em GB):</label>
          <NumberInputStyled>
            <button
              onClick={() => this.handleClickIncrement("-", "capacidade")}
            >
              <MinusIcon className="icon" />
            </button>
            <input
              type="number"
              name="capacidade"
              id="capacidade"
              required={true}
              value={this.state.capacidade}
              onChange={this.handleChange}
            ></input>
            <button
              onClick={() => this.handleClickIncrement("+", "capacidade")}
            >
              <PlusIcon className="icon" />
            </button>
          </NumberInputStyled>
        </ContainerNumberInput>
        <label className="teste">
          Teste:
          <input
            type="checkbox"
            checked={this.state.vendaTeste}
            onChange={this.toggleTeste}
          ></input>
        </label>
        {!this.state.vendaTeste ? (
          <div className="data">
            <label>
              Venda válida até:
              <input
                required
                name="venda_data_fim"
                id="venda_data_fim"
                type="date"
              ></input>
            </label>
          </div>
        ) : (
          <></>
        )}

        <h4>Cliente</h4>
        <FormInput
          mt={2}
          bgFilled
          type="text"
          id="cnpj"
          label="CNPJ ou CPF"
          placeholder=""
          error={this.state.cnpj?.length === 0 || this.state.cnpjVazio}
          errorMsg="Digite um CPF ou CNPJ para continuar"
          required
          value={this.state.cnpj}
          onChange={this.handleChangeMask}
          onBlur={this.getCliente}
        />
        <h5>
          {this.state.clienteNovo
            ? "Cadastrar Novo Cliente "
            : "Dados do Cliente "}
          {this.state.loadingDados && <LoadingIcon />}
        </h5>
        {this.state.pegouCnpj && (
          <>
            <FormInput
              mt={1}
              bgFilled
              type="text"
              id="nomeEmpresa"
              label="Nome da Empresa"
              placeholder="Empresa Exemplo"
              errorMsg="Campo obrigatório"
              error={this.state.nomeEmpresa?.length < 1}
              required
              value={this.state.nomeEmpresa}
              onChange={this.handleChange}
            />
            <FormInput
              mt={2}
              bgFilled
              type="tel"
              id="telEmpresa"
              label="Telefone"
              placeholder="(12) 91234-1234"
              errorMsg="Telefone inválido"
              // error={this.state.telEmpresa?.length < 14}
              required
              value={this.state.telEmpresa}
              onChange={this.handleChangeMask}
            />
            <FormInput
              mt={2}
              bgFilled
              type="text"
              id="cepEmpresa"
              label="CEP"
              placeholder="12345-123"
              errorMsg="CEP inválido"
              // error={this.state.cepEmpresa?.length < 9}
              required
              value={this.state.cepEmpresa}
              onChange={this.handleChangeMask}
              onBlur={this.getEndereco}
            />
            {this.state.loadingEndereco && (
              <LoadingBox>
                <LoadingIcon />
              </LoadingBox>
            )}
            {this.state.pegouEndereco && (
              <>
                <FormInput
                  mt={2}
                  bgFilled
                  type="text"
                  id="endEmpresa"
                  label="Endereço"
                  placeholder="Rua Exemplo, 1234"
                  errorMsg="Campo obrigatório"
                  error={this.state.endEmpresa?.length < 1}
                  required
                  value={this.state.endEmpresa}
                  onChange={this.handleChange}
                />
                <FormInput
                  mt={2}
                  bgFilled
                  type="text"
                  id="bairroEmpresa"
                  label="Bairro"
                  placeholder="Bairro Exemplo"
                  errorMsg="Campo obrigatório"
                  error={this.state.bairroEmpresa?.length < 1}
                  required
                  value={this.state.bairroEmpresa}
                  onChange={this.handleChange}
                />
                <FormInput
                  mt={2}
                  bgFilled
                  type="text"
                  id="cidadeEmpresa"
                  label="Cidade"
                  placeholder="Cidade Exemplo"
                  errorMsg="Campo obrigatório"
                  error={this.state.cidadeEmpresa?.length < 1}
                  required
                  value={this.state.cidadeEmpresa}
                  onChange={this.handleChange}
                />
                <FormInput
                  mt={2}
                  bgFilled
                  type="text"
                  id="estadoEmpresa"
                  label="Estado"
                  placeholder="XX"
                  errorMsg="Campo obrigatório"
                  error={this.state.estadoEmpresa?.length < 1}
                  required
                  value={this.state.estadoEmpresa}
                  onChange={this.handleChangeMask}
                />
              </>
            )}
          </>
        )}
        <FormInput
          mt={2}
          bgFilled
          type="text"
          id="email"
          label="Email do Contato"
          placeholder="contato@email.com"
          errorMsg="Email inválido"
          error={this.state.emailError}
          required
          value={this.state.email}
          onChange={this.handleChange}
        />
        <label className="teste">
          Criar banco
          <input
            type="checkbox"
            checked={this.state.criarBanco}
            onChange={this.toggleCriarBanco}
          ></input>
        </label>
        {this.state.criarBanco ? (
          <FormInput
            mt={2}
            bgFilled
            type="text"
            id="nome_banco"
            label="Nome do Banco de Dados"
            placeholder="Banco do contato"
            errorMsg="Preencha o nome do banco"
            error={this.state.erroNomeBd}
            required
            value={this.state.nome_banco}
            onChange={this.handleChange}
            onFocus={this.focusNomeBD}
          />
        ) : (
          <></>
        )}

        <div className="contas">
          <h4>Contas</h4>
          <h5>PowerBI</h5>
          {this.state.customPBI ? (
            <div className="custom-conta">
              <FormInput
                type="text"
                id="contaPBI"
                label="Conta PBI"
                placeholder=""
                errorMsg=""
                required
                value={this.state.contaPBI}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="chavePBI"
                label="Chave da conta"
                placeholder=""
                errorMsg=""
                required
                value={this.state.chavePBI}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="clientID"
                label="Client ID"
                placeholder=""
                errorMsg=""
                required
                value={this.state.clientID}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="groupID"
                label="Group ID"
                placeholder=""
                errorMsg=""
                required
                value={this.state.groupID}
                onChange={this.handleChange}
              />
              <Button
                className="mt-1"
                onClick={() => this.toggleCustom("customPBI")}
                link
                background="primary"
              >
                Usar conta padrão
              </Button>
            </div>
          ) : (
            <div className="flex-wrapper">
              <p>Conta Padrão</p>
              <Button
                onClick={() => this.toggleCustom("customPBI")}
                link
                background="primary"
              >
                Editar
              </Button>
            </div>
          )}
          <h5>Storage</h5>
          {this.state.customStorage ? (
            <div className="custom-conta">
              <FormInput
                type="text"
                id="contaStorage"
                label="Conta Storage"
                placeholder=""
                errorMsg=""
                required
                value={this.state.contaStorage}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="chaveStorage"
                label="Chave da Conta"
                placeholder=""
                errorMsg=""
                required
                value={this.state.chaveStorage}
                onChange={this.handleChange}
              />
              <Button
                className="mt-1"
                onClick={() => this.toggleCustom("customStorage")}
                link
                background="primary"
              >
                Usar conta padrão
              </Button>
            </div>
          ) : (
            <div className="flex-wrapper">
              <p>Conta Padrão</p>
              <Button
                onClick={() => this.toggleCustom("customStorage")}
                link
                background="primary"
              >
                Editar
              </Button>
            </div>
          )}
          <h5>Banco de Dados</h5>
          {this.state.customBD ? (
            <div className="custom-conta">
              <FormInput
                type="text"
                id="usuarioMestre"
                label="Usuário Mestre"
                placeholder=""
                errorMsg=""
                required
                value={this.state.usuarioMestre}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="senhaMestre"
                label="Senha Mestre"
                placeholder=""
                errorMsg=""
                required
                value={this.state.senhaMestre}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="servidorBD"
                label="Servidor"
                placeholder=""
                errorMsg=""
                required
                value={this.state.servidorBD}
                onChange={this.handleChange}
              />
              <FormInput
                type="text"
                id="portaBD"
                label="Porta"
                placeholder=""
                errorMsg=""
                required
                value={this.state.portaBD}
                onChange={this.handleChange}
              />
              <Button
                className="mt-1"
                onClick={() => this.toggleCustom("customBD")}
                link
                background="primary"
              >
                Usar conta padrão
              </Button>
            </div>
          ) : (
            <div className="flex-wrapper">
              <p>Conta Padrão</p>
              <Button
                onClick={() => this.toggleCustom("customBD")}
                link
                background="primary"
              >
                Editar
              </Button>
            </div>
          )}
        </div>
        <Button
          forwardRef={this.refCriarBtn}
          background="primary"
          onClick={this.handleClick}
          disabled={this.state.criandoVenda}
        >
          Criar Venda
        </Button>
      </Container>
    );
  }
}

export default CriarVenda;
