import api from './config/Axios';

export default class AuthService {

    static async autenticar(obj) {
        /* email / senha */
        return api({
            method: 'post',
            url: `/autenticar/`,
            data: obj,
        });
    }

    static async autenticarMsal(obj, tokenMsal, token) {
        /* email / token */
        return api({
            method: 'post',
            url: `/autenticar_msal/`,
            headers: {
                "Authorization": `Bearer ${tokenMsal}`,
                token: token
            },
            data: obj,
        });
    }

    static async verificaSso(tokenConfirmacao) {
        /* email / token */
        return api({
            method: 'get',
            url: `/sso/verificar/${tokenConfirmacao}`
        });
    }

    static async autenticar2fa(obj) {
        return api({
            method: 'post',
            url: '/autenticar/2fa/',
            data: obj
        });
    }

    static async autenticar2faMsal(obj, tokenMsal) {
        return api({
            method: 'post',
            url: '/autenticar_msal/2fa/',
            headers: {
                "Authorization": `Bearer ${tokenMsal}`
            },
            data: obj
        });
    }

    static async confirmarUsuario(token, senha) {
        return api({
            method: 'patch',
            url: `/confirmar/${token}/`,
            data: {
                senha: senha,
            },
        });
    }

    static async confirmarUsuarioSSO(token) {
        return api({
            method: 'patch',
            url: `/confirmar/${token}/`
        });
    }

    static async recuperarSenha(email) {
        return api({
            method: 'put',
            url: `/recuperar/`,
            data: {
                email: email,
            },
        })
    }

    static async validarTokenRecuperacao(token) {
        return api({
            method: 'get',
            url: `/recuperar/${token}/`,
        })
    }

    static async criarNovaSenhaPorToken(token, email, senha) {
        return api({
            method: 'post',
            url: `/recuperar/`,
            data: {
                token: token,
                email: email,
                senha: senha,
            },
        })
    }

    static async getInfoNovaVenda(token) {
        return api({
            method: 'get',
            url: `/nova_venda_marketplace/?token=${token}`
        })
    }
}