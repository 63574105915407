import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "rgb(187, 186, 186)";
};

const DropzoneContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const FileUploaded = styled.div`
    
    padding: .5rem;
    text-align: center;
`;

const StyledDropzone = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  border: 2px dashed #707070;
  border-color: ${(props) => getColor(props)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  padding: 1em;
  color: #47484c;
  cursor: pointer;
  flex-shrink: 1;
  flex-grow: 1;

  svg.drop-img {
    height: 3rem;
    fill: ${(props) => props.theme.gray};
    margin-bottom: 1rem;
  }
`;

function FileUpload(props) {
  const maxSize = 1048576;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    //console.log(acceptedFiles);
/*    if (rejectedFiles.length > 0) {
      alert(rejectedFiles.map((rejectedFile) => rejectedFile.name));
    }*/
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        //console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: ".txt,text/plain",
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <DropzoneContainer>
      <StyledDropzone
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <svg className="drop-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 38.272">
          <path
            id="Union_2"
            data-name="Union 2"
            d="M-2088.149,2469.842v-23.15l-8.209,8.191-1.582-1.581,10.916-10.916,10.916,10.916-1.582,1.581-8.209-8.191v23.15Zm-3.74-8.271H-2100a11.8,11.8,0,0,1-4.677-.938,12.1,12.1,0,0,1-3.82-2.566,12.117,12.117,0,0,1-2.566-3.821,11.817,11.817,0,0,1-.938-4.677,11.813,11.813,0,0,1,.938-4.676,12.127,12.127,0,0,1,2.566-3.82,12.11,12.11,0,0,1,3.82-2.566,11.814,11.814,0,0,1,4.677-.938,11.661,11.661,0,0,1,2.179.21,14.161,14.161,0,0,1,2.168-2.613,13.2,13.2,0,0,1,2.684-1.958,13.4,13.4,0,0,1,3.094-1.219,13.6,13.6,0,0,1,3.375-.422,13.276,13.276,0,0,1,4.909.915,13.485,13.485,0,0,1,4.091,2.52,13.686,13.686,0,0,1,2.941,3.808,13.97,13.97,0,0,1,1.488,4.758,8.949,8.949,0,0,1,3.528.7,9.271,9.271,0,0,1,2.883,1.91,8.985,8.985,0,0,1,1.945,2.848,8.8,8.8,0,0,1,.716,3.54,8.785,8.785,0,0,1-.7,3.5,9.134,9.134,0,0,1-1.933,2.859,9.094,9.094,0,0,1-2.871,1.933,8.811,8.811,0,0,1-3.492.7h-9.381v-3H-2073a5.87,5.87,0,0,0,2.332-.468,6.111,6.111,0,0,0,1.91-1.29,6.07,6.07,0,0,0,1.289-1.91,5.846,5.846,0,0,0,.469-2.332,5.853,5.853,0,0,0-.469-2.332,6.075,6.075,0,0,0-1.289-1.911,6.1,6.1,0,0,0-1.91-1.289,5.867,5.867,0,0,0-2.332-.469h-3v-1.5a10.3,10.3,0,0,0-.82-4.09,10.618,10.618,0,0,0-2.25-3.34,10.64,10.64,0,0,0-3.34-2.25,10.32,10.32,0,0,0-4.09-.82,10.264,10.264,0,0,0-3.164.492,10.543,10.543,0,0,0-2.8,1.383,10.56,10.56,0,0,0-2.262,2.145,10.646,10.646,0,0,0-1.571,2.8,8.685,8.685,0,0,0-3.7-.821,8.789,8.789,0,0,0-3.5.7,9.178,9.178,0,0,0-2.859,1.933,9.122,9.122,0,0,0-1.932,2.871,8.8,8.8,0,0,0-.7,3.492,8.776,8.776,0,0,0,.7,3.5,9.165,9.165,0,0,0,1.932,2.859,9.165,9.165,0,0,0,2.859,1.933,8.793,8.793,0,0,0,3.5.7h8.112v3h0Z"
            transform="translate(2112 -2431.57)"
          />
        </svg>
        {!isDragActive && "Arraste arquivos aqui"}
        {isDragActive && "Solte os arquivos"}
      </StyledDropzone>
      {acceptedFiles.length > 0 && (
        <FileUploaded>
          {acceptedFiles.length > 0 &&
            acceptedFiles.map((acceptedFile) => (
              <p>
                {acceptedFile.name}
              </p>
            ))}
        </FileUploaded>
      )}
    </DropzoneContainer>
  );
}

export default FileUpload;
