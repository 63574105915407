import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import React from "react";

const client = axios.create({
  //baseURL: `https://api-taxcel.eastus.cloudapp.azure.com/backend/api`,
  baseURL: `${process.env.REACT_APP_URL_KUBERNETS}`,
  // baseURL: "http://52.249.179.137/api",
});

// middleware
client.interceptors.response.use(
  function (response) {
    // verifica se está na tela de manutenção e manda pra home
    redirectHome();
    return response;
  },
  function (error) {
    if (checaTrava(error.response)) {
      redirectManutencao();
    } else {
      redirectHome();
    }
    return Promise.reject(error);
  }
);

function checaTrava(response) {
  if (
    response &&
    response.data &&
    response.data.statusCode === 503 &&
    response.data.msg === "A aplicação encontra-se em manutenção."
  ) {
    return true;
  }

  return false;
}

function redirectManutencao() {
  if (window.location.pathname !== "/manutencao")
    window.location.href = "/manutencao";
}

function redirectHome() {
  if (window.location.pathname === "/manutencao") window.location.href = "/";
}

export default client;
