import styled from "styled-components";

export const Message = styled.p`
  font-size: ${(p) => (p.$size === "small" ? "14px" : "18px")};
  font-weight: 600;
  padding: 4px 8px;
  margin-top: ${(p) => (p.$mt ? p.$mt : "8")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "8")}px;
  margin-left: ${(p) => (p.$ml ? p.$ml : "0")}px;
  margin-right: ${(p) => (p.$mr ? p.$mr : "0")}px;
  color: ${(p) => (p.$color ? p.theme[p.$color] : p.theme.text)};

  svg {
    color: ${(p) => p.theme[p.$iconColor]};
    transform: translateY(4px);
    margin-right: 8px;
    width: ${(p) => (p.$size === "small" ? "20px" : "24px")};
    height: ${(p) => (p.$size === "small" ? "20px" : "24px")};
  }

  .mt-extra {
    transform: translateY(6px);
  }
`;
