import React from 'react';

import Button from "../Button"

const GAuthSincronizacao = props => (
    <>
        <h2>Sincronize</h2>
        <p>Clique no "+" que fica no canto inferior direito e depois em "Ler código QR"</p>
        <p>Escaneie o QR Code abaixo para começar a sincronização.</p>
        <img className="qr-code" src={props.qrCodeSrc} alt='QR Code para sincronização' />
        <p>Ou digite o código abaixo no Google Authenticator</p>
        <p className="codigo">{props.codigoAlternativo}</p>
        <div className="footer">
            <Button background="gray" onClick={props.prevStep}>Voltar</Button>
            <Button background="primary" onClick={props.nextStep}>Próximo</Button>
        </div>
    </>
);

export default GAuthSincronizacao;