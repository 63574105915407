import React from "react";
import { Link as LinkReact } from "react-router-dom";

import * as S from "./style";

import { ReactComponent as Arrow } from "./arrow_alt.inline.svg";
import { ReactComponent as NewTab } from "./open_in_new.svg";

export default function StyledLink({
  external,
  mt,
  mb,
  light,
  color = "primary",
  to,
  arrow,
  newTab,
  children,
  download,
  ...props
}) {
  return (
    <S.Link $color={color} $mt={mt} $mb={mb} $light={light} {...props}>
      {external ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          download={download}
        >
          {children}
          {arrow && <Arrow />}
          {newTab && <NewTab />}
        </a>
      ) : to ? (
        <LinkReact to={to}>
          {children}
          {arrow && <Arrow />}
          {newTab && <NewTab />}
        </LinkReact>
      ) : (
        <button>
          {children}
          {arrow && <Arrow />}
          {newTab && <NewTab />}
        </button>
      )}
    </S.Link>
  );
}
