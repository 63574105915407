import api from './config/Axios';

export default class CertificadoService {
    static async getCertificados(token) {
        /*
    {"certificados": [{
        "nome": "cert.pfx",
        "id": "27fe4a06-7a2a-4220-a7b2-cf6ec783b3e0",
        "docs": [{
            "id_doc": "2977f845-065d-4567-9d54-6711f1bf68d5",
            "ehCnpj": true,
            "num_doc": "04100894000142"
        }]
    }]} */
        return api({
            method: 'get',
            url: `/certificados/`,
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
    }

    static async criarCertificado(token, nome, senha, arquivo) {
        // let form = new FormData();
        // form.append(nome, arquivo);
      
        return api({
            method: 'post',
            url: `/certificados/`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "nome": nome,
                "senha": senha,
                "Content-Type": "multipart/form-data"
            },
            data: arquivo
        });
    }

    static async atualizarSenhaCertificado(token, nome, senha) {
        return api({
            method: "patch",
            url: `/certificados/`,
            headers: {
                Authorization: `Bearer ${token}`,
                "nome": nome,
                "senha": senha
            },
        });
    }

    static async deletarCertificado(token, nome) {
        return api({
            method: "delete",
            url: `/certificados/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                nome: nome
            }
        });
    }

    static async adicionarDocumento(token, nome_certificado, ehCnpj, num_doc) {
        return api({
            method: "post",
            url: `/certificados/documentos/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                nome: nome_certificado,
                cnpj: ehCnpj, // boolean, pra ver se é cnpj ou cpf
                num_doc: num_doc // numero do documento, cpf ou cnpj
            }
        });
    }

    static async deletarDocumento(token, nome_certificado, num_doc) {
        return api({
            method: "delete",
            url: `/certificados/documentos/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                nome: nome_certificado,
                num_doc: num_doc
            }
        });
    }
}