import api from "./config/Axios";
export default class BancoService {
  static async checaOperacaoEmAndamento(token) {
    return api({
      method: "get",
      url: `/operacoes/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getInfoArquivos(
    token,
    tipo_arquivo,
    page,
    limit = 1000,
    filtro
  ) {
    console.log({
      Authorization: `Bearer ${token}`,
      ...filtro,
    });
    return api({
      method: "get",
      url: `/bancos/arquivos/${page}/${limit}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        //"Lista-File": file_ids.toString().replace("[", "").replace("]", ""),
        "Tipo-Arquivo": tipo_arquivo,
        ...filtro,
      },
    });
  }

  static async carregaArquivosBanco(token, tipo_arquivo, file_ids) {
    return api({
      method: "post",
      url: `/bancos/arquivos/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        lista_file_ids: file_ids,
        tipo_arquivo: tipo_arquivo,
      },
    });
  }

  static async resetarBanco(token) {
    return api({
      method: "delete",
      url: `/bancos/reset/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async deletaArquivosBancoPorTipo(token, tipo_arquivo) {
    return api({
      method: "delete",
      url: `/bancos/arquivos/${tipo_arquivo}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async deletaArquivosBancoPorId(token, tipo_arquivo, lista_ids) {
    console.log(lista_ids);
    return api({
      method: "delete",
      url: `/bancos/arquivos/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        tipo_arquivo: tipo_arquivo,
        lista_file_ids: lista_ids,
      },
    });
  }

  static async getListaBancos(token) {
    return api({
      method: "get",
      url: "/vendas/bancos/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async criarBanco(token, obj) {
    return api({
      method: "post",
      url: "/bancos/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        nome: obj.nome,
      },
    });
  }

  static async renomearBanco(token, obj) {
    return api({
      method: "put",
      url: "/bancos/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_banco: obj.id_banco,
        nome: obj.nome,
      },
    });
  }

  static async deletarBanco(token, obj) {
    return api({
      method: "delete",
      url: "/bancos/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_banco: obj.id_banco,
      },
    });
  }
}
