import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { store } from "react-notifications-component";
import { ToastNotification, notificationTopCenter } from "../ToastNotification";
import "animate.css";

import AuthService from "../../services/AuthService";
import { verificaSenha } from "../../helpers/verificar-senha";

import DSButton from "../@DesignSystem/Button";
import DSFormInput from "../@DesignSystem/FormInput";

import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";
import RequisitosSenha from "../RequisitosSenha";
import LoadingIcon from "../@DesignSystem/LoadingIcon";
import Checkbox from "../@DesignSystem/Checkbox";

const HeaderLogin = styled.h4`
  font-size: 1.5rem;
  text-align: left;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  a:last-child {
    text-align: center;

    img {
      height: 36px;
      margin-top: 1.5rem;
      text-align: center;
    }
  }
`;

const SenhaCriada = styled.div`
  text-align: center;
  /* background-color: ${(props) => props.theme.successLight}; */
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;

  svg {
    height: 32px;
    width: 32px;
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.2rem;
  }
`;

const Loading = styled.div`
  text-align: center;
  padding: 2rem 0;

  svg {
    width: 4rem;
  }
`;

export default function CriaSenha(props) {
  const [token, setToken] = useState("");
  const [passValue, setPassValue] = useState("");
  const [invalidPassword, setInvalidPassword] = useState("");
  const [senhaCriada, setSenhaCriada] = useState("");
  const [confPassValue, setConfPassValue] = useState("");
  const [senhasDiferentes, setSenhasDiferentes] = useState("");
  const [sso, setSso] = useState(true);
  const [aceitou, setAceitou] = useState(false);
  const passInput = useRef(null);
  const confPassInput = useRef(null);
  let history = useHistory();

  const verificaSso = (token) => {
    AuthService.verificaSso(token)
      .then((resp) => {
        if (resp.data.sso_usuario) {
          setSso(true);
          AuthService.confirmarUsuarioSSO(token)
            .then((resp) => {
              if (resp.status == 200) {
                store.addNotification({
                  content: (
                    <ToastNotification bg="primary" textoAuxiliar="">
                      Usuário confirmado com sucesso
                    </ToastNotification>
                  ),
                  onRemoval: (id, removedBy) => {
                    console.log(removedBy);
                    // if (removedBy==="click") {
                    //   history.push("/");
                    // }
                  },
                  ...notificationTopCenter,
                });
              } else {
                store.addNotification({
                  content: (
                    <ToastNotification bg="danger" textoAuxiliar="">
                      Não foi possível confirmar o usuário, tente mais tarde.
                    </ToastNotification>
                  ),
                  onRemoval: (id, removedBy) => {
                    console.log(removedBy);
                    if (removedBy === "click") {
                      history.push("/");
                    }
                  },
                  ...notificationTopCenter,
                });
              }
            })
            .catch((err) => {
              console.log(err.status);
              store.addNotification({
                content: (
                  <ToastNotification bg="danger" textoAuxiliar="">
                    Erro ao confirmar usuário! Só permitido o cadastro de e-mail
                    corporativo com autenticação Microsoft/Google.
                  </ToastNotification>
                ),
                onRemoval: (id, removedBy) => {
                  console.log(removedBy);
                  if (removedBy === "click") {
                    history.push("/");
                  }
                },
                ...notificationTopCenter,
              });
            });
        } else {
          setSso(false);
        }
      })
      .catch((err) => {
        setSso(false);
        let msg = "Verifique se ele já não foi confirmado.";
        if (err.response.data.statusCode === 403) msg = "Token inválido.";
        setSso(false);
        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Usuário não encontrado, {msg}
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => {
            console.log(removedBy);
            if (removedBy === "click") {
              history.push("/");
            }
          },
          ...notificationTopCenter,
        });
        //console.log("Usuário não encontrado.",msg);
      });
  };

  useEffect(() => {
    if (passInput.current) {
      passInput.current.focus();
    }
    setToken(props.token);
    verificaSso(props.token);
  }, []);

  const criaNovaSenha = () => {
    if (!aceitou) {
      store.addNotification({
        content: (
          <ToastNotification bg="danger" textoAuxiliar="">
            Você precisa aceitar a política de privacidade para criar sua conta.
          </ToastNotification>
        ),
        //onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
      return;
    }
    if (!verificaSenha(passValue).ehForte) {
      setInvalidPassword(true);
      return;
    }

    if (passValue !== confPassValue) {
      setSenhasDiferentes(true);
      return;
    }
    setInvalidPassword(false);
    setSenhasDiferentes(false);

    AuthService.confirmarUsuario(token, passValue)
      .then((resp) => {
        //console.log(resp);
        setSenhaCriada(true);
      })
      .catch((err) => {
        let msg = "tente novamente mais tarde.";
        if (err.response.data.statusCode === 403) msg = "token inválido.";
        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Não foi possível criar sua senha, {msg}
            </ToastNotification>
          ),
          //onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopCenter,
        });
        // console.log(err.response);
      });
  };

  const handlePassChange = (event) => {
    setPassValue(event.target.value);
    setInvalidPassword(false);
  };

  const handleConfPassChange = (event) => {
    setConfPassValue(event.target.value);
    setSenhasDiferentes(false);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      criaNovaSenha();
    }
  };

  return (
    <>
      <HeaderLogin>Criar senha</HeaderLogin>
      <FormGroup>
        {sso ? (
          <Loading>
            <LoadingIcon />
          </Loading>
        ) : !senhaCriada ? (
          <>
            <DSFormInput
              inputRef={passInput}
              required
              type="password"
              id="password"
              label="Senha"
              onChange={handlePassChange}
              value={passValue}
              onKeyUp={handleKeyUp}
              errorMsg="Complete todos os requisitos de senha"
              error={invalidPassword}
              mt={24}
            />
            <RequisitosSenha password={passValue} />
            <DSFormInput
              inputRef={confPassInput}
              required
              type="password"
              id="confPassword"
              label="Confirmar senha"
              onChange={handleConfPassChange}
              value={confPassValue}
              onKeyUp={handleKeyUp}
              errorMsg="As senhas não coincidem"
              error={senhasDiferentes}
              mb={24}
            />
            <div style={{display: "flex", alignContent: "flex-start", marginBottom: 32}}>
              <Checkbox
                label=""
                mt={8}
                mb={16}
                checked={aceitou}
                onChange={() => setAceitou(!aceitou)}
              />
              <p style={{fontSize: 16, marginTop: 2, marginLeft: 4}}>
                Declaro que li e concordo com a{" "}
                <a
                  href="https://taxcel.com.br/politica-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  política de privacidade
                </a>
              </p>
            </div>
            {!sso && (
              <DSButton fluid background="primary" onClick={criaNovaSenha}>
                Criar senha
              </DSButton>
            )}
          </>
        ) : (
          <>
            <SenhaCriada>
              <AcceptIcon />
              <p>Senha criada com sucesso!</p>
            </SenhaCriada>
            <DSButton
              fluid
              background="primary"
              onClick={() => history.push("/")}
            >
              Fazer Login
            </DSButton>
          </>
        )}
      </FormGroup>
    </>
  );
}
