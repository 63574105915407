import api from './config/Axios';
export default class UsuarioService {
    static async validarTokenLogin(token) {
        return api({
            method: 'get',
            url: `/usuarios/sessao/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async validarMsal(tokenMsal, tokenLogin) {
        /* email / token */
        return api({
            method: 'get',
            url: `/usuarios/validar/sso`,
            headers: {
                "Authorization": `Bearer ${tokenMsal}`,
                "api-token": `Bearer ${tokenLogin}`
            }
        });
    }

    static async verificaSso(token) {
        return api({
            method: 'get',
            url: `/usuarios/sso/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async getTwofactorQRCode(token) {
        return api({
            method: 'get',
            url: `/usuarios/twofactor/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async validaTokenTwofactor(token, tokenGauth) {
        return api({
            method: 'get',
            url: `/usuarios/twofactor/${tokenGauth}/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async alternarTwoFactor(token, senha, tokenGauth) {
        return api({
            method: 'put',
            url: `/usuarios/twofactor/`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                senha: senha,
                token: tokenGauth,
            },
        })
    }

    static async alternarTwoFactorMsal(token, tokenMsal, tokenGauth) {
        return api({
            method: 'put',
            url: `/usuarios/twofactorMsal/`,
            headers: {
                "Authorization": `Bearer ${tokenMsal}`,
                "api-token": `Bearer ${token}`
            },
            data: {
                token: tokenGauth
            },
        })
    }

    static async alteraSenha(token, senha, novaSenha) {
        return api({
            method: 'put',
            url: `/usuarios/senha/`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                senha: senha,
                novasenha: novaSenha,
            },
        });
    }

    static async verficaPermissao(token) {
        return api({
            method: 'get',
            url: `/usuarios/permissoes/`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
        })
    }

    static async reenviaToken(token) {
        return api({
            method: 'get',
            url: `/usuarios/tokenApi/reenviar`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
        })
    }

    static async criarUsuario(token, obj) {
        return api({
            method: 'post',
            url: '/usuarios/',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: obj,
        });
    }

    static async adicionaPermissao(token, obj) {
        return api({
            method: 'put',
            url: '/usuarios/permissoes/',
            data: {
                email: obj.email,
                permissao: obj.permissao,
                id_banco: obj.id_banco
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async removePermissao(token, obj) {
        return api({
            method: 'delete',
            url: '/usuarios/permissoes/',
            data: {
                email: obj.email,
                permissao: obj.permissao
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }

        });
    }

    static async tornarAdmin(token, obj) {
        // console.log(obj.novo_contato);
        return api({
            method: 'put',
            url: '/usuarios/permissoes/admin',
            data: {
                novo_contato: obj.novo_contato
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async alterarBancoUsuario(token, obj) {
        return api({
            method: 'put',
            url: '/usuarios/bancos/',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                email: obj.email,
                banco: obj.banco
            }
        });
    }

    static async deletarUsuario(token, obj) {
        return api({
            method: 'delete',
            url: '/usuarios/',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                email: obj.email
            }
        });
    }

    static async getListaUsuarios(token) {
        return api({
            method: 'get',
            url: '/vendas/usuarios/',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async getSessaoUsuarioPorEmail(email, token) {
        // TODO
        return api({
            method: 'get',
            url: `/usuarios/sessao/${email}/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async reenviarEmail(token, email) {
        return api({
            method: 'get',
            url: `/usuarios/confirmar/reenviar`,
            headers: {
                "Authorization": `Bearer ${token}`,
                email
            }
        });
    }

}