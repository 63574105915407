import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from 'styled-components/macro';

import { ReactComponent as CancelLogo } from "../icons/cancel-icon.svg"

const modalRoot = document.getElementById("modal-root");

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 0.15s;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-wrapper {
        position: relative;
        width: 100%;
        max-height: 100vh;
        z-index: 2001;
        overflow: auto;
    }

    &.fade-in {
        opacity: 1;
        transition: opacity linear 0.15s;
    }
    &.fade-out {
        opacity: 0;
        transition: opacity linear 0.15s;
    }

    .background {
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        z-index: 101;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
    }

    .dialog-wrapper {
        position: relative;
        z-index: 2002;
        width: ${props => {
            switch (props.modalSize) {
                case "lg":
                    return "744";
                case "sm":
                    return "344";
                case "xs":
                    return "300";
                default:
                    return "480";
        }}}px;
        margin: 2.5rem auto;
        
        .x-close {
            display: block;
            width: 2rem;
            height: 2rem;
            border-radius: 1rem;
            position: absolute;
            right: -3rem;
            top: .5rem;
            padding: 0;
            font-size: 1rem;
            text-align: center;
            line-height: 1em;
            text-shadow: none;
            color: #FFFFFF85;
            cursor: pointer;
            transition: all .3s ease;
            background: black;
            border: none;

            &:hover {
                color: #FFFFFF;
            }
        }
    }

  .box-dialog {
    width: 100%;
    box-sizing: border-box;
    background-color: #fefefe;
    box-shadow: 0px 5px 10px #00000040;
    border-top: 0px solid white;
    border-radius: 8px;
    border-color: ${props => {
        switch (props.modalTipo) {
            case "danger":
                return props.theme.danger;
            case "primary":
                return props.theme.primary;
            default:
                return props.theme.secondary;
        }
    }};
    overflow: hidden;
    .progress {
        width: ${props => props.progress}%;
        position: relative;
        height: 4px;
        background-color: ${(props) =>
    ({
      primary: props.theme.primary,
      secondary: props.theme.secondary,
      danger: props.theme.dangerDark,
      gray: props.theme.lightGray,
    }[props.pgBackground])};
    }
    .box-content {
        padding: 1.5em 3em;
        width: 100%;
        box-sizing: border-box;
    }
    .box-body {
      font-size: 1rem;
      padding: 0px;
      width: auto;
      height: auto;
    }
    .box-footer {
      height: 48px;
      padding: 0px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #FFF;

      .footer-button {
        background: ${props => props.theme.danger};
        border: none;
        margin-bottom: 1em;
        padding: .5em 1em;
        border-radius: 3px;
        cursor: pointer;
        color: white;
        font-size: 1em;
        transition: all .35s ease;
      }
    }
  }
`;

class Modal extends Component {
    static defaultProps = {
        id: "",
        modalClass: "",
        modalSize: "md",
        modalTipo: "default"
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        modalClass: PropTypes.string,
        modalSize: PropTypes.string,
        modalTipo: PropTypes.string
    };

    state = { fadeType: null };

    background = React.createRef();

    componentDidMount() {
        window.addEventListener("keydown", this.onEscKeyDown, false);
        setTimeout(() => this.setState({ fadeType: "in" }), 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isOpen && prevProps.isOpen) {
            this.setState({ fadeType: "out" });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onEscKeyDown, false);
    }

    transitionEnd = e => {
        if (e.propertyName !== "opacity" || this.state.fadeType === "in") return;

        if (this.state.fadeType === "out") {
            this.props.onClose();
        }
    };

    onEscKeyDown = e => {
        if (e.key !== "Escape") return;
        this.setState({ fadeType: "out" });
    };

    handleClick = e => {
        e.preventDefault();
        this.setState({ fadeType: "out" });
    };

    render() {
        return ReactDOM.createPortal(
            <StyledModal
                id={this.props.id}
                className={`wrapper ${"size-" + this.props.modalSize} fade-${
                    this.state.fadeType
                    } ${this.props.modalClass}`}
                role="dialog"
                modalSize={this.props.modalSize}
                modalTipo={this.props.modalTipo}
                onTransitionEnd={this.transitionEnd}
                pgBackground={this.props.pgBackground || "primary"}
                progress={this.props.progress || 0}
            >
                <div className="modal-wrapper">
                    <div className="dialog-wrapper">
                        <button onClick={this.handleClick} className="x-close">
                            <CancelLogo className="icon" />
                        </button>
                        <div className="box-dialog">
                            <div className="progress"></div>
                            <div className="box-content">{this.props.children}</div>
                            {this.props.footer && 
                            <div className="box-footer">
                                <button className="footer-button" onClick={this.handleClick}>
                                    Fechar
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                    <div
                        className={`background`}
                        onMouseDown={this.handleClick}
                        ref={this.background}
                    />
                </div>
            </StyledModal>,
            modalRoot
        );
    }
}

export default Modal;
