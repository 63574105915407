import React from "react";
import styled from "styled-components/macro";

import Button from "../Button";

import Modal from "../Modal";

import AlteraTudo from "./AlteraTudo";

import moment from "moment";
import "moment/locale/pt-br";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
  notificationTopCenter,
} from "../ToastNotification";
import UsuarioService from "../../services/UsuarioService";
import BancoService from "../../services/BancoService";

const StyledH4 = styled.h4`
  font-size: 1.75rem;
  margin-bottom: 1em;
  margin-top: 4rem;
`;

const ModalContainer = styled.div`
  h2 {
    font-size: 1.25em;
    line-height: 1.3;
  }

  p {
    margin-top: 1em;
    font-size: 1em;
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export class AlteraBancoPermissao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      isLoading: false,
      isModalAdmOpen: false,
      isModalDeleteOpen: false,
      selectedOption: null,
      usuario: "",
      bancosDisponiveis: [
        /*   { value: "bancoid1", label: "Banco 1" },*/
      ],
      bancosNaoDisponiveis: [
        /*   { value: "bancoid4", label: "Banco 4", isDisabled: true },*/
      ],
      arrayCompleto: [
        /* {
          nome: "Usuario 1",
          id: 1,
          permissao: "comum",
          comum: true,
          banco: { value: "bancoid4", label: "Banco 4" },
        },
        {
          nome: "Usuario 3",
          id: 3,
          permissao: "comum",
          comum: true,
          banco: null,
        },*/
      ],
    };
    this.atualizaBancosUsuarios = this.atualizaBancosUsuarios.bind(this);
  }

  toggleModalAdm = (usuario) => {
    this.setState({
      isModalAdmOpen: !this.state.isModalAdmOpen,
      usuario,
    });
  };

  toggleModalDelete = (usuario) => {
    this.setState({
      isModalDeleteOpen: !this.state.isModalDeleteOpen,
      usuario,
    });
  };

  componentDidMount() {
    this.atualizaBancosUsuarios();
  }

  convertTimestamp = (time) => {
    let formatedTime = moment(time);
    formatedTime.locale("pt-br");
    return formatedTime.calendar();
  };

  atualizaBancosUsuarios() {
    //console.log("atualizando bancos e usuarios");
    BancoService.getListaBancos(this.props.tokenLogin)
      .then((resp) => {
        UsuarioService.getListaUsuarios(this.props.tokenLogin).then((resp2) => {
          let usuariosLista = [];
          usuariosLista = resp2.data.venda.usuarios.flatMap((usuario) => {
            // if (usuario.permissao === "Contato") {
            //   return [];
            // } else {
            let usuarioObj = {
              nome: usuario.email,
              id: usuario.uuid,
              permissao: usuario.permissao.toLowerCase(),
              comum: usuario.podeEscrever,
              ultimoLogin: usuario.ultimo_login || "",
              qtdLogins: usuario.acessos || "",
              ultimaOper: usuario.ultimaOper || "",
              dataUltimaOper: usuario.dataUltimaOper || "",
            };

            let banco_usuario = this.getBancoUsuario(
              resp.data.bancos.bancos_nao_disponiveis,
              usuario.banco
            );
            if (!banco_usuario) {
              banco_usuario = this.getBancoUsuario(
                resp.data.bancos.bancos_disponiveis,
                usuario.banco
              );
            }
            usuarioObj.banco = banco_usuario;
            return usuarioObj;
            // }
          });
          

          this.setState({
            arrayCompleto: usuariosLista.sort((a, b) => a.nome.localeCompare(b.nome, "pt", {'sensitivity': 'base'})),
          });
        });

        let bancos_disponiveis = [];
        let bancos_nao_disponiveis = [];
        bancos_disponiveis = resp.data.bancos.bancos_disponiveis.map((banco) => ({
          label: banco.nome,
          value: banco.uuid,
        }));
        bancos_nao_disponiveis = resp.data.bancos.bancos_nao_disponiveis.map(
          (banco) => ({
            label: banco.nome,
            value: banco.uuid,
          })
        );

        this.setState({
          bancosDisponiveis: bancos_disponiveis,
          bancosNaoDisponiveis: bancos_nao_disponiveis,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // getBancoUsuario2(bancos, banco_usuario) {
  //   let resp = null;
  //   bancos.some((banco) => {
  //     if (banco_usuario === banco.uuid) {
  //       resp = {
  //         value: banco.uuid,
  //         label: banco.nome
  //       };
  //     }
  //   });
  //   return resp;
  // }

  getBancoUsuario(bancos, banco_usuario) {
    let resp = bancos.filter((banco) => banco_usuario === banco.uuid);
    if (!resp[0]) {
      return null;
    } else {
      return {
        value: resp[0].uuid,
        label: resp[0].nome,
      };
    }
  }

  apagarUsuario = () => {
    let obj = {
      email: this.state.usuario,
    };
    UsuarioService.deletarUsuario(this.props.tokenLogin, obj)
      .then((resp) => {
        //sucesso
        this.toggleModalDelete();
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Usuário deletado com sucesso!{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => window.location.reload(true),
          ...notificationTopCenter,
        });
        this.atualizaBancosUsuarios();
      })
      .catch((err) => {
        //erro
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível deletar o usuário.Tente novamente ou entre em
              contato com o suporte{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => window.location.reload(true),
          ...notificationTopCenter,
        });
      });
  };

  tornarAdmin = () => {
    //console.log(this.state.usuario);
    let obj = {
      novo_contato: this.state.usuario,
    };

    UsuarioService.tornarAdmin(this.props.tokenLogin, obj)
      .then((resp) => {
        //sucesso
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Permissão de administrador alterada com sucesso!{" "}
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => window.location.reload(true),
          ...notificationTopCenter,
        });
      })
      .catch((err) => {
        //erro
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível passar a permissão de administrador.Tente
              novamente ou entre em contato com o suporte{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => window.location.reload(true),
          ...notificationTopCenter,
        });
      });
  };

  render() {
    return (
      <>
        <StyledH4> Alterar Bancos e Permissões </StyledH4>{" "}
        {this.state.arrayCompleto.map((user) => (
          <AlteraTudo
            key={user.nome}
            handleToggleModalAdm={this.toggleModalAdm}
            handleToggleModalDelete={this.toggleModalDelete}
            atualizaBancosUsuarios={this.atualizaBancosUsuarios}
            tokenLogin={this.props.tokenLogin}
            bancosDisponiveis={this.state.bancosDisponiveis}
            bancosNaoDisponiveis={this.state.bancosNaoDisponiveis}
            ultimoLogin={user.ultimoLogin}
            qtdLogins={user.qtdLogins}
            ultimaOper={user.ultimaOper}
            dataUltimaOper={user.dataUltimaOper}
            {...user}
          ></AlteraTudo>
        ))}{" "}
        {this.state.isModalAdmOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalAdmOpen}
            onClose={this.toggleModalAdm}
            modalSize="md"
          >
            <ModalContainer>
              <h2>
                Tem certeza de que deseja tornar <b> {this.state.usuario} </b> o
                novo administrador ?
              </h2>{" "}
              <p> Você deixará de ser o administrador </p>{" "}
              <div className="buttons">
                <Button background="gray" onClick={this.toggleModalAdm}>
                  Cancelar{" "}
                </Button>{" "}
                <Button background="primary" onClick={this.tornarAdmin}>
                  Confirmar{" "}
                </Button>{" "}
              </div>{" "}
            </ModalContainer>{" "}
          </Modal>
        )}{" "}
        {this.state.isModalDeleteOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalOpen}
            onClose={this.toggleModalDelete}
            modalSize="md"
          >
            <ModalContainer>
              <h2>
                Tem certeza de que deseja apagar o usuário{" "}
                <b className="email"> {this.state.usuario} </b> ?{" "}
              </h2>{" "}
              <div className="buttons">
                <Button background="gray" onClick={this.toggleModalDelete}>
                  Cancelar{" "}
                </Button>{" "}
                <Button background="danger" onClick={this.apagarUsuario}>
                  Apagar{" "}
                </Button>{" "}
              </div>{" "}
            </ModalContainer>{" "}
          </Modal>
        )}{" "}
      </>
    );
  }
}

export default AlteraBancoPermissao;
