import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    
    50% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
`

const StyledLoadingIcon = styled.div`
  display: inline-block;

  svg {
    width: ${props => props.big ? "1.5rem" : "1rem"};
    animation: ${spin} 850ms linear infinite;
    fill: ${props => 
        props.fill === 'primary' ? props.theme.primary :
            props.fill === 'danger' ? props.theme.danger :
                props.fill === 'success' ? props.theme.success :
                  props.fill === 'white' ? props.theme.background :
                    props.theme.text};
  }
`

const LoadingIcon = props => (
    <StyledLoadingIcon fill={props.fill} big={props.big}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g transform="translate(-636 -312)"><path d="M-2090.024,2450.677h0l-2.611-1.507A8.933,8.933,0,0,0-2091,2444a9.01,9.01,0,0,0-9-9v-3a11.922,11.922,0,0,1,8.486,3.516A11.92,11.92,0,0,1-2088,2444a11.921,11.921,0,0,1-2.024,6.676Z" transform="translate(2748 -2120)" /><g transform="translate(636 312)" fill="none" stroke="rgba(112,112,112,0)" strokeWidth="1"><circle cx="12" cy="12" r="12" stroke="none"/><circle cx="12" cy="12" r="11.5" fill="none"/></g></g></svg>
    </StyledLoadingIcon>
);

export default LoadingIcon;