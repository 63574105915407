import Dexie from "dexie";

export default class GerenciaTokenCliente {
  static async salvaBDBrowser(token) {
    this.dbPromise = new Dexie("taxcel_app_bd");
    this.dbPromise.version(1).stores({
      usuario: "token",
    });

    await this.dbPromise.usuario.clear();

    await this.dbPromise.usuario.add({
      token: token,
    });
    return true;
  }

  static async limpaToken() {
    this.dbPromise = new Dexie("taxcel_app_bd");
    this.dbPromise.version(1).stores({
      usuario: "token",
    });

    await this.dbPromise.usuario.clear();

    return true;
  }

  static async getUserToken() {
    let buscaToken = await this.dbPromise.usuario.toArray();
    return buscaToken[0] ? buscaToken[0].token : null;
  }

  static async verificaUserToken() {
    this.dbPromise = new Dexie("taxcel_app_bd");
    this.dbPromise.version(1).stores({
      usuario: "token",
    });
    let buscaToken = await this.dbPromise.usuario.toArray();
    return buscaToken[0] ? buscaToken[0].token : null;
  }
}
