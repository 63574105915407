export const cnpjMask = (value) => {
  let v = value.replace(/\D/g, "");
  
  if (value.length < 14) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    // v = v.replace(/(\d{2})(\d)/, "$1-$2");
    v = v.substring(0, 18);
    return v;
  }

  v = v.replace(/(\d{2})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");
  v = v.substring(0, 18);

  return v;
};

export const telMask = (value) => {
  let v = value.replace(/\D/g, "");
  v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

  if (value.length < 15) v = v.replace(/(\d{4})(\d)/, "$1-$2");
  else v = v.replace(/(\d{5})(\d)/, "$1-$2");

  v = v.substring(0, 15);

  return v;
};

export const cepMask = (value) => {
  let v = value.replace(/\D/g, "");
  v = v.replace(/(\d{5})(\d)/, "$1-$2");
  v = v.substring(0, 9);

  return v;
};

export const estadoMask = (value) => {
  let v = value.replace(/[^a-zA-Z]/g, "");
  // v = v.replace(/(\d{2})(\d)/, "$1-$2");
  v = v.substring(0, 2);
  v = v.toUpperCase();

  return v;
};
