import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import CertificadoService from "../../services/CertificadoService";
import { cnpjMask } from "../../helpers/masks";
import moment from "moment";

import Button from "../@DesignSystem/Button";
import LoadingIcon from "../@DesignSystem/LoadingIcon";
import Title from "../@DesignSystem/Title";
import Radio from "../@DesignSystem/Radio";
import SwitchButton from "../@DesignSystem/SwitchButton";
import Checkbox from "../@DesignSystem/Checkbox";

import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { useInput } from "../../hooks/useInput";
import FormInput from "../@DesignSystem/FormInput";
import { store } from "react-notifications-component";
import { notificationTopRight, ToastNotification } from "../ToastNotification";
import ArquivosService from "../../services/ArquivosService";
import mixpanel from "mixpanel-browser";

const Wrapper = styled.div``;

const ErrorMsg = styled.p`
  color: ${(p) => p.theme.danger};
  font-size: 14px;
  font-weight: 600;
  text-align: right;
`;

const NenhumCertificado = styled.div`
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 20px;
    color: ${(p) => p.theme.textGray};
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

const Certificados = styled.div`
  min-height: 360px;
  margin-top: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  .footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 0px;
    padding-top: 16px;
  }
`;

const Docs = styled.div`
  margin: 16px 8px;

  .flex {
    display: flex;
    align-items: flex-start;

    button {
      margin-left: 16px;
    }
  }
`;

const Body = styled.div``;

const Breadcrumb = styled.div`
  font-weight: 600;
  color: ${(p) => p.theme.primary};
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    color: ${(p) => p.theme.text};
  }

  p {
    color: ${(p) => p.theme.text};
    font-size: 14px;
    padding: 4px 0;
  }

  button {
    color: ${(p) => p.theme.primary};
    background: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    padding: 4px 0;
    max-width: 160px;

    span {
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
    }
  }
`;

const tiposSuportados = ["icms", "contrib", "contabil", "ecf"];

export default function UploadComCertificado({
  tokenLogin,
  arquivo,
  toggleUploadModal,
  checkOperacao,
}) {
  const history = useHistory();

  const [certificadosCadastrados, setCertificadosCadastrados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [certificadoSelecionado, setCertificadoSelecionado] = useState(null);
  const [isProcurador, setIsProcurador] = useState(0);
  const [docSelecionado, setDocSelecionado] = useState("");

  const [novoDoc, setNovoDoc] = useState(false);
  const [errorDoc, setErrorDoc] = useState(false);

  const [step, setStep] = useState(0);

  const [dataNaoSelecionada, setDataNaoSelecionada] = useState([]);

  const [nenhumArquivo, setNenhumArquivo] = useState(false);

  const [datasArquivos, setDatasArquivos] = useState({});
  const [arquivosSelecionados, setarquivosSelecionados] = useState(
    tiposSuportados.reduce((i, value) => ({ ...i, [value]: false }), {})
  );

  const newDocRef = useRef(null);

  const {
    value: cnpjValue,
    bind: bindCnpjValue,
    reset: resetcnpjValue,
    check: checkCnpj,
    isInvalid: invalidCnpj,
  } = useInput("", "cnpj", "cnpj");

  // useEffect(() => {
  //   console.log(arquivosSelecionados);
  // }, [arquivosSelecionados]);

  // useEffect(() => {
  //   console.log(datasArquivos);
  // }, [datasArquivos]);

  // useEffect(() => {
  //   console.log(dataNaoSelecionada);
  // }, [dataNaoSelecionada]);

  const goToCertificado = () => {
    history.push("/configuracoes#certificado");

    mixpanel.track("Foi para pagina de adicionar certificado");
  };

  useEffect(() => {
    atualizaCertificados();

    mixpanel.track("Abriu upload com certificado");

    let tipoAtual = tiposSuportados.find(
      (tipo) => tipo === arquivo.toLowerCase()
    );

    handleSelectArquivo(tipoAtual);
  }, []);

  const atualizaCertificados = () => {
    setIsLoading(true);
    CertificadoService.getCertificados(tokenLogin)
      .then((resp) => {
        setCertificadosCadastrados(resp.data.certificados);
        //atualiza lista de docs
        // let novoCertSelecionado = resp.data.certificados.filter(
        //   (certificado) => certificado.nome === certificadoSelecionado?.nome
        // )[0];
        // setCertificadoSelecionado(null);
        // setCertificadoSelecionado(novoCertSelecionado);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDateChange = (e, tipo) => {
    e.persist();
    let date = moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY");

    setDatasArquivos((prevState) => ({
      ...prevState,
      [tipo]: { ...prevState[tipo], [e.target.name]: date },
    }));
  };

  const adicionaDoc = () => {
    if (invalidCnpj || checkCnpj()) return;

    let ehCnpj = cnpjValue.length > 14;

    mixpanel.track("Adiciona doc", {
      source: "Upload",
    });

    CertificadoService.adicionarDocumento(
      tokenLogin,
      certificadoSelecionado.nome,
      ehCnpj,
      cnpjValue
    )
      .then((resp) => {
        setNovoDoc(false);
        resetcnpjValue();
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Documento cadastrado!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
        atualizaCertificados();
        setDocSelecionado(cnpjValue);
        setStep(step + 1);
      })
      .catch((err) => {
        console.error(err);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      adicionaDoc();
    }
  };

  const handleSelectArquivo = (tipo) => {
    setarquivosSelecionados((prevState) => ({
      ...prevState,
      [tipo]: !prevState[tipo],
    }));
  };

  const goToArquivos = () => {
    if (isProcurador && !docSelecionado) {
      setErrorDoc(true);
      return;
    }

    mixpanel.track("Escolheu empresa", {
      Contribuinte: !isProcurador,
    });
    setStep(2);
  };

  const importarArquivos = () => {
    let pedidos = [];

    setDataNaoSelecionada([]);

    let invalidDate =
      !datasArquivos[arquivo]?.dtIni ||
      !datasArquivos[arquivo]?.dtFim ||
      datasArquivos[arquivo]?.dtIni === "Invalid date" ||
      datasArquivos[arquivo]?.dtFim === "Invalid date";

    if (invalidDate) {
      setDataNaoSelecionada((prevState) => [...prevState, arquivo]);
    } else {
      pedidos.push({
        tipo: arquivo.toUpperCase(),
        dt_ini: datasArquivos[arquivo].dtIni,
        dt_fim: datasArquivos[arquivo].dtFim,
      });
    }

    console.log(pedidos);

    let obj = {
      ehProcurador: isProcurador,
      ehCpf: docSelecionado?.length === 11,
      num_doc: docSelecionado,
      nome_certificado: certificadoSelecionado.nome,
      pedidos: pedidos,
    };

    console.log(obj);

    mixpanel.track("Tentou importar arquivos");

    ArquivosService.importarArquivosCertificado(
      tokenLogin,
      pedidos,
      isProcurador,
      docSelecionado?.length === 11,
      docSelecionado,
      certificadoSelecionado.nome
    )
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Os arquivos estão sendo importados!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });

        //Limpa seleções
        // setStep(0);
        // setCertificadoSelecionado(null);
        // setDocSelecionado(null);
        // setArquivos({});

        setTimeout(() => {
          checkOperacao();
        }, 2000);

        toggleUploadModal();
      })
      .catch((err) => {
        console.error(err);
        mixpanel.track("Erro ao importar arquivos", {
          error: err.response.data.descricao,
        });

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  return (
    <Wrapper>
      {isLoading ? (
        <NenhumCertificado>
          <p>Pesquisando certificados cadastrados...</p>
          <LoadingIcon size="big" />
        </NenhumCertificado>
      ) : certificadosCadastrados.length === 0 ? (
        <NenhumCertificado>
          <p>Nenhum certificado cadastrado.</p>
          <Button onClick={goToCertificado}>Adicionar novo certificado</Button>
        </NenhumCertificado>
      ) : (
        <Certificados>
          {step === 0 ? (
            <>
              <Breadcrumb>
                <p>Certificado</p>
              </Breadcrumb>
              <Title headingLevel="h6">Selecione o certificado:</Title>
              {certificadosCadastrados.map((certificado) => (
                <Button
                  onClick={() => {
                    setCertificadoSelecionado(certificado);
                    setStep(1);
                    mixpanel.track("Selecionou arquivo de certificado");
                  }}
                  fullWidth
                  mt={16}
                  bg="grayLight3"
                  color="text"
                >
                  {certificado.nome}
                </Button>
              ))}
              <Button
                onClick={goToCertificado}
                fullWidth
                mt={32}
                bg="primaryLight"
                color="primary"
                secondary
              >
                Cadastrar novo certificado
              </Button>
            </>
          ) : step === 1 ? (
            <>
              <Breadcrumb>
                <button onClick={() => setStep(0)}>
                  <span>{certificadoSelecionado?.nome}</span>
                </button>
                <ArrowRight />
                <p>Empresa</p>
              </Breadcrumb>
              <Title headingLevel="h6">Selecione a empresa:</Title>
              <div className="empresa">
                <SwitchButton
                  variant={1}
                  selection={isProcurador ? 1 : 0}
                  handleChange={(index) => setIsProcurador(index === 1)}
                  options={["Contribuinte", "Procurador"]}
                  // size="small"
                />
                {!isProcurador ? null : certificadoSelecionado.docs.length ? (
                  <Docs>
                    <Radio
                      // size="small"
                      selected={0}
                      name="doc"
                      handleChangeParent={(value) => setDocSelecionado(value)}
                      options={certificadoSelecionado.docs.map((doc) => ({
                        label: cnpjMask(doc.num_doc),
                        value: doc.num_doc,
                      }))}
                    >
                      CPF/CNPJ:
                    </Radio>
                    {novoDoc ? (
                      <div className="flex">
                        <FormInput
                          ref={newDocRef}
                          required
                          mt={16}
                          label="CNPJ ou CPF"
                          error={invalidCnpj}
                          errorMsg="Digite um documento válido"
                          width={200}
                          onKeyDown={handleKeyDown}
                          {...bindCnpjValue}
                        />
                        <Button onClick={adicionaDoc} mt={36} size="small">
                          Cadastrar
                        </Button>
                        <Button
                          onClick={() => setNovoDoc(false)}
                          mt={36}
                          secondary
                          color="text"
                          bg="grayLight"
                          size="small"
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <Button
                        small
                        secondary
                        color="primary"
                        bg="primaryLight"
                        mt={16}
                        onClick={() => setNovoDoc(true)}
                      >
                        Cadastrar nova empresa
                      </Button>
                    )}
                  </Docs>
                ) : (
                  <Docs>
                    <p>Nenhuma empresa cadastrada</p>
                    {novoDoc ? (
                      <div className="flex">
                        <FormInput
                          ref={newDocRef}
                          required
                          mt={16}
                          label="CNPJ ou CPF"
                          error={invalidCnpj}
                          errorMsg="Digite um documento válido"
                          width={200}
                          onKeyDown={handleKeyDown}
                          {...bindCnpjValue}
                        />
                        <Button onClick={adicionaDoc} mt={36} size="small">
                          Cadastrar
                        </Button>
                        <Button
                          onClick={() => setNovoDoc(false)}
                          mt={36}
                          secondary
                          color="text"
                          bg="grayLight"
                          size="small"
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <Button
                        small
                        secondary
                        color="primary"
                        bg="primaryLight"
                        mt={16}
                        onClick={() => setNovoDoc(true)}
                      >
                        Cadastrar novo documento
                      </Button>
                    )}
                  </Docs>
                )}
              </div>
              {errorDoc && (
                <ErrorMsg>
                  Selecione uma empresa ou troque para a opção "Contribuinte"
                  para continuar
                </ErrorMsg>
              )}
              <div className="footer">
                <Button
                  secondary
                  color="text"
                  bg="grayLight"
                  onClick={() => {
                    setCertificadoSelecionado(null);
                    setStep(0);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  // secondary
                  // color="primary"
                  bg="primary"
                  onClick={goToArquivos}
                >
                  Selecionar arquivos <ArrowRight />
                </Button>
              </div>
            </>
          ) : (
            <>
              <Body>
                <Breadcrumb>
                  <button onClick={() => setStep(0)}>
                    <span>{certificadoSelecionado.nome}</span>
                  </button>
                  <ArrowRight />
                  <button onClick={() => setStep(1)}>
                    <span>
                      {docSelecionado
                        ? cnpjMask(docSelecionado)
                        : "Contribuinte"}
                    </span>
                  </button>
                  <ArrowRight />
                  <p>Arquivos</p>
                </Breadcrumb>
                <Title headingLevel="h6">
                  Selecione o(s) tipo(s) de arquivo e seu período de
                  escrituração:
                </Title>
                <div className="arquivos">
                  <ArquivosRow
                    tipo={arquivo}
                    checked={true}
                    handleSelectArquivo={() => console.log(arquivo)}
                    handleDateChange={(e) => handleDateChange(e, arquivo)}
                    dataNaoSelecionada={dataNaoSelecionada.includes(arquivo)}
                  />
                  {nenhumArquivo && (
                    <ErrorMsg>Selecione pelo menos 1 tipo de arquivo</ErrorMsg>
                  )}
                  <div className="footer">
                    <Button
                      secondary
                      color="text"
                      bg="grayLight"
                      onClick={() => {
                        setDocSelecionado(null);
                        setStep(1);
                      }}
                    >
                      Voltar
                    </Button>
                    <Button bg="primary" onClick={importarArquivos}>
                      Importar arquivos
                    </Button>
                  </div>
                </div>
              </Body>
            </>
          )}
        </Certificados>
      )}
    </Wrapper>
  );
}

const DateWrapper = styled.div`
  label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(p) => p.theme.text};
  }

  &:focus-within {
    label {
      color: ${(p) => p.theme.primary};
    }
  }

  input[type="date"] {
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.input.border};

    &:active {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }

    &:focus {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }
  }
`;

const ArquivosDates = ({ handleDateChange }) => {
  return (
    <>
      <DateWrapper>
        <label>
          Data Início
          <input
            name={`dtIni`}
            type="date"
            onChange={(e) => handleDateChange(e)}
          />
        </label>
      </DateWrapper>
      <DateWrapper>
        <label>
          Data Fim
          <input
            name={`dtFim`}
            type="date"
            onChange={(e) => handleDateChange(e)}
          />
        </label>
      </DateWrapper>
    </>
  );
};

const ArquivosRowWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  align-items: center;
  gap: 16px;
  padding: 8px 12px;
  background: ${(p) =>
    p.checked ? p.theme.primaryLighter : p.theme.background};
  border-radius: 0 12px;
  margin-top: 16px;
`;

const titulosArquivo = {
  icms: "EFD ICMS",
  contrib: "EFD Contrib",
  contabil: "ECD Contabil",
  ecf: "ECF",
};

const ArquivosRow = ({
  tipo,
  checked,
  handleSelectArquivo,
  handleDateChange,
  dataNaoSelecionada,
}) => {
  return (
    <>
      <ArquivosRowWrapper checked={checked}>
        <Checkbox
          checked={checked}
          onChange={() => handleSelectArquivo(tipo)}
          // size="small"
        />
        <Title headingLevel="h6" mb={"0"}>
          {titulosArquivo[tipo.toLowerCase()]}
        </Title>
        <ArquivosDates handleDateChange={handleDateChange} tipoArquivo={tipo} />
      </ArquivosRowWrapper>
      {dataNaoSelecionada && (
        <ErrorMsg>Selecione datas de início e fim válidas</ErrorMsg>
      )}
    </>
  );
};
