import React, { useEffect, useState } from "react";
import styled from "styled-components";

import checkIcon from "../icons/check_circle.svg";
import checkIconGray from "../icons/check_circle_gray.svg";

import { verificaSenha } from "../helpers/verificar-senha";

const Requisitos = styled.div`
  ul {
    list-style-type: none;
    margin: 1.5rem 0.5rem;

    li {
      padding-left: 36px;
      padding-bottom: 16px;
      background-image: url(${checkIcon});
      background-position: 0 0;
      background-repeat: no-repeat;
      margin-left: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${(p) => p.theme.text};
      text-align: left;
    }

    li.invalid {
      background-image: url(${checkIconGray});
      color: ${(p) => p.theme.textGray};
      font-weight: 400;
    }
  }
`;

export default function RequisitosSenha(props) {
    
  const [requisitosFaltando, setRequisitosFaltando] = useState([]);
  
  useEffect(() => {
    setRequisitosFaltando(verificaSenha(props.password).falta);
  }, [props.password]);

  return (
    <Requisitos>
      <ul>
        <li
          className={
            requisitosFaltando.filter((k) => k === "letra maiúscula").length
              ? "invalid"
              : ""
          }
        >
          Pelo menos 1 letra maiúscula
        </li>
        <li
          className={
            requisitosFaltando.filter((k) => k === "letra minúscula").length
              ? "invalid"
              : ""
          }
        >
          Pelo menos 1 letra minúscula
        </li>
        <li
          className={
            requisitosFaltando.filter((k) => k === "símbolo").length
              ? "invalid"
              : ""
          }
        >
          Pelo menos 1 símbolo
        </li>
        <li
          className={
            requisitosFaltando.filter((k) => k === "número").length
              ? "invalid"
              : ""
          }
        >
          Pelo menos 1 número
        </li>
        <li
          className={
            requisitosFaltando.filter((k) => k === "tamanho").length
              ? "invalid"
              : ""
          }
        >
          Pelo menos 8 caracteres
        </li>
      </ul>
    </Requisitos>
  );
}
