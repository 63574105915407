import React, { useEffect, useState } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components/macro";

import { GlobalStyle, themeDefault } from "./theme/GlobalStyle";

import { ReactComponent as AcceptIcon } from "./icons/check_circle.svg";
import { ReactComponent as WarningIcon } from "./icons/warningtriangle-icon.svg";
import { ReactComponent as ErrorIcon } from "./icons/cancel-circle.svg";
import LoadingIcon from "./components/@DesignSystem/LoadingIcon";

import logoTaxdashs from "./components/images/Logo TaxDashs.png";
import StatusService from "./services/StatusService";

import "animate.css";

const start = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 680px;
  margin: 3rem auto;
`;

const Logo = styled.a`
  display: block;
  width: 160px;
  margin: 3rem auto;
  text-align: center;

  img {
    width: 100%;
  }
`;

const StatusBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .header {
    padding: 1rem 2rem;
    font-weight: 600;
    background-color: ${(props) =>
      props.status === "loading"
        ? "#efefef"
        : props.status === "online"
        ? "#2ecc71"
        : props.status === "warning"
        ? "#f1c40f"
        : "#e74c3c"};
    color: white;
    font-size: 1.5rem;
  }
`;

const StatusServices = styled.div`
  padding: 1rem 2rem;
  display: flex;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;

  div {
    font-weight: 450;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.status === "online"
        ? "#2ecc71"
        : props.status === "warning"
        ? "#f1c40f"
        : "#e74c3c"};

    p {
      margin-left: 0.5rem;
      font-weight: 600;
    }
  }
`;

export default function Status() {
  const [status, setStatus] = useState("loading");
  const [apiStatus, setApiStatus] = useState("loading");
  const [webStatus, setWebStatus] = useState("loading");
  const [azureStatus, setAzureStatus] = useState("loading");

  // na montagem do componente, faz a chamada para a API

  async function getStatus() {
    const webStatus = await StatusService.getApplicationStatus();
    setWebStatus(webStatus ? "online" : "offline");
    const apiStatus = await StatusService.getApiStatus();
    setApiStatus(apiStatus ? "online" : "offline");
    const azureStatus = await StatusService.getAzureStatus();
    setAzureStatus(azureStatus ? "online" : "offline");

    if (webStatus && apiStatus && azureStatus) {
      setStatus("online");
    } else if (webStatus || apiStatus || azureStatus) {
      setStatus("warning");
    } else {
      setStatus("offline");
    }
  }
  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <ThemeProvider theme={themeDefault}>
        <div>
          <GlobalStyle />
          <Container>
            <Logo href="/">
              <img src={logoTaxdashs} alt="Logo Taxcel"></img>
            </Logo>
            <div className="animate__animated animate__fadeInUp">
              <StatusBlock status={status}>
                <div className="header">
                  <h4>
                    {status === "loading" ? (
                      <LoadingIcon />
                    ) : status === "online" ? (
                      "Todos os sistemas funcionando"
                    ) : status === "warning" ? (
                      "Alguns sistemas estão funcionando com problemas"
                    ) : (
                      "Alguns sistemas estão offline"
                    )}
                  </h4>
                </div>
                <StatusServices status={apiStatus}>
                  <p>API</p>
                  {apiStatus === "loading" ? (
                    <LoadingIcon />
                  ) : apiStatus === "online" ? (
                    <div>
                      <AcceptIcon />
                      <p>Funcionando</p>
                    </div>
                  ) : apiStatus === "warning" ? (
                    <div>
                      <WarningIcon />
                      <p>Funcionando com problemas</p>
                    </div>
                  ) : (
                    <div>
                      <ErrorIcon />
                      <p>Offline</p>
                    </div>
                  )}
                </StatusServices>
                <StatusServices status={webStatus}>
                  <p>WebApp</p>
                  {webStatus === "loading" ? (
                    <LoadingIcon />
                  ) : webStatus === "online" ? (
                    <div>
                      <AcceptIcon />
                      <p>Funcionando</p>
                    </div>
                  ) : webStatus === "warning" ? (
                    <div>
                      <WarningIcon />
                      <p>Funcionando com problemas</p>
                    </div>
                  ) : (
                    <div>
                      <ErrorIcon />
                      <p>Offline</p>
                    </div>
                  )}
                </StatusServices>
                <StatusServices status={azureStatus}>
                  <p>Serviços da Azure</p>
                  {azureStatus === "loading" ? (
                    <LoadingIcon />
                  ) : azureStatus === "online" ? (
                    <div>
                      <AcceptIcon />
                      <p>Funcionando</p>
                    </div>
                  ) : azureStatus === "warning" ? (
                    <div>
                      <WarningIcon />
                      <p>Funcionando com problemas</p>
                    </div>
                  ) : (
                    <div>
                      <ErrorIcon />
                      <p>Offline</p>
                    </div>
                  )}
                </StatusServices>
              </StatusBlock>
            </div>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
}
