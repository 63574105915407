import React, { useEffect, useRef, useState } from "react";

import DSButton from "../../../@DesignSystem/Button";
import DSFormInput from "../../../@DesignSystem/FormInput";
import Dropdown from "../../../@DesignSystem/Dropdown";
import DSInput from "../../../@DesignSystem/Input";
import Checkbox from "../../../@DesignSystem/Checkbox";

import { ReactComponent as ArrowIcon } from "../../../../icons/keyboard_arrow_left.svg";
import { ReactComponent as EditIcon } from "../../../../icons/edit.svg";

import * as S from "./style";
import { useInput } from "../../../../hooks/useInput";
import axios from "axios";
import Radio from "../../../@DesignSystem/Radio";
import QuestionCard from "../../../@DesignSystem/QuestionCard";
import moment from "moment";
import VendaService from "../../../../services/VendaService";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
} from "../../../ToastNotification";
import LoadingIcon from "../../../LoadingIcon";
import InlineMessage from "../../../@DesignSystem/InlineMessage";
import Modal from "../../../Modal";

import { mask } from "../../CriarVenda";
import ToggleButton from "../../../@DesignSystem/ToggleButton";
import SwitchButton from "../../../@DesignSystem/SwitchButton";

const client = axios.create({
  baseURL: process.env.REACT_APP_LICENSE_URL,
  headers: {
    "Content-Type": `application/x-www-form-urlencoded`,
  },
});

const planos = [
  {
    label: "Padrão",
    value: {
      usuarios: 3,
      capacidade: 10,
    },
  },
  {
    label: "Capacidade média",
    value: {
      usuarios: 5,
      capacidade: 30,
    },
  },
  {
    label: "Capacidade alta",
    value: {
      usuarios: 10,
      capacidade: 60,
    },
  },
  {
    label: "Personalizado",
    value: "Personalizado",
  },
];

const ssoOptions = [
  {
    label: "Apenas login com senha TaxDash",
    value: 0,
  },
  {
    label: "Apenas login com conta Microsoft/Google",
    value: 1,
  },
  {
    label: "Login com senha TaxDash e conta Microsoft/Google",
    value: 2,
  },
];

export default function CriarVenda(props) {
  const [criandoVenda, setCriandoVenda] = useState(false);
  const [isUsuarioSSO, setIsUsuarioSSO] = useState(false);
  const [selectedPlano, setSelectedPlano] = useState(planos[0].value);
  const [selectedSsoOption, setSelectedSsoOption] = useState(0);
  const [planoPersonalizado, setPlanoPersonalizado] = useState(false);
  const [usuariosLeitores, setUsuariosLeitores] = useState(false);
  const [editingNomeBanco, setEditingNomeBanco] = useState(false);
  const [editingData, setEditingData] = useState(false);
  const [dataExpiraTeste, setDataExpiraTeste] = useState("");
  const [tempoContrato, setTempoContrato] = useState(0);
  const [dataExpiraVenda, setDataExpiraVenda] = useState("");
  const [openConfig, setOpenConfig] = useState(false);
  const [criarBanco, setCriarBanco] = useState(true);
  const [isSQL, setIsSQL] = useState(true);
  const [apiArquivei, setApiArquivei] = useState(false);

  const [pesquisandoCliente, setPesquisandoCliente] = useState(false);
  const [clienteCadastrado, setClienteCadastrado] = useState(false);
  const [editandoCliente, setEditandoCliente] = useState(false);

  const [customPBI, setCustomPBI] = useState(false);
  const [customBD, setCustomBD] = useState(false);
  const [customStorage, setCustomStorage] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [confirmaNovaVenda, setConfirmaNovaVenda] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const dateRef = useRef(null);

  //plano
  const {
    value: qtdUsuarios,
    bind: bindQtdUsuarios,
    reset: resetqtdUsuarios,
  } = useInput("", "", "vazio");
  const {
    value: qtdUsuariosLeitores,
    bind: bindQtdUsuariosLeitores,
    reset: resetqtdUsuariosLeitores,
  } = useInput("", "", "vazio");
  const {
    value: capacidadeBd,
    bind: bindCapacidadeBd,
    reset: resetcapacidadeBd,
  } = useInput("", "", "vazio");

  //venda
  const {
    value: cnpjValue,
    bind: bindCnpjValue,
    reset: resetcnpjValue,
    check: checkCnpj,
    isInvalid: invalidCnpj,
  } = useInput("", "cnpj", "cnpj");

  const {
    value: nomeValue,
    bind: bindNomeValue,
    reset: resetnomeValue,
    setValue: setnomeValue,
    isInvalid: invalidNome,
  } = useInput("", "", "vazio");

  const {
    value: nomeEmpresaValue,
    bind: bindNomeEmpresaValue,
    reset: resetnomeEmpresaValue,
    setValue: setnomeEmpresaValue,
    isInvalid: invalidNomeEmpresa,
  } = useInput("", "", "vazio");

  const {
    value: emailValue,
    bind: bindEmailValue,
    reset: resetemailValue,
    setValue: setemailValue,
    isInvalid: invalidEmail,
  } = useInput("", "", "email");

  const {
    value: telefoneValue,
    bind: bindTelefoneValue,
    reset: resettelefoneValue,
    setValue: settelefoneValue,
    isInvalid: invalidTelefone,
  } = useInput("", "tel", "tel");

  const {
    value: ContaazulValue,
    bind: bindcontaazulValue,
    reset: resetContaazulValue,
  } = useInput("", "", "vazio");

  const {
    value: qtdDiasTeste,
    bind: bindQtdDiasTeste,
    reset: resetqtdDiasTeste,
  } = useInput("15", "", "vazio");

  const {
    value: nomeBancoValue,
    bind: bindNomeBancoValue,
    setValue: setNomeBanco,
    reset: resetnomeBancoValue,
  } = useInput("", "", "");

  //avancado
  const {
    value: contaPBI,
    bind: bindcontaPBI,
    reset: resetcontaPBI,
  } = useInput("", "", "");
  const {
    value: chavePBI,
    bind: bindchavePBI,
    reset: resetchavePBI,
  } = useInput("", "", "");
  const {
    value: groupID,
    bind: bindgroupID,
    reset: resetgroupID,
  } = useInput("", "", "");
  const {
    value: clientID,
    bind: bindclientID,
    reset: resetclientID,
  } = useInput("", "", "");
  const {
    value: usuarioMestre,
    bind: bindusuarioMestre,
    reset: resetusuarioMestre,
  } = useInput("", "", "");
  const {
    value: senhaMestre,
    bind: bindsenhaMestre,
    reset: resetsenhaMestre,
  } = useInput("", "", "");
  const {
    value: servidorBD,
    bind: bindservidorBD,
    reset: resetservidorBD,
  } = useInput("", "", "");
  const {
    value: portaBD,
    bind: bindportaBD,
    reset: resetportaBD,
  } = useInput("", "", "");
  const {
    value: contaStorage,
    bind: bindcontaStorage,
    reset: resetcontaStorage,
  } = useInput("", "", "");
  const {
    value: chaveStorage,
    bind: bindchaveStorage,
    reset: resetchaveStorage,
  } = useInput("", "", "");

  useEffect(() => {
    let date = moment().add(1, "years").format("DD/MM/YYYY");
    setDataExpiraVenda(date.toString());
  }, []);

  useEffect(() => {
    let date = moment().add(qtdDiasTeste, "days").format("DD/MM/YYYY");
    setDataExpiraTeste(date.toString());
  }, [qtdDiasTeste]);

  useEffect(() => {
    if (tempoContrato == 1) {
      let date = moment().add(1, "years").format("DD/MM/YYYY");
      setDataExpiraVenda(date.toString());
    }
    if (tempoContrato == 2) {
      let date = moment().add(6, "months").format("DD/MM/YYYY");
      setDataExpiraVenda(date.toString());
    }
  }, [tempoContrato]);

  useEffect(() => {
    setNomeBanco(
      `Banco de ${emailValue.substring(0, emailValue.indexOf("@"))}`
    );
  }, [emailValue, setNomeBanco]);

  const handleChangeCheckbox = () => {
    setIsUsuarioSSO(!isUsuarioSSO);
  };

  const handleDateChange = (e) => {
    console.log(dateRef.current.value);
    setDataExpiraVenda(
      moment(dateRef.current.value, "YYYY-MM-DD").format("DD/MM/YYYY")
    );
    // console.log(moment(dateRef.current.value, "YYYY-MM-DD").format("DD/MM/YYYY"));
  };

  const criarTeste = () => {
    setCriandoVenda(true);

    let data_fim = new Date(
      moment(dataExpiraTeste, "DD/MM/YYYY")
    ).toISOString();

    let obj = {
      cliente: {
        cnpj: cnpjValue.replace(/\D/g, ""),
        razao_social: nomeEmpresaValue,
        telefone: telefoneValue.replace(/\D/g, ""),
        nome_contato: nomeValue,
        tipo: "1",
      },
      venda: {
        data_fim: data_fim,
        teste: true,
      },
      usuario: {
        email: emailValue,
        nome_banco: criarBanco ? nomeBancoValue : null,
        novo_banco: criarBanco,
      },
      plano: {
        nome: "Plano de " + nomeEmpresaValue,
        num_bancos:
          selectedPlano === "Personalizado"
            ? qtdUsuarios
            : selectedPlano.usuarios,
        capacidade_bd:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
        capacidade_storage:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
      },
    };

    if (customPBI) {
      obj.conta_pbi = {
        conta_pbi: contaPBI,
        chave_pbi: chavePBI,
        group_id: groupID,
        client_id: clientID,
      };
    }

    if (customBD) {
      obj.conta_bd = {
        usuario: usuarioMestre,
        senha: senhaMestre,
        servidor: servidorBD,
        porta: portaBD,
      };
    }

    if (customStorage) {
      obj.conta_storage = {
        conta_storage: contaStorage,
        chave_storage: chaveStorage,
      };
    }
    console.log(obj);
    console.log(props.tokenLogin);

    if (
      !obj.venda.data_fim ||
      !(obj.plano.capacidade_bd > 0) ||
      !(obj.plano.capacidade_storage > 0)
    ) {
      console.log(
        2,
        obj.venda.data_fim,
        obj.plano.capacidade_bd,
        obj.plano.capacidade_storage
      );
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    if (obj.usuario.novo_banco && !obj.usuario.nome_banco) {
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    if (
      invalidCnpj ||
      invalidEmail ||
      invalidNome ||
      invalidNomeEmpresa ||
      invalidTelefone
    ) {
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    VendaService.criarVenda(obj, props.tokenLogin)
      .then((resp) => {
        adicionaNotificacao("Teste criado com sucesso", false);
        resetCampos();
        console.log(resp);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400)
          adicionaNotificacao(err.response.data.descricao, true);
        setCriandoVenda(false);
        props.refreshListaTestes();
        // console.log(err.response);
      });
  };

  const criarVenda = (apagarDados = false) => {
    console.log("SSO option: " + selectedSsoOption);
    console.log("Usuário SSO option: " + isUsuarioSSO);
    setCriandoVenda(true);

    let data_fim = new Date(
      moment(dataExpiraVenda, "DD/MM/YYYY")
    ).toISOString();

    let obj = {
      cliente: {
        cnpj: cnpjValue.replace(/\D/g, ""),
        razao_social: nomeEmpresaValue,
        telefone: telefoneValue.replace(/\D/g, ""),
        nome_contato: nomeValue,
        tipo: "1",
      },
      venda: {
        data_fim: data_fim,
        teste: false,
        apagar_dados: apagarDados,
      },
      usuario: {
        email: emailValue,
        nome_banco: criarBanco ? nomeBancoValue : null,
        novo_banco: criarBanco,
        sso: isUsuarioSSO,
      },
      plano: {
        nome: "Plano de " + nomeEmpresaValue,
        num_bancos:
          selectedPlano === "Personalizado"
            ? qtdUsuarios
            : selectedPlano.usuarios,
        capacidade_bd:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
        capacidade_storage:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
        sso: selectedSsoOption,
        synapse: isSQL === true ? 0 : 1,
        arquivei: apiArquivei === true ? 1 : 0,
      },
    };

    if (customPBI) {
      obj.conta_pbi = {
        conta_pbi: contaPBI,
        chave_pbi: chavePBI,
        group_id: groupID,
        client_id: clientID,
      };
    }

    if (customBD) {
      obj.conta_bd = {
        usuario: usuarioMestre,
        senha: senhaMestre,
        servidor: servidorBD,
        porta: portaBD,
      };
    }

    if (customStorage) {
      obj.conta_storage = {
        conta_storage: contaStorage,
        chave_storage: chaveStorage,
      };
    }
    // console.log(obj);

    if (
      !obj.venda.data_fim ||
      !(obj.plano.capacidade_bd > 0) ||
      !(obj.plano.capacidade_storage > 0)
    ) {
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    if (obj.usuario.novo_banco && !obj.usuario.nome_banco) {
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    if (
      invalidCnpj ||
      invalidEmail ||
      invalidNome ||
      invalidNomeEmpresa ||
      invalidTelefone
    ) {
      console.log("campos obrigatorios");
      adicionaNotificacao("Preencha todos os campos obrigatórios", true);
      setCriandoVenda(false);
      return;
    }

    console.log(obj);
    console.log(props.tokenLogin);

    VendaService.criarVenda(obj, props.tokenLogin)
      .then((resp) => {
        adicionaNotificacao("Venda criada com sucesso", false);
        resetCampos();
        props.refreshListaVendas();
        console.log(resp);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400)
          adicionaNotificacao(err.response.data.descricao, true);
        setCriandoVenda(false);
        // console.log(err.response);
      });
  };

  const pesquisaCliente = () => {
    if (checkCnpj()) return;
    setPesquisandoCliente(true);
    VendaService.getClienteCadastrado(cnpjValue, props.tokenLogin)
      .then((resp) => {
        console.log(resp.data);

        setPesquisandoCliente(false);

        if (!resp.data.venda) {
          setClienteCadastrado(false);
          return;
        }

        setClienteCadastrado(true);

        setnomeValue(resp.data.venda.nome_contato || "");
        setnomeEmpresaValue(resp.data.venda.razao_social || "");
        setemailValue(resp.data.venda.contatos[0] || "");
        settelefoneValue(mask.telEmpresa(resp.data.venda.telefone || ""));
      })
      .catch((err) => {
        setPesquisandoCliente(false);
        setClienteCadastrado(false);
      });
  };

  const resetCampos = () => {
    resetContaazulValue();
    resetcapacidadeBd();
    resetcapacidadeBd();
    resetchavePBI();
    resetchaveStorage();
    resetclientID();
    resetcnpjValue();
    resetcontaPBI();
    resetcontaStorage();
    resetemailValue();
    resetgroupID();
    resetnomeBancoValue();
    resetnomeEmpresaValue();
    resetnomeValue();
    resetportaBD();
    resetqtdDiasTeste();
    resetqtdUsuarios();
    resetqtdUsuariosLeitores();
    resetsenhaMestre();
    resetservidorBD();
    resettelefoneValue();
    resetusuarioMestre();
  };

  const adicionaNotificacao = (texto, erro) => {
    store.addNotification({
      content: (
        <ToastNotification bg={erro ? "danger" : "primary"} textoAuxiliar="">
          {texto}{" "}
        </ToastNotification>
      ),
      // onRemoval: (id, removedBy) => //console.log(removedBy),
      ...notificationTopRight,
    });
  };

  const renderCamposCliente = (
    pesquisandoCliente,
    clienteCadastrado,
    editandoCliente
  ) => {
    if (pesquisandoCliente)
      return (
        <S.Pesquisando>
          <div>
            <p>Pesquisando cliente</p>
            <LoadingIcon big />
          </div>
        </S.Pesquisando>
      );
    if (clienteCadastrado)
      if (editandoCliente)
        return (
          <>
            <InlineMessage mt={32}>Editando cliente cadastrado</InlineMessage>
            <DSFormInput
              required
              mt={24}
              label="Nome da empresa"
              error={invalidNomeEmpresa}
              errorMsg="Campo obrigatório"
              {...bindNomeEmpresaValue}
            />
            <DSFormInput
              required
              mt={24}
              label="Nome do contato"
              error={invalidNome}
              errorMsg="Campo obrigatório"
              {...bindNomeValue}
            />
            <DSFormInput
              required
              mt={24}
              label="Email"
              error={invalidEmail}
              errorMsg="Email inválido"
              {...bindEmailValue}
            />
            <DSFormInput
              required
              mt={24}
              label="Telefone"
              error={invalidTelefone}
              errorMsg="Telefone inválido"
              {...bindTelefoneValue}
            />
          </>
        );
      else
        return (
          <>
            <InlineMessage mt={32} type="success">Cliente já cadastrado!</InlineMessage>
            <S.ClienteCadastrado>
              <p>
                Empresa: <span>{nomeEmpresaValue}</span>
              </p>
              <p>
                Contato: <span>{nomeValue}</span>
              </p>
              <p>
                Email: <span>{emailValue}</span>
              </p>
              <p>
                Telefone: <span>{telefoneValue}</span>
              </p>
              <p className="color">Algum dado do cliente mudou?</p>
              <DSButton mt={16} onClick={() => setEditandoCliente(true)}>
                Editar dados
              </DSButton>
            </S.ClienteCadastrado>
          </>
        );
    return (
      <>
        <InlineMessage mt={32} type="default">Cadastrando novo cliente</InlineMessage>
        <DSFormInput
          required
          mt={24}
          label="Nome da empresa"
          error={invalidNomeEmpresa}
          errorMsg="Campo obrigatório"
          {...bindNomeEmpresaValue}
        />
        <DSFormInput
          required
          mt={24}
          label="Nome do contato"
          error={invalidNome}
          errorMsg="Campo obrigatório"
          {...bindNomeValue}
        />
        <DSFormInput
          required
          mt={24}
          label="Email"
          error={invalidEmail}
          errorMsg="Email inválido"
          {...bindEmailValue}
        />
        <DSFormInput
          required
          mt={24}
          label="Telefone"
          error={invalidTelefone}
          errorMsg="Telefone inválido"
          {...bindTelefoneValue}
        />
      </>
    );
  };

  return props.teste ? (
    <S.CriaVenda
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="header">
        <h4>Criar novo teste</h4>
        <DSButton
          onClick={() => props.closeCriar()}
          color="textDarkerGray"
          bg="grayLight"
          size="small"
          secondary
        >
          Cancelar
        </DSButton>
      </div>
      <div className="dados">
        <DSFormInput
          required
          label="CNPJ ou CPF"
          error={invalidCnpj}
          errorMsg="Digite um CNPJ ou CPF válido"
          {...bindCnpjValue}
          onBlur={pesquisaCliente}
        />
        {renderCamposCliente(
          pesquisandoCliente,
          clienteCadastrado,
          editandoCliente
        )}
      </div>
      <div className="plano">
        <Dropdown
          label="Plano"
          defaultValue={planos[0]}
          options={planos}
          onChange={(selectedOption) => {
            if (selectedOption.value === "Personalizado")
              setPlanoPersonalizado(true);
            else setPlanoPersonalizado(false);
            setSelectedPlano(selectedOption.value);
          }}
        />
        {planoPersonalizado ? (
          <>
            <S.AddUsuarios>
              <DSFormInput
                required
                mt={24}
                short
                width={160}
                label="Qtd. usuários"
                {...bindQtdUsuarios}
              />
              {!usuariosLeitores && (
                <DSButton
                  mt={24}
                  secondary
                  color="primary"
                  bg="primaryLight"
                  small
                  onClick={() => setUsuariosLeitores(true)}
                >
                  Adicionar usuários leitores
                </DSButton>
              )}
            </S.AddUsuarios>
            {usuariosLeitores && (
              <S.AddUsuarios>
                <DSFormInput
                  required
                  mt={24}
                  short
                  width={160}
                  label="Qtd. usuários leitores"
                  {...bindQtdUsuariosLeitores}
                />
                <DSButton
                  mt={24}
                  secondary
                  color="danger"
                  bg="grayLight"
                  small
                  onClick={() => setUsuariosLeitores(false)}
                >
                  Cancelar
                </DSButton>
              </S.AddUsuarios>
            )}
            <DSFormInput
              required
              mt={24}
              short
              width={160}
              label="Capacidade BD em GB"
              {...bindCapacidadeBd}
            />
          </>
        ) : (
          <>
            <div className="info">
              <p>Quantidade de usuários:</p>
              <p>{selectedPlano.usuarios} usuários</p>
            </div>
            <div className="info">
              <p>Capacidade máxima:</p>
              <p className="extra-margin">{selectedPlano.capacidade}GB</p>
            </div>
          </>
        )}
        <div className="duracao">
          {editingData ? (
            <DSInput
              short
              label="Duração do teste em dias"
              placeholder=""
              color="primary"
              className
              {...bindQtdDiasTeste}
            >
              <DSButton
                size="small"
                onClick={() => {
                  setEditingData(false);
                }}
              >
                Confirmar
              </DSButton>
            </DSInput>
          ) : (
            <div className="info-edit">
              <p>Duração:</p>
              <p>{qtdDiasTeste} dias</p>
              <S.EditButton
                onClick={() => setEditingData(true)}
                title="Editar duração do teste"
              >
                <EditIcon />
              </S.EditButton>
            </div>
          )}
          <div className="info">
            <p>Venda válida até:</p>
            <p>{dataExpiraTeste}</p>
          </div>
        </div>
        <div className="flex-nao-criar">
          {editingNomeBanco ? (
            <div style={{ marginTop: 16 }}>
              <DSInput
                short
                label="Nome do banco"
                placeholder="Banco de ..."
                color="primary"
                className
                {...bindNomeBancoValue}
              >
                <DSButton
                  size="small"
                  onClick={() => {
                    setEditingNomeBanco(false);
                  }}
                >
                  Confirmar
                </DSButton>
              </DSInput>
            </div>
          ) : (
            <>
              <div className="info-edit">
                <p>Nome do banco:</p>
                {criarBanco ? (
                  <>
                    <p title={nomeBancoValue}>{nomeBancoValue}</p>
                    <S.EditButton
                      onClick={() => {
                        setEditingNomeBanco(true);
                      }}
                      title="Editar nome do banco"
                    >
                      <EditIcon />
                    </S.EditButton>
                  </>
                ) : (
                  <p title="Sem banco">-</p>
                )}
              </div>
              <DSButton
                onClick={() => setCriarBanco(!criarBanco)}
                secondary
                color="primary"
                bg="primaryLight"
                mt={16}
                center
              >
                {criarBanco ? "Criar sem banco" : "Adicionar banco"}
              </DSButton>
            </>
          )}
        </div>
      </div>
      <div className="config">
        <S.Avancado
          $isOpened={openConfig}
          onClick={() => setOpenConfig(!openConfig)}
        >
          <ArrowIcon />
          Configurações avançadas
        </S.Avancado>
        {openConfig && (
          <>
            <S.ContasPersonalizadas>
              <h5>Conta PBI</h5>
              {customPBI ? (
                <>
                  <DSFormInput
                    label="Conta"
                    mt={0}
                    required
                    small
                    {...bindcontaPBI}
                  />
                  <DSFormInput
                    label="Chave"
                    mt={8}
                    required
                    small
                    {...bindchavePBI}
                  />
                  <DSFormInput
                    label="Group ID"
                    mt={8}
                    required
                    small
                    {...bindgroupID}
                  />
                  <DSFormInput
                    label="Client ID"
                    mt={8}
                    required
                    small
                    {...bindclientID}
                  />
                  <DSButton
                    onClick={() => setCustomPBI(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomPBI(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
            <S.ContasPersonalizadas>
              <h5>Conta banco de dados</h5>
              {customBD ? (
                <>
                  <DSFormInput
                    label="Usuário mestre"
                    mt={0}
                    required
                    small
                    {...bindusuarioMestre}
                  />
                  <DSFormInput
                    label="Senha mestre"
                    mt={8}
                    required
                    small
                    {...bindsenhaMestre}
                  />
                  <DSFormInput
                    label="Servirdor BD"
                    mt={8}
                    required
                    small
                    {...bindservidorBD}
                  />
                  <DSFormInput
                    label="Porta BD"
                    mt={8}
                    required
                    small
                    {...bindportaBD}
                  />
                  <DSButton
                    onClick={() => setCustomBD(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomBD(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
            <S.ContasPersonalizadas>
              <h5>Conta storage</h5>
              {customStorage ? (
                <>
                  <DSFormInput
                    label="Conta storage"
                    mt={0}
                    required
                    small
                    {...bindcontaStorage}
                  />
                  <DSFormInput
                    label="Chave storage"
                    mt={8}
                    required
                    small
                    {...bindchaveStorage}
                  />
                  <DSButton
                    onClick={() => setCustomStorage(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomStorage(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
          </>
        )}
      </div>
      <div className="footer">
        <DSButton
          onClick={() => props.closeCriar()}
          color="textDarkerGray"
          bg="grayLight"
          secondary
        >
          Cancelar
        </DSButton>
        <DSButton bg="secondary" onClick={criarTeste}>
          Criar novo teste
        </DSButton>
      </div>
    </S.CriaVenda>
  ) : (
    <S.CriaVenda
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {isModalOpen && (
        <Modal modalSize="lg" isOpen={isModalOpen} onClose={toggleModal}>
          <S.ModalRenova>
            {confirmaNovaVenda ? (
              <>
                <h5>Confirmar nova venda</h5>
                <p>
                  Tem certeza de que deseja criar uma nova venda para esse
                  cliente apagando todos os usuários e bancos da venda antiga?
                </p>
                <div className="buttons">
                  <DSButton
                    onClick={() => setConfirmaNovaVenda(false)}
                    secondary
                    color="text"
                    bg="grayLight"
                  >
                    Voltar
                  </DSButton>
                  <DSButton onClick={() => criarVenda(true)}>
                    Criar nova venda
                  </DSButton>
                </div>
              </>
            ) : (
              <>
                <h5>Esse cliente já possui uma venda cadastrada</h5>
                <p>
                  Deseja <b>renovar a venda</b>, mantendo os usuários e os
                  bancos dessa venda ou <b>criar uma nova venda</b>, apagando
                  todos os usuários e bancos da venda e criando uma nova?
                </p>
                <div className="buttons">
                  <div>
                    <DSButton
                      ghost
                      color="primary"
                      onClick={() => setConfirmaNovaVenda(true)}
                    >
                      Criar venda nova
                    </DSButton>
                    <InlineMessage mt={4} ml={-8} size="small" type="system">
                      Essa opção apagará os dados dessa venda
                    </InlineMessage>
                  </div>
                  <DSButton onClick={() => criarVenda(false)}>
                    Renovar venda
                  </DSButton>
                </div>
              </>
            )}
          </S.ModalRenova>
        </Modal>
      )}
      <div className="header">
        <h4>Criar nova venda</h4>
        <DSButton
          onClick={props.closeCriar}
          color="textDarkerGray"
          bg="grayLight"
          size="small"
          secondary
        >
          Cancelar
        </DSButton>
      </div>
      <div className="dados">
        <DSFormInput
          required
          label="CNPJ ou CPF"
          error={invalidCnpj}
          errorMsg="Digite um CNPJ ou CPF válido"
          {...bindCnpjValue}
          onBlur={pesquisaCliente}
        />
        {renderCamposCliente(
          pesquisandoCliente,
          clienteCadastrado,
          editandoCliente
        )}
      </div>
      <div className="plano">
        <Dropdown
          label="Plano"
          defaultValue={planos[0]}
          options={planos}
          onChange={(selectedOption) => {
            if (selectedOption.value === "Personalizado")
              setPlanoPersonalizado(true);
            else setPlanoPersonalizado(false);
            setSelectedPlano(selectedOption.value);
          }}
        />
        {planoPersonalizado ? (
          <>
            <S.AddUsuarios>
              <DSFormInput
                required
                mt={24}
                short
                width={160}
                label="Qtd. usuários"
                {...bindQtdUsuarios}
              />
              {!usuariosLeitores && (
                <DSButton
                  mt={24}
                  secondary
                  color="primary"
                  bg="primaryLight"
                  small
                  onClick={() => setUsuariosLeitores(true)}
                >
                  Adicionar usuários leitores
                </DSButton>
              )}
            </S.AddUsuarios>
            {usuariosLeitores && (
              <S.AddUsuarios>
                <DSFormInput
                  required
                  mt={24}
                  short
                  width={160}
                  label="Qtd. usuários leitores"
                  {...bindQtdUsuariosLeitores}
                />
                <DSButton
                  mt={24}
                  secondary
                  color="danger"
                  bg="grayLight"
                  small
                  onClick={() => setUsuariosLeitores(false)}
                >
                  Cancelar
                </DSButton>
              </S.AddUsuarios>
            )}
            <DSFormInput
              required
              mt={24}
              short
              width={160}
              label="Capacidade BD em GB"
              {...bindCapacidadeBd}
            />
          </>
        ) : (
          <>
            <div className="info">
              <p>Quantidade de usuários:</p>
              <p>{selectedPlano.usuarios} usuários</p>
            </div>
            <div className="info">
              <p>Capacidade máxima:</p>
              <p className="extra-margin">{selectedPlano.capacidade}GB</p>
            </div>
          </>
        )}
        {
          <>
            {/* <SwitchButton
              variant={1}
              size="small"
              selection={1}
              handleChange={() => console.log()}
              options={["Login TaxDashs", "Conta Microsoft", "Ambos"]}
            /> */}

            <Radio
              mt={24}
              selected={0}
              name="opcoesLogin"
              handleChangeParent={(value) => setSelectedSsoOption(value)}
              options={[
                { label: "Login TaxDashs", value: 0 },
                { label: "Conta Microsoft/Google", value: 1 },
                { label: "Ambos", value: 2 },
              ]}
            >
              Tipo de login:
            </Radio>
            {selectedSsoOption != 0 ? (
              <Checkbox
                label="Primeiro usuário com login Microsoft/Google"
                mt={24}
                size="small"
                mb={16}
                checked={isUsuarioSSO}
                onChange={handleChangeCheckbox}
              />
            ) : (
              <></>
            )}
          </>
        }

        <div className="data-contrato">
          <Radio
            selected={1}
            name="duracaoContrato"
            handleChangeParent={(value) => setTempoContrato(value)}
            options={[
              { label: "1 ano", value: 1 },
              { label: "6 meses", value: 2 },
              { label: "Personalizado", value: 3 },
            ]}
          >
            Contrato de:
          </Radio>
          <div className="info">
            <p>Venda válida até:</p>
            {tempoContrato == 3 ? (
              <input type="date" ref={dateRef} onChange={handleDateChange} />
            ) : (
              <p>{dataExpiraVenda}</p>
            )}
          </div>
        </div>
        <div className="tipoBanco">
          <div className="flexBanco">
            <p className="titleBanco">Tipo do Banco:</p>
            <SwitchButton
              variant={1}
              selection={isSQL ? 0 : 1}
              handleChange={(index) => setIsSQL(index === 0)}
              options={["SQL", "Synapse"]}
              size="small"
            />
          </div>
          {!isSQL && (
            <InlineMessage type="system" size="small" mt={16}>
              O banco tipo Synapse é indicado somente para vendas grandes
            </InlineMessage>
          )}
        </div>
        <div className="tipoBanco">
          <div className="flexBanco">
            <p className="titleBanco">Integração com Arquivei:</p>
            <SwitchButton
              variant={1}
              selection={apiArquivei ? 0 : 1}
              handleChange={(index) => setApiArquivei(index === 0)}
              options={["Sim", "Não"]}
              size="small"
            />
          </div>
        </div>
        <div className="flex-nao-criar">
          {editingNomeBanco ? (
            <div style={{ marginTop: 16 }}>
              <DSInput
                short
                label="Nome do banco"
                placeholder="Banco de ..."
                color="primary"
                className
                {...bindNomeBancoValue}
              >
                <DSButton
                  size="small"
                  onClick={() => {
                    setEditingNomeBanco(false);
                  }}
                >
                  Confirmar
                </DSButton>
              </DSInput>
            </div>
          ) : (
            <>
              <div className="info-edit">
                <p>Nome do banco:</p>
                {criarBanco ? (
                  <>
                    <p title={nomeBancoValue}>{nomeBancoValue}</p>
                    <S.EditButton
                      onClick={() => {
                        setEditingNomeBanco(true);
                      }}
                      title="Editar nome do banco"
                    >
                      <EditIcon />
                    </S.EditButton>
                  </>
                ) : (
                  <p title="Sem banco">-</p>
                )}
              </div>
              <DSButton
                onClick={() => setCriarBanco(!criarBanco)}
                secondary
                color="primary"
                bg="primaryLight"
                mt={16}
                center
              >
                {criarBanco ? "Criar sem banco" : "Adicionar banco"}
              </DSButton>
            </>
          )}
        </div>
      </div>
      <div className="config">
        <S.Avancado
          $isOpened={openConfig}
          onClick={() => setOpenConfig(!openConfig)}
        >
          <ArrowIcon />
          Configurações avançadas
        </S.Avancado>
        {openConfig && (
          <>
            <S.ContasPersonalizadas>
              <h5>Conta PBI</h5>
              {customPBI ? (
                <>
                  <DSFormInput
                    label="Conta"
                    mt={0}
                    required
                    small
                    {...bindcontaPBI}
                  />
                  <DSFormInput
                    label="Chave"
                    mt={8}
                    required
                    small
                    {...bindchavePBI}
                  />
                  <DSFormInput
                    label="Group ID"
                    mt={8}
                    required
                    small
                    {...bindgroupID}
                  />
                  <DSFormInput
                    label="Client ID"
                    mt={8}
                    required
                    small
                    {...bindclientID}
                  />
                  <DSButton
                    onClick={() => setCustomPBI(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomPBI(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
            <S.ContasPersonalizadas>
              <h5>Conta banco de dados</h5>
              {customBD ? (
                <>
                  <DSFormInput
                    label="Usuário mestre"
                    mt={0}
                    required
                    small
                    {...bindusuarioMestre}
                  />
                  <DSFormInput
                    label="Senha mestre"
                    mt={8}
                    required
                    small
                    {...bindsenhaMestre}
                  />
                  <DSFormInput
                    label="Servirdor BD"
                    mt={8}
                    required
                    small
                    {...bindservidorBD}
                  />
                  <DSFormInput
                    label="Porta BD"
                    mt={8}
                    required
                    small
                    {...bindportaBD}
                  />
                  <DSButton
                    onClick={() => setCustomBD(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomBD(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
            <S.ContasPersonalizadas>
              <h5>Conta storage</h5>
              {customStorage ? (
                <>
                  <DSFormInput
                    label="Conta storage"
                    mt={0}
                    required
                    small
                    {...bindcontaStorage}
                  />
                  <DSFormInput
                    label="Chave storage"
                    mt={8}
                    required
                    small
                    {...bindchaveStorage}
                  />
                  <DSButton
                    onClick={() => setCustomStorage(false)}
                    secondary
                    color="text"
                    bg="primaryLight"
                    size="small"
                    center
                    mt={16}
                  >
                    Usar conta padrão
                  </DSButton>
                </>
              ) : (
                <div className="flex">
                  <p>Conta padrão</p>
                  <DSButton
                    onClick={() => setCustomStorage(true)}
                    secondary
                    color="primary"
                    bg="grayLight"
                    size="small"
                  >
                    Editar
                  </DSButton>
                </div>
              )}
            </S.ContasPersonalizadas>
          </>
        )}
      </div>
      <div className="footer">
        <DSButton
          onClick={() => props.closeCriar()}
          color="textDarkerGray"
          bg="grayLight"
          secondary
        >
          Cancelar
        </DSButton>
        <DSButton
          onClick={() => {
            if (clienteCadastrado) toggleModal();
            else criarVenda();
          }}
        >
          Criar nova venda
        </DSButton>
      </div>
    </S.CriaVenda>
  );
}
