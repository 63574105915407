import React from "react";

import Select from "react-select";

import * as S from "./style";

export default function Dropdown(props) {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: props.width ? `${props.width}px` : "320px",
    }),
    indicatorSeparator: () => ({ background: "none" }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0 12px 0 12px",
      border: "2px solid #dcdee0",
      height: "100%",
      background: "rgba(220, 222, 224, 0)",
      fontSize: "18px",
      fontWeight: "600",
      paddingLeft: "8px",
      lineHeight: "24px",
    }),
    menu: (provided, state) => ({ ...provided, borderRadius: "0 12px 0 12px" }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "0 12px 0 12px",
    }),
  };

  return (
    <>
      {props.label && <S.Label>{props.label}</S.Label>}
      <Select className={props.className} styles={customStyles} {...props} />
    </>
  );
}
