import React from "react";
import styled from "styled-components/macro";

import UserContext from "../../context/user-context";

import { ReactComponent as GlobalNavigationIcon } from "../../icons/globalnavigation-icon.svg";
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import { ReactComponent as ReportIcon } from "../../icons/report-icon.svg";
import { ReactComponent as UserSettingIcon } from "../../icons/usersetting-icon.svg";
import { ReactComponent as ToolsIcon } from "../../icons/tools-icon.svg";
import { ReactComponent as PowerIcon } from "../../icons/power-icon.svg";
import { ReactComponent as AddIcon } from "../../icons/add-icon.svg";
import { ReactComponent as PieChartIcon } from "../../icons/piechart-icon.svg";
import { ReactComponent as TestIcon } from "../../icons/quiz_white_24dp.svg";

import { NavLink, withRouter } from "react-router-dom";

import UploadDropdown from "./UploadDropdown";

import PowerBIPartner from "../images/PowerBIPartner.svg";

import { ReactComponent as SettingIcon } from "../../icons/setting-icon.svg";

const Sidebar = styled.nav`
  width: 216px;
  position: fixed;
  top: 56px;
  left: 0;
  height: calc(100vh - 56px);
  background: ${(props) => props.theme.sidenav};
  color: #ffffff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: all 0.4s ease;
  overflow-x: hidden;

  &.collapsed {
    width: 50px;
    overflow-x: hidden;

    span {
      opacity: 0;
    }

    button.hamburguer-menu {
      text-align: center;
      transition: all 0.2s ease-in-out;
      box-sizing: border-box;
    }
  }

  button {
    background: inherit;
    border: none;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    text-align: left;
    white-space: nowrap;

    span {
      margin-left: 1rem;
      word-break: normal;
    }
  }

  button.active {
    background-color: ${(props) => props.theme.primaryDark};
  }

  button:hover {
    background-color: ${(props) => props.theme.primaryDark};
    color: #ffffff;
  }

  button.hamburguer-menu {
    text-align: right;
    /* box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25); */
    font-size: 1.25rem;

    &:hover {
      color: white;
    }
  }

  a {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
    padding: 0.75em 1em;
    font-size: 1em;
    white-space: nowrap;
    background: inherit;

    span {
      word-break: normal;
      margin-left: 1em;
    }
  }

  a:hover {
    background-color: ${(props) => props.theme.primaryDark};
    color: #ffffff;
  }

  a.active {
    background-color: ${(props) => props.theme.primaryDark};
    border-left: 5px solid ${(props) => props.theme.secondary};
  }

  svg.icon {
    transform: translateY(1px);
  }
`;

const PbiPartner = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;

  a {
    width: 100%;
    padding: 1rem 0;
    display: block;

    &:hover {
      background-color: ${(props) => props.theme.primary};
    }

    img {
      height: 3rem;
      filter: invert(100%);
    }
  }
`;

class Sidenav extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleLoginClick() {
    this.props.doLogin();
  }

  render() {
    if (this.props.isAdm) {
      return (
        <UserContext.Consumer>
          {(permissao) => (
            <Sidebar className={this.props.isCollapsed && "collapsed"}>
              <button
                className="hamburguer-menu"
                id="sidebarCollapse"
                onClick={this.props.handleCollapseSidenav}
              >
                {this.props.isCollapsed ? (
                  <GlobalNavigationIcon className="icon" />
                ) : (
                  <BackIcon className="icon" />
                )}
              </button>

              {permissao === "vendedor" && (
                <>
                  <NavLink activeClassName="active" to="/adm/vendas">
                    <ReportIcon className="icon" />
                    <span>Vendas</span>
                  </NavLink>
                  <NavLink activeClassName="active" to="/adm/testes">
                    <TestIcon className="icon" />
                    <span>Testes</span>
                  </NavLink>

                  <NavLink activeClassName="active" to="/adm/usuarios">
                    <UserSettingIcon className="icon" />
                    <span>Usuários</span>
                  </NavLink>
                </>
              )}

              {/* {permissao === "sup" && ( */}
              {this.props.ehSuporte && (
                <NavLink activeClassName="active" to="/adm/suporte">
                  <ToolsIcon className="icon" />
                  <span>Suporte</span>
                </NavLink>
              )}
              {/* )} */}

              {permissao === "dev" && (
                <NavLink activeClassName="active" to="/adm/app">
                  <ToolsIcon className="icon" />
                  <span>Aplicação</span>
                </NavLink>
              )}

              <button
                id="arquivos"
                className={
                  (this.props.location.pathname.startsWith("/storage/") ||
                    this.props.location.pathname.startsWith("/database/")) &&
                  "active"
                }
                onClick={this.props.handleShowDropdown}
              >
                <AddIcon className="icon" />
                <span>Arquivos</span>
              </button>
              {this.props.showDropdown && (
                <UploadDropdown isLeitor={this.props.isLeitor} />
              )}
              <NavLink
                activeClassName="active"
                to="/dashboard/nav"
                isActive={(match, location) => {
                  if (location.pathname.toLowerCase().startsWith("/dashboard"))
                    return true;
                }}
              >
                <PieChartIcon className="icon" />
                <span>Dashboards</span>
              </NavLink>
              <NavLink activeClassName="active" to="/configuracoes">
                <SettingIcon className="icon" />
                <span>Configurações</span>
              </NavLink>

              {/* <button onClick={this.handleLoginClick}>
                <PowerIcon className="icon" />
                <span>Logout</span>
              </button> */}
            </Sidebar>
          )}
        </UserContext.Consumer>
      );
    }
    return (
      <Sidebar className={this.props.isCollapsed && "collapsed"}>
        <button
          className="hamburguer-menu"
          id="sidebarCollapse"
          onClick={this.props.handleCollapseSidenav}
        >
          {this.props.isCollapsed ? (
            <GlobalNavigationIcon className="icon" />
          ) : (
            <BackIcon className="icon" />
          )}
        </button>
        <button
          id="arquivos"
          className={
            (this.props.location.pathname.startsWith("/storage/") ||
              this.props.location.pathname.startsWith("/database/")) &&
            "active"
          }
          onClick={this.props.handleShowDropdown}
        >
          <AddIcon className="icon" />
          <span>Arquivos</span>
        </button>
        {this.props.showDropdown && (
          <UploadDropdown isLeitor={this.props.isLeitor} />
        )}
        <NavLink
          activeClassName="active"
          to="/dashboard/nav"
          isActive={(match, location) => {
            if (location.pathname.toLowerCase().startsWith("/dashboard"))
              return true;
          }}
        >
          <PieChartIcon className="icon" />
          <span>Dashboards</span>
        </NavLink>
        <NavLink activeClassName="active" to="/configuracoes">
          <SettingIcon className="icon" />
          <span>Configurações</span>
        </NavLink>
        {this.props.ehSuporte && (
          <NavLink activeClassName="active" to="/suporte">
            <ToolsIcon className="icon" />
            <span>Suporte</span>
          </NavLink>
        )}
        {/* <button onClick={this.handleLoginClick}>
          <PowerIcon className="icon" />
          <span>Logout</span>
        </button> */}
        {!this.props.isCollapsed && (
          <PbiPartner>
            {/* <a
              href="https://powerbi.microsoft.com/en-us/partners/taxcel-solues-fiscais-s-a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={PowerBIPartner} alt="PowerBI PArtner" />
            </a> */}
          </PbiPartner>
        )}
      </Sidebar>
    );
  }
}

export const SidenavWithRouter = withRouter(Sidenav);
