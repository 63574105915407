import api from './config/Axios';
export default class CadastroService {
    static async criarCadastroTeste(obj) {
        return api({
            method: 'post',
            url: `/cadastro`,
            data: obj,
        });
    }

    static async getCadastrosTeste(token) {
        return api({
            method: 'get',
            url: `/cadastro`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async atualizaStatusCadastroTeste(token, id, status) {
        return api({
            method: 'put',
            url: `/cadastro`,
            data: {
                id,
                status
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}