import styled from "styled-components";

export const StyledTitle = styled.p`
  font-size: ${(p) =>
    p.variant === "h1"
      ? "44.79px"
      : p.variant === "h2"
      ? "37.32px"
      : p.variant === "h3"
      ? "31.10px"
      : p.variant === "h4"
      ? "25.92px"
      : p.variant === "h5"
      ? "21.60px"
      : "18px"};
  margin-bottom: ${(p) => (p.mb ? p.mb : "0.5em")};
  margin-top: ${(p) => p.mt}px;
  color: ${(p) => (p.color ? p.theme[p.color] : "inherit")};
  font-weight: 600;
  line-height: 1.2;
  /* font-family: 'Segoe UI','Verdana',sans-serif; */
`;
