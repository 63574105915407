import {
    passwordStrength
} from "check-password-strength";

const requerido = {
    lowercase: "letra minúscula", 
    uppercase: "letra maiúscula", 
    symbol: "símbolo",
    number: "número"
}

const forca = {
    "Too weak": "Muito fraca",
    "Weak": "Fraca",
    "Medium": "Media",
    "Strong": "Forte",
}

export function verificaSenha(senha){
    let res = passwordStrength(senha);
    let falta = Object.keys(requerido).filter(k=>!res.contains.includes(k)).map(k => requerido[k]);
    if(senha.length < 8) falta.push('tamanho');

    return {
        valor: forca[res.value],
        ehForte: (res.value === 'Strong' || res.value === 'Medium') ? true : false,
        falta,
    };
}