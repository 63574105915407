import React from "react";
import styled from "styled-components/macro";

import Button from "../Button";
import FileUpload from "./FileUpload";


const AplicacaoContainer = styled.div`
  h2 {
    font-size: 3.125rem;
  }
`;

const AplicacaoHeader = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 4rem;
  }
`;

const LimparTestes = styled.div`
  margin: 3rem 0;

  input[type="number"] {
    width: 48px;
    font-size: 1rem;
    text-align: center;
  }

  button {
    margin-left: 1rem
  }
`;

const AtualizarContainer = styled.div`
  display: flex;
  align-items: center;

  div.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    margin-right: 8rem;

    h3 {
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    button {
      margin-top: 1rem;
    }
  }
`;

const ConciliarContainer = styled.div`
  margin-top: 4rem;
`;

const ConsumoContainer = styled.div`
  margin-top: 4rem;
`

export class Aplicacao extends React.Component {
  render() {
    return (
      <AplicacaoContainer>
        <AplicacaoHeader>
          <h2>Aplicação</h2>
          <Button background="primary">Travar aplicação</Button>
        </AplicacaoHeader>
        <LimparTestes>
          <label>
            Limpar todas as contas de teste com mais de{" "}
            <input
              type="number"
              id="qtdDias"
              name="qtdDias"
              min="0"
              step="5"
            ></input>{" "}
            dias
          </label>
          <Button background="danger"
          >Limpar</Button>
        </LimparTestes>
        <AtualizarContainer>
          <div className="container">
            <h3>Atualizar Templates</h3>
            <FileUpload />
            <Button background="primary">Atualizar</Button>
          </div>
          <div className="container">
            <h3>Atualizar Banco de Dados</h3>
            <FileUpload />
            <Button background="primary">Atualizar</Button>
          </div>
        </AtualizarContainer>
        <ConciliarContainer>
          <Button background="primary">Conciliar BDs e Datasets</Button>
        </ConciliarContainer>
        <ConsumoContainer>
          <h3>Consumo da nuvem</h3>
        </ConsumoContainer>
      </AplicacaoContainer>
    );
  }
}
