import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
    width: 100%;
    height: 1.5em;
    border-radius: 3px;
    background: #cfd8dc;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 1.5em;
    display: block;
`

const Progress = styled.div`
    width: ${props => props.progress}%;
    background: ${props => props.theme.secondary};
    height: 100%;
    text-align: center;
`

class ProgressBar extends React.Component {
    render() {
        return (
            <Container>
                <Progress progress={this.props.progress}>{this.props.progress}%</Progress>
            </Container>
        )
    }
}

export default ProgressBar;