import React from "react";

import { AreaVendedores } from "./AreaVendedores";

// import { FormInput } from "../FormInput";

// import CriarPlano from "./CriarPlano";
// import CriarVenda from "./CriarVenda";
// import ConsultarVenda from "./ConsultarVenda";
// import TableVendas from "./TableVendas";
// import TableVendasTeste from "./TableVendasTeste";
// import AprovaTeste from "./AprovaTeste";
// import Modal from "../Modal";
// import Button from "../Button";

// import { ReactComponent as MinusIcon } from "../../icons/minus-icon.svg";
// import { ReactComponent as PlusIcon } from "../../icons/plus-icon.svg";
// import LoadingIcon from "../LoadingIcon";

// import { store } from "react-notifications-component";
// import "animate.css";
// import { ToastNotification, notificationTopRight } from "../ToastNotification";
// import TableAprovaTeste from "./TableAprovaTeste";
// import SwitchButton from "../SwitchButton";
// import moment from "moment";

// import axios from "axios";

// import { mask } from "./CriarVenda";
// import CadastroService from "../../services/CadastroService";
// import VendaService from "../../services/VendaService";

// const Wrapper = styled.div`
//   display: flex;
//   align-items: stretch;
// `;

// const SidenavConfig = styled.div`
//   position: -webkit-sticky;
//   position: sticky;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   justify-content: flex-start;
//   top: 56px;
//   width: 320px;
//   height: calc(100vh - 56px);
//   border-right: 1px solid #d9dee0;
//   flex-grow: 0;
//   margin: -40px 4rem -40px -40px;
//   padding: 0;

//   @media (max-width: 1440px) {
//     width: 240px;
//   }

//   h5 {
//     font-size: 1.75rem;
//     margin: 2.25rem 2rem 1.25rem;
//   }

//   a {
//     padding: 1rem 2rem;
//     font-size: 1.2rem;
//     font-weight: 600;
//     cursor: pointer;
//     transition: all 0.3s ease;
//   }

//   a:hover {
//     text-decoration: underline;
//   }

//   a.active {
//     background: ${(props) => props.theme.grayLight};
//     text-decoration: underline;
//   }
// `;

// const Section = styled.section`
//   margin: 3rem 0 6rem;
//   border: 1px solid ${(props) => props.theme.grayLight};
//   background: ${(props) => props.theme.background};
//   padding: 3rem 4rem;
//   border-radius: 8px;
//   box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);

//   h3 {
//     font-size: 2.375rem;
//     margin-bottom: 2rem;
//   }
// `;

// const VendasContainer = styled.div`
//   flex-grow: 1;

//   h2 {
//     font-size: 3.125rem;
//   }

//   .header {
//     display: flex;
//     justify-content: space-between;
//     align-items: baseline;

//     .switch {
//       margin-right: 0;
//       margin-left: auto;
//       display: flex;
//       align-items: center;

//       @media (max-width: 1440px) {
//         margin-right: 8rem;
//       }

//       @media (max-width: 1280px) {
//         margin-right: 4rem;
//       }

//       p {
//         font-size: 1.25rem;
//         margin-right: 1rem;
//         font-weight: 600;
//         /* color: ${(props) => props.theme.primary}; */
//       }
//     }
//   }
// `;

// const PillContainer = styled.div`
//   padding: 4px;
//   background-color: #eff0f2;
//   box-sizing: border-box;
//   border-radius: 4px;
//   margin: 2rem 0rem;
//   display: inline-block;
//   border: 1px solid #eaeaeb;

//   button:nth-of-type(2) {
//     margin-left: 1rem;
//   }
// `;

// const PillButton = styled.button`
//   padding: 12px 24px;
//   background-color: ${(props) =>
//     props.active ? props.theme.background : "transparent"};
//   color: ${(props) => (props.active ? props.theme.primary : props.theme.text)};
//   box-shadow: ${(props) => (props.active ? "0px 4px 8px #D5D9E1" : "none")};
//   border-radius: 4px;
//   outline: none;
//   border: none;
//   font-size: 18px;
//   cursor: pointer;
//   font-family: ${(props) =>
//     props.active
//       ? '"DIN W01 Medium", "Segoe UI", "Verdana", sans-serif'
//       : '"DIN W01 Regular", "Segoe UI", "Verdana", sans-serif'};
//   font-weight: ${(props) => (props.active ? "500" : "400")};

//   &:hover {
//     color: ${(props) => props.theme.primary};
//   }
// `;

// const TableContainer = styled.div`
//   margin: 1rem 0;

//   h4 {
//     font-size: 2rem;
//     margin: 3rem 0 0;
//   }
// `;

// const ModalApaga = styled.div`
//   h5 {
//     font-size: 1.5rem;
//     margin-bottom: 2rem;
//     line-height: 1.25;
//   }

//   .buttons {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
// `;

// const ModalAprova = styled.div`
//   h5 {
//     font-size: 1.5rem;
//     margin-bottom: 2rem;
//   }

//   label.criar-banco {
//     color: ${(props) => props.theme.text};
//     margin-bottom: 2rem;
//     font-size: 1rem;
//     display: inline-block;
//     user-select: none;

//     input {
//       margin-left: 1rem;
//     }
//   }

//   .inputWrapper {
//     margin-bottom: 2rem;
//   }

//   .buttons {
//     display: flex;
//     justify-content: space-between;
//   }
// `;

// const ContainerNumberInput = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin: 2rem 0;

//   label {
//     color: ${(props) => props.theme.text};
//   }

//   &:focus-within {
//     label {
//       color: ${(props) => props.theme.primary};
//     }
//   }
// `;

// const NumberInputStyled = styled.div`
//   display: inline-flex;
//   flex-direction: row;
//   align-items: stretch;
//   width: 100px;

//   input {
//     flex-grow: 1;
//     text-align: center;
//     width: 100%;
//     font-size: 1rem;
//   }

//   /* Chrome, Safari, Edge, Opera */
//   input::-webkit-outer-spin-button,
//   input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   /* Firefox */
//   input[type="number"] {
//     -moz-appearance: textfield;
//   }

//   button {
//     border: none;
//     background: ${(props) => props.theme.primary};
//     color: ${(props) => props.theme.background};
//     border-radius: 4px;
//     font-size: 0.75rem;
//     padding: 0.25rem 0.5rem;

//     svg.icon {
//       margin-top: 4px;
//     }
//   }
// `;

// const client = axios.create({
//   baseURL: `https://taxcellicenseserver.azurewebsites.net/`,
//   headers: {
//     "Content-Type": `application/x-www-form-urlencoded`,
//   },
// });

// export class Vendas extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       state: "",
//       teste: true,
//       vendaId: "",
//       isModalOpen: false,
//       capacidadeBd: 10,
//       numUser: 3,
//       nomeBanco: "",
//       criarBanco: false,
//       criando: false,
//       tableView: false,
//       testesList: [],
//       erroNomeBd: false,
//       loading: false
//     };

//     this.atualizaIdVenda = this.atualizaIdVenda.bind(this);
//   }

//   componentDidMount() {
//     this.getListaTestes();
//   }

//   getListaTestes() {
//     CadastroService.getCadastrosTeste(this.props.tokenLogin)
//       .then((res) => {
//         let testesList = res.data.cadastros.map((cadastro) => {
//           let split = cadastro.endereco.split(" - ");
//           let cidade, estado;

//           if (split[2]) {
//             [cidade, estado] = split[2].split(", ");
//           }

//           return {
//             id: cadastro._id,
//             email: cadastro.email,
//             cnpj: mask.cnpj(cadastro.cnpj),
//             nome: cadastro.razao_social,
//             telefone: mask.telEmpresa(cadastro.telefone),
//             cep: mask.cepEmpresa(cadastro.cep),
//             endereco: split[0],
//             bairro: split[1],
//             cidade: cidade,
//             estado: estado,
//             linkedin: cadastro.linkedin,
//           };
//         });

//         this.setState({ testesList });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }

//   handleClickIncrement = (oper, campo, step = 1) => {
//     //console.log(oper, campo);
//     if (oper === "+") {
//       this.setState((prevState) => ({
//         [campo]: parseInt(prevState[campo]) + step,
//       }));
//     } else if (oper === "-") {
//       this.setState((prevState) => {
//         if (parseInt(prevState[campo]) <= step) {
//           return {
//             [campo]: 0,
//           };
//         }
//         return {
//           [campo]: parseInt(prevState[campo]) - step,
//         };
//       });
//     }
//   };

//   toggleCriarBanco = () => {
//     this.setState((prevState, prevProps) => ({
//       criarBanco: !prevState.criarBanco,
//     }));
//   };

//   toggleModal = () => {
//     this.setState({
//       isModalOpen: !this.state.isModalOpen,
//     });
//   };

//   handleRecusa = (id, venda) => {
//     this.setState({
//       isModalOpen: true,
//       modalType: "apaga",
//       vendaId: id,
//       venda: venda,
//     });
//   };

//   handleAprova = (id, venda) => {
//     this.setState({
//       isModalOpen: true,
//       modalType: "aprova",
//       vendaId: id,
//       venda: venda,
//     });
//   };

//   handleChange = (e) => {
//     const {
//       target: { name, value },
//     } = e;

//     if (name === "nomeBanco") {
//       if (this.state.nomeBanco.length > 0) {
//         this.setState({ erroNomeBd: false });
//       }
//     }
//     // let v = value.replace(/\D/g, ""); only numbers

//     this.setState({
//       [name]: value,
//     });
//   };

//   apagaTeste = () => {
//     CadastroService.atualizaStatusCadastroTeste(
//       this.props.tokenLogin,
//       this.state.vendaId,
//       2
//     )
//       .then((resp) => {
//         this.getListaTestes();
//         store.addNotification({
//           content: (
//             <ToastNotification bg="primary" textoAuxiliar="">
//               Teste apagado com sucesso!{" "}
//             </ToastNotification>
//           ),
//           // onRemoval: (id, removedBy) => //console.log(removedBy),
//           ...notificationTopRight,
//         });
//       })
//       .catch((err) => {
//         store.addNotification({
//           content: (
//             <ToastNotification bg="secondary" textoAuxiliar="">
//               Não foi possível apagar o teste no momento. Tente novamente mais
//               tarde{" "}
//             </ToastNotification>
//           ),
//           // onRemoval: (id, removedBy) => //console.log(removedBy),
//           ...notificationTopRight,
//         });
//       });

//     this.setState({
//       isModalOpen: false,
//     });
//   };

//   atualizaIdVenda(id){
//     this.setState({
//       idVenda: id,
//       loading: true,
//     }, () =>{
//       this.setState({loading: false});
//     });
//   }
//   criaTeste = () => {
//     if (
//       !this.state.criando &&
//       this.state.numUser > 0 &&
//       this.state.capacidadeBd > 0
//     ) {
//       this.setState({
//         criando: true,
//       });
//       //cria

//       let obj = {
//         cliente: {
//           cnpj: this.state.venda.cnpj.replace(/\D/g, ""),
//           razao_social: this.state.venda.nome,
//           telefone: this.state.venda.telefone.replace(/\D/g, ""),
//           endereco: `${this.state.venda.endereco} - ${this.state.venda.bairro} - ${this.state.venda.cidade}, ${this.state.venda.estado}`,
//           cep: this.state.venda.cep.replace(/\D/g, ""),
//           tipo: "1",
//         },
//         venda: {
//           data_fim: moment(),
//           teste: 1,
//         },
//         usuario: {
//           email: this.state.venda.email,
//           nome_banco: this.state.criarBanco ? this.state.nomeBanco : null,
//           novo_banco: this.state.criarBanco,
//         },
//         plano: {
//           nome: "Plano de " + this.state.venda.nome,
//           num_bancos: this.state.numUser,
//           capacidade_bd: this.state.capacidadeBd,
//           capacidade_storage: this.state.capacidadeBd,
//         },
//       };

//       if (!obj.usuario.nome_banco && obj.usuario.novo_banco) {
//         // precisa ter nome_banco se novo_banco tá marcado
//         this.setState({ criando: false, erroNomeBd: true });
//         return;
//       }

//       VendaService.criarVenda(obj, this.props.tokenLogin)
//         .then((res) => {
//           CadastroService.atualizaStatusCadastroTeste(
//             this.props.tokenLogin,
//             this.state.vendaId,
//             1
//           ).catch((err) => {
//             // console.log(err);
//           });

//           //integracao RD
//           const form = new URLSearchParams();

//           form.append("source", "Teste-TaxDashs-Aprovado");
//           form.append("email", this.state.venda.email);
//           form.append("nome", this.state.venda.nome);
//           form.append("empresa", this.state.venda.nome);
//           form.append("celular", this.state.venda.telefone);

//           client.post(`/RD_Station.aspx`, form).then((resp) => {
//             // console.log(resp);
//           });
//           //-------------

//           this.getListaTestes();
//           store.addNotification({
//             content: (
//               <ToastNotification bg="primary" textoAuxiliar="">
//                 Teste criado com sucesso!{" "}
//               </ToastNotification>
//             ),
//             // onRemoval: (id, removedBy) => //console.log(removedBy),
//             ...notificationTopRight,
//           });

//           this.setState({
//             criando: false,
//             isModalOpen: false,
//             teste: false,
//           });
//         })
//         .catch((err) => {
//           // console.log(err.response);

//           store.addNotification({
//             content: (
//               <ToastNotification bg="secondary" textoAuxiliar="">
//                 Não foi possível criar o teste no momento. Tente novamente mais
//                 tarde{" "}
//               </ToastNotification>
//             ),
//             // onRemoval: (id, removedBy) => //console.log(removedBy),
//             ...notificationTopRight,
//           });
//           //error
//           this.setState({
//             criando: false,
//           });
//         });
//     } else {
//       store.addNotification({
//         content: (
//           <ToastNotification bg="secondary" textoAuxiliar="">
//             Número de usuários ou capacidade de storage inválidos!
//           </ToastNotification>
//         ),
//         // onRemoval: (id, removedBy) => //console.log(removedBy),
//         ...notificationTopRight,
//       });
//     }
//   };

//   toggleTableView = () => {
//     this.setState((prevState, prevProps) => ({
//       tableView: !prevState.tableView,
//     }));
//   };

//   render() {
//     return (
//       <Wrapper>
//         <SidenavConfig>
//           <h5>Vendas</h5>
//           <ScrollLink
//             activeClass="active"
//             to="aprovar-testes"
//             spy={true}
//             smooth={true}
//             offset={-300}
//             duration={500}
//           >
//             Aprovar Testes
//           </ScrollLink>
//           <ScrollLink
//             activeClass="active"
//             to="consultar-venda"
//             spy={true}
//             smooth={true}
//             offset={-300}
//             duration={500}
//           >
//             Consultar Venda
//           </ScrollLink>
//           <ScrollLink
//             activeClass="active"
//             to="nova-venda"
//             spy={true}
//             smooth={true}
//             offset={-300}
//             duration={500}
//           >
//             Criar Venda
//           </ScrollLink>
//           {/* <ScrollLink
//             activeClass="active"
//             to="criar-plano"
//             spy={true}
//             smooth={true}
//             offset={-350}
//             duration={500}
//           >
//             Criar novo Plano
//           </ScrollLink> */}
//         </SidenavConfig>
//         <VendasContainer>
//           <h2>Vendas</h2>
//           <Section title="Aprovar Teste" id="aprovar-testes">
//             <div className="header">
//               <h3>Aprovar Testes</h3>
//               <div className="switch">
//                 <p>Visualização em Tabela</p>
//                 <SwitchButton
//                   id="tableview"
//                   onChange={this.toggleTableView}
//                   checked={this.state.tableView}
//                   small
//                 />
//               </div>
//             </div>
//             {this.state.tableView ? (
//               <TableAprovaTeste
//                 data={this.state.testesList}
//                 clickAprova={this.handleAprova}
//                 clickApaga={this.handleRecusa}
//               />
//             ) : this.state.testesList.length === 0 ? (
//               <p>Nenhum teste para aprovar</p>
//             ) : (
//               this.state.testesList.map((teste) => (
//                 <AprovaTeste
//                   key={teste.id}
//                   id={teste.id}
//                   email={teste.email}
//                   cnpj={teste.cnpj}
//                   nome={teste.nome}
//                   telefone={teste.telefone}
//                   cep={teste.cep}
//                   endereco={teste.endereco}
//                   bairro={teste.bairro}
//                   cidade={teste.cidade}
//                   estado={teste.estado}
//                   linkedin={teste.linkedin}
//                   recusaClick={() => this.handleRecusa(teste.id, teste)}
//                   aprovaClick={() => this.handleAprova(teste.id, teste)}
//                 />
//               ))
//             )}
//           </Section>
//           {this.state.isModalOpen && (
//             <Modal isOpen={this.state.isModalOpen} onClose={this.toggleModal}>
//               {this.state.modalType === "apaga" ? (
//                 <ModalApaga>
//                   <h5>
//                     Deseja recusar e apagar o teste de{" "}
//                     <b>
//                       {
//                         this.state.testesList.filter(
//                           (teste) => teste.id === this.state.vendaId
//                         )[0]?.nome
//                       }
//                     </b>
//                     ?
//                   </h5>
//                   <div className="buttons">
//                     <Button background="gray" onClick={this.toggleModal}>
//                       Cancelar
//                     </Button>
//                     <Button onClick={this.apagaTeste} background="danger">
//                       Apagar
//                     </Button>
//                   </div>
//                 </ModalApaga>
//               ) : (
//                 <ModalAprova>
//                   <h5>
//                     Criar teste de{" "}
//                     {
//                       this.state.testesList.filter(
//                         (teste) => teste.id === this.state.vendaId
//                       )[0]?.nome
//                     }
//                   </h5>
//                   <p className="email">
//                     Email:{" "}
//                     {
//                       this.state.testesList.filter(
//                         (teste) => teste.id === this.state.vendaId
//                       )[0]?.email
//                     }
//                   </p>
//                   <ContainerNumberInput>
//                     <label for="numUser">Número de Usuários:</label>
//                     <NumberInputStyled>
//                       <button
//                         onClick={() =>
//                           this.handleClickIncrement("-", "numUser")
//                         }
//                       >
//                         <MinusIcon className="icon" />
//                       </button>
//                       <input
//                         type="number"
//                         name="numUser"
//                         id="numUser"
//                         required={true}
//                         value={this.state.numUser}
//                         onChange={this.handleChange}
//                       ></input>
//                       <button
//                         onClick={() =>
//                           this.handleClickIncrement("+", "numUser")
//                         }
//                       >
//                         <PlusIcon className="icon" />
//                       </button>
//                     </NumberInputStyled>
//                   </ContainerNumberInput>
//                   <ContainerNumberInput>
//                     <label for="capacidadeBd">
//                       Capacidade Máxima do Teste (em GB):
//                     </label>
//                     <NumberInputStyled>
//                       <button
//                         onClick={() =>
//                           this.handleClickIncrement("-", "capacidadeBd")
//                         }
//                       >
//                         <MinusIcon className="icon" />
//                       </button>
//                       <input
//                         type="number"
//                         name="capacidadeBd"
//                         id="capacidadeBd"
//                         required={true}
//                         value={this.state.capacidadeBd}
//                         onChange={this.handleChange}
//                       ></input>
//                       <button
//                         onClick={() =>
//                           this.handleClickIncrement("+", "capacidadeBd")
//                         }
//                       >
//                         <PlusIcon className="icon" />
//                       </button>
//                     </NumberInputStyled>
//                   </ContainerNumberInput>{" "}
//                   <label className="criar-banco">
//                     Criar banco?
//                     <input
//                       type="checkbox"
//                       checked={this.state.criarBanco}
//                       onChange={this.toggleCriarBanco}
//                     ></input>
//                   </label>
//                   {this.state.criarBanco && (
//                     <>
//                       {" "}
//                       <div className="inputWrapper">
//                         <p>Nome do Banco:</p>
//                         <FormInput
//                           type="text"
//                           id="nomeBanco"
//                           label=""
//                           placeholder=""
//                           required
//                           mt={"0"}
//                           value={this.state.nomeBanco}
//                           onChange={this.handleChange}
//                           error={this.state.erroNomeBd}
//                           errorMsg="Preencha o nome do banco"
//                         />
//                       </div>
//                     </>
//                   )}
//                   <div className="buttons">
//                     <Button background="gray" onClick={this.toggleModal}>
//                       Cancelar
//                     </Button>
//                     <Button background="primary" onClick={this.criaTeste}>
//                       {this.state.criando ? (
//                         <span>
//                           Criando Teste <LoadingIcon fill="white" />
//                         </span>
//                       ) : (
//                         <span>Criar Teste</span>
//                       )}
//                     </Button>
//                   </div>
//                 </ModalAprova>
//               )}
//             </Modal>
//           )}
//           <Section title="Consultar Venda" id="consultar-venda">
//             {this.state.loading ? (
//               <LoadingIcon />
//             ) : (
//               <>
//                 <h3>Consultar Venda</h3>
//                 <ConsultarVenda
//                   idVenda={this.state.idVenda}
//                   tokenLogin={this.props.tokenLogin}
//                 />
//               </>
//             )}
//             <TableContainer>
//               <h4>Tabela de vendas</h4>
//               <PillContainer>
//                 <PillButton
//                   active={!this.state.teste}
//                   onClick={() => this.setState({ teste: false })}
//                 >
//                   Vendas
//                 </PillButton>
//                 <PillButton
//                   active={this.state.teste}
//                   onClick={() => this.setState({ teste: true })}
//                 >
//                   Testes
//                 </PillButton>
//               </PillContainer>

//               {this.state.teste ? (
//                 <TableVendasTeste selecionaVenda={this.atualizaIdVenda} tokenLogin={this.props.tokenLogin} />
//               ) : (
//                 <TableVendas tokenLogin={this.props.tokenLogin} />
//               )}
//             </TableContainer>
//           </Section>
//           <Section title="Nova Venda" id="nova-venda">
//             <h3>Criar Venda</h3>
//             <CriarVenda tokenLogin={this.props.tokenLogin} />
//           </Section>
//           {/* <Section title="Criar novo Plano" id="criar-plano">
//             <h3>Criar novo Plano</h3>
//             <CriarPlano tokenLogin={this.props.tokenLogin} />
//           </Section> */}
//         </VendasContainer>
//       </Wrapper>
//     );
//   }
export function Vendas(props) {
  return <AreaVendedores {...props} />;
}
