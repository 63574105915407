import React, { useState } from "react";
import styled from "styled-components";
import Button from "../@DesignSystem/Button";
import { AnimatePresence, motion } from "framer-motion";
import { ClickOutsideListener } from "react-click-outside-listener";
import AgendamentoService from "../../services/AgendamentoService";

import LoadingIcon from "../LoadingIcon";

const Wrapper = styled(motion.div)`
  margin-top: 32px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border-radius: 0px 24px;
  background: rgba(199, 209, 219, 0.25);
  width: 474px;
  box-sizing: border-box;
  cursor: pointer;

  .pill {
    position: absolute;
    top: 0;
    right: 32px;
    padding: 4px 16px;
    border-radius: 8px;
    border: 1px solid #eef0f0;
    background: #fff;
    color: #0046d7;
    font-size: 20px;
    font-weight: 600;
    transform: translateY(-50%);
  }

  h5 {
    font-size: 20px;
    font-style: normal;
    font-family: "Segoe UI", "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 28px;
  }

  .legenda {
    color: #484a51;
    font-size: 14px;
    font-weight: 400;

    b {
      font-weight: 600;
    }
  }

  .flex {
    display: flex;

    p:first-child {
      margin-right: 8px;
    }
  }

  .label {
    color: #484a51;
    font-size: 12px;
    font-weight: 400;
  }

  .label.active {
    color: ${(p) => p.theme.primary};
    font-weight: 600;
  }

  .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    b {
      font-weight: 600;
    }
  }

  span {
    color: ${(p) => (p.$status === 3 ? p.theme.danger : p.theme.primary)};
  }
`;

const DateWrapper = styled.div`
  display: flex;

  label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(p) => p.theme.text};
  }

  &:focus-within {
    label {
      color: ${(p) => p.theme.primary};
    }
  }

  input[type="date"],
  input[type="time"] {
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.input.border};

    &:active {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }

    &:focus {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }
  }

  input[type="time"] {
    margin-left: 16px;
    margin-right: 32px;
  }
`;

const ErroMsg = styled.p`
  font-size: 12px;
  color: ${(p) => p.theme.danger};
  margin-top: 8px;
`;

export default function AgendamentoCriado({
  ag,
  tokenLogin,
  atualizaAgendamentos,
}) {
  const dataProxCargaFormated = ag.proximaExecucao.split("T")[0];
  const horaProxCargaFormated = ag.proximaExecucao
    .split("T")[1]
    .substring(0, 5);

  const [isEditing, setIsEditing] = useState(false);
  const [erroData, setErroData] = useState(false);
  const [dataProxCarga, setDataProxCarga] = useState(dataProxCargaFormated);
  const [timeCarga, setTimeCarga] = useState(horaProxCargaFormated);

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const formataTipo = {
    ICMS: "EFD ICMS",
    CONTRIB: "EFD Contrib",
    CONTABIL: "ECD Contábil",
    ECF: "ECF",
  };

  const formataCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formataCNPJ = (cnpj) => {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const formataData = (dataString) => {
    let data = new Date(dataString);
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  //data de hoje em yyyy-mm-dd
  let dataHoje = new Date();
  dataHoje.setDate(dataHoje.getDate() + 1);
  dataHoje = dataHoje.toISOString().slice(0, 10);

  const editaDataAgendamento = () => {
    if (loadingEdit) return;
    console.log(dataProxCarga);
    let novaData = formataData(dataProxCarga) + " " + timeCarga;

    console.log("Nova Data: ",novaData);
    console.log("Data Hoje: ",dataHoje);
    if (novaData < dataHoje) {
      setErroData(true);
      return;
    }
    
    setLoadingEdit(true);
    AgendamentoService.alteraDataAgendamento(tokenLogin, ag._id, novaData)
      .then((resp) => {
        console.log(resp);
        atualizaAgendamentos();
        setIsEditing(false);
        setLoadingEdit(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingEdit(false);
      });
  };

  const apagaAgendamento = () => {
    if (loadingDelete) return;
    setLoadingDelete(true);
    AgendamentoService.apagarAgendamento(tokenLogin, ag._id)
      .then((resp) => {
        console.log(resp);
        atualizaAgendamentos();
        setLoadingDelete(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDelete(false);
      });
  };

  return (
    <ClickOutsideListener onClickOutside={() => setIsEditing(false)}>
      <Wrapper
        $status={ag.statusOperacao}
        onClick={(e) => {
          setIsEditing(true);
          e.stopPropagation();
        }}
      >
        <p className="pill">{formataTipo[ag.tipoArquivo]}</p>
        <div>
          <h5>{ag.certificado}</h5>
          <p className="legenda">
            {ag.ehProcurador ? "Procurador" : "Contribuinte"}{" "}
            {ag.ehCpf ? (
              <b>{formataCPF(ag.numDocumento)}</b>
            ) : (
              <b>{formataCNPJ(ag.numDocumento)}</b>
            )}
          </p>
        </div>
        <div>
          <AnimatePresence>
            {isEditing ? (
              <motion.div
                key="editaData"
                initial={{ opacity: 0, scaleY: 0 }}
                animate={{ opacity: 1, scaleY: 1 }}
                exit={{ opacity: 0, scaleY: 0 }}
                mode="popLayout"
              >
                <p className="label active">Próxima execução</p>
                <DateWrapper>
                  <input
                    name={`dtIni`}
                    type="date"
                    defaultValue={dataProxCargaFormated}
                    min={dataHoje}
                    onChange={(e) => {
                      setDataProxCarga(new Date(e.target.value + " 00:00"));
                      if (e.target.value >= dataHoje) {
                        setErroData(false);
                      }
                    }}
                  />
                  <input
                    name={`timeIni`}
                    type="time"
                    defaultValue={horaProxCargaFormated}
                    onChange={(e) => {
                      setTimeCarga(e.target.value);
                    }}
                  />
                  <Button onClick={editaDataAgendamento} size="small">
                    Salvar data {loadingEdit && <LoadingIcon fill="white" />}
                  </Button>
                  
                </DateWrapper>
                {erroData && (
                  <ErroMsg>A data de início não pode ser anterior a data de hoje</ErroMsg>
                )}
              </motion.div>
            ) : (
              <motion.div
                key="proxData"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                mode="popLayout"
              >
                
                <p className="label">Próxima execução</p>
                <div className="flex">
                  <p className="text">
                    <b>{formataData(ag.proximaExecucao)}</b>
                  </p>
                  <p className="text">
                    Período retroativo:{" "}
                    <b>
                      {ag.intervalo}{" "}
                      {ag.periodo === "M" && ag.intervalo > 1
                        ? "meses"
                        : ag.periodo === "M" && ag.intervalo === 1
                        ? "mês"
                        : ag.periodo === "A" && ag.intervalo > 1
                        ? "ano"
                        : "anos"}
                    </b>
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {ag.qtdExecucoes > 0 ? (
          <div>
            <p className="label">Última execução</p>
            <div className="flex">
              <p className="text">
                <b>{formataData(ag.ultimaExecucao)}</b>
              </p>
              <p className="text">
                <span>
                  {ag.statusOperacao === 1
                    ? "Executando operação"
                    : ag.statusOperacao === 2
                    ? "Executado com sucesso"
                    : "Falha na execução"}
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p className="label">Última execução</p>
            <p>Nenhuma execução anterior</p>
          </div>
        )}
        <p className="label">{ag.descricaoOperacao}</p>
        <AnimatePresence>
          {isEditing && (
            <motion.div
              key="apagaButton"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              mode="wait"
              style={{ width: "100%" }}
            >
              <Button
                onClick={apagaAgendamento}
                mt={16}
                secondary
                color="danger"
                bg="dangerLight"
                center
              >
                Apagar agendamento{" "}
                {loadingDelete && <LoadingIcon fill="danger" />}
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    </ClickOutsideListener>
  );
}
