import React from 'react'
import styled from "styled-components"
import { useHistory } from "react-router-dom";

import Button from "./components/Button"

const Page404 = styled.div`
    h1 {
        font-size: 64px;
        margin-bottom: 64px;
        color: ${props => props.theme.text};
    }

    button {
        margin-right: 2rem;
    }
`

export default (props) => {
    let history = useHistory();
    return (
        <Page404>
            <h1>Página não encontrada</h1>
            <Button background="primary" link onClick={() => history.goBack()}>Voltar</Button>
            <Button background="primary" onClick={() => history.push("/")}>Ir para Home</Button>
        </Page404>
    )
}
