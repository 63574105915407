import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const TableWrapper = styled.div`
  border-radius: 4px;
  overflow: visible;
  border: 1px solid rgba(236, 239, 244, 0.85);
`;

export const Table = styled.table`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: normal;

  th,
  td {
    span.cell-data {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  thead {
    text-align: left;
    color: #787b85;
    font-weight: 600;
    line-height: 21px;
    background: #ffffff;
    border-right: 1px solid rgba(236, 239, 244, 0);

    &:hover {
      th {
        border-right: 1px solid rgba(236, 239, 244, 0.85);
      }
    }

    th {
      border-right: 1px solid rgba(236, 239, 244, 0);
      padding: 24px 32px 16px 16px;
      position: relative;
      background: #ffffff;
    }

    th:first-of-type {
      padding: 0;
      border-right: 0px solid rgba(236, 239, 244, 0.85);
    }
  }

  tbody {
    color: #202124;

    tr {
      flex-direction: column;

      .tr {
        display: flex;
      }
    }

    td {
      position: relative;
      background: #ffffff;
      text-align: left;

      span.cell-data {
        padding: 8px 16px;
        box-sizing: border-box;
      }

      &.tooltip:hover {
        span.cell-data {
          overflow: visible;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1),
            0px 0px 2px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          position: absolute;
          width: fit-content;
          height: 100%;
          background: #ffffff;
          z-index: 100;
        }

        span {
          overflow: visible;
        }
      }
    }

    td.td-actions {
      overflow: visible;
      justify-self: stretch;
      text-align: right;

      span.cell-data {
        overflow: visible;
      }
    }

    td.expand {
      padding: 0;

      span.cell-data {
        padding: 0;
      }
    }

    tr:nth-child(odd) {
      background: #f8f9f9;

      td {
        background: #f8f9f9;
        border-top: 1px solid #f8f9f9;
      }
    }

    tr:nth-child(even) {
      background: #ffffff;

      td {
        background: #ffffff;
        border-top: 1px solid #ffffff;
      }
    }

    tr.expired td:first-of-type {
      background: ${(p) => p.theme.danger};
      background: #cc2241;
    }
  }
`;

export const DoubleRow = styled.div`
  p:last-of-type {
    font-size: 14px;
    color: #5a5c63;
  }
`;

export const Resizer = styled.div`
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
  touch-action: none;
  height: 100%;

  &:hover {
    border-color: ${(p) => (p.$active ? p.theme.primaryLight : "#ccc")};
  }
`;

export const Pagination = styled.div`
  background-color: white;
  display: flex;
  color: #5a5c63;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 12px;
  align-items: center;
  border-top: 1px solid #f2f2f5;

  p {
    margin-left: 20px;
  }

  .qtdRows {
    display: flex;
    align-items: center;

    p {
      margin-right: 16px;
    }
  }

  p.pageInfo {
    margin-left: auto;
  }

  .buttons {
    margin-left: 20px;
    display: flex;

    .next {
      button {
        transform: rotateY(180deg);
      }
    }

    button {
      background: none;
      border: none;
      padding: 4px 6px;
      border-radius: 4px;
      color: #5a5c63;

      svg {
        width: 20px;
        height: 20px;
        transform: translateY(2px);
      }

      &.disabled {
        color: #ccc;

        &:hover {
          background: none;
        }
      }

      &:hover {
        background: #eee;
        cursor: pointer;
      }
    }
  }
`;

export const Sorter = styled.div`
  color: ${(p) => p.theme.text};
  position: absolute;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  right: 4px;
  top: 24px;
  transform: ${(p) => (p.invert ? "rotateX(180deg)" : "rotateY(0deg)")};

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const Expander = styled.div`
  color: #5a5c63;
  background-color: inherit;

  svg {
    transform: ${(p) => (p.expanded ? "rotate(-90deg)" : "rotate(180deg)")};
  }
`;

export const ExpandedWrapper = styled(motion.div)`
  display: flex;
  background: inherit;

  .block {
    width: 8px;
    align-self: stretch;
    background: ${(p) => (p.$expired ? "#cc2241" : "transparent")};
  }

  .content {
    padding: 24px 32px;
    display: flex;
    align-items: center;

    span.label {
      color: #5a5c63;
    }

    div {
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }
    }

    p.small {
      font-size: 14px;
      color: #5a5c63;
      text-align: right;
    }
  }
`;

export const NenhumDado = styled.div`
  padding: 24px 16px;
  display: block;
  text-align: center;
  background-color: ${(p) => p.theme.background};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid #f8f9f9;
  border-bottom: 1px solid #f8f9f9;

  p {
    font-size: 18px;
    font-weight: 600;
    padding: 16px 24px;
    border-radius: 4px;
    background-color: ${(p) => p.theme.background};
    display: block;
  }
`;

export const Loading = styled.div`
  height: 160px;
  border-top: 1px solid #f8f9f9;
  border-bottom: 1px solid #f8f9f9;
  background-color: ${(p) => p.theme.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: .75;

  p {
    margin-top: 16px;
  }
`;
