import React from "react";
import styled from "styled-components/macro";

import SwitchButton from "../SwitchButton";
import Button from "../Button";

import Modal from "../Modal";

import Select from "react-select";

import { ReactComponent as SaveIcon } from "../../icons/save-icon.svg";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "../ToastNotification";
import UsuarioService from "../../services/UsuarioService";

const StyledH4 = styled.h4`
  font-size: 2rem;
  margin-bottom: 1em;
  margin-top: 4rem;
`;

const ModalContainer = styled.div`
  h2 {
    font-size: 1.25em;
    line-height: 1.3;
  }

  p {
    margin-top: 1em;
    font-size: 1em;
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Container = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  background-color: ${(props) =>
    props.contato ? props.theme.primaryLighter : props.theme.grayLight};
  border-radius: 8px;
  width: 560px;

  .select-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;

    .select {
      /* width: 320px; */
      width: 100%;
      margin: 1rem 0;
    }

    button {
      align-self: flex-start;
    }
  }

  .metricas {
    margin: 8px 0 16px;
    p {
      margin-bottom: 8px;
      font-size: 16px;
    }
  }

  .wrapper {
    margin-bottom: 1rem 0;
    display: flex;
    align-items: center;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1rem;
    width: 100%;

    p {
      font-size: 1.25rem;
    }
  }

  span:first-child {
    color: ${(props) =>
      props.leitor ? props.theme.text : props.theme.grayDark};
    font-weight: ${(props) => (props.leitor ? "600" : "inherit")};
  }

  span:last-child {
    color: ${(props) =>
      props.leitor ? props.theme.grayDark : props.theme.primary};
    font-weight: ${(props) => (props.leitor ? "inherit" : "600")};
  }

  label {
    margin: 0 0.5rem;
  }
`;

export class AlteraTudo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      selectedOption: null,
      comum: false,
      bancos: [],
    };
    this.carregaOpcoesBancos = this.carregaOpcoesBancos.bind(this);
  }

  componentDidMount() {
    //console.log(opcoesBancos);
    this.setState(
      {
        selectedOption: this.props.banco,
        comum: this.props.comum,
      },
      () => this.carregaOpcoesBancos()
    );
  }

  carregaOpcoesBancos() {
    let opcoesBancos = null;
    if (this.state.comum) {
      let bancosDisabled = this.props.bancosNaoDisponiveis.map((data) => ({
        ...data,
        isDisabled: true,
      }));
      opcoesBancos = [
        {
          label: "Disponiveis",
          options: this.props.bancosDisponiveis,
        },
        {
          label: "Não Disponiveis",
          options: bancosDisabled,
          color: "#d4d4d4",
        },
      ];
    } else {
      opcoesBancos = [
        ...this.props.bancosDisponiveis,
        ...this.props.bancosNaoDisponiveis,
      ];
    }
    this.setState({
      bancos: opcoesBancos,
    });
  }

  toggleModalAdm = (usuario) => {
    this.props.handleToggleModalAdm(usuario);
  };

  toggleModalDelete = (usuario) => {
    this.props.handleToggleModalDelete(usuario);
  };

  salvaAlteracoes = () => {
    let obj = {
      email: this.props.nome,
      permissao: "Comum",
      id_banco: this.state.selectedOption
        ? this.state.selectedOption.value
        : null,
    };

    //console.log(this.props.tokenLogin);
    //console.log(obj);
    //console.log(this.props.permissao);
    let ehContato = this.props.permissao.toLowerCase() === "contato" ? true : false;

    if (this.props.comum) {
      // state é o toggle
      if (!this.state.comum) {
        UsuarioService.removePermissao(this.props.tokenLogin, obj)
          .then((resp) => {
            this.alteraBanco(true);
            store.addNotification({
              content: (
                <ToastNotification bg="primary" textoAuxiliar="">
                  Alterações feitas com sucesso! {ehContato ? "É necessário atualizar a página para validar as alterações." : ""}
                </ToastNotification>
              ),
              ...notificationTopRight,
            });
          })
          .catch((err) => {
            // console.log(err);
            store.addNotification({
              content: (
                <ToastNotification bg="secondary" textoAuxiliar="">
                  Não foi possível fazer as alterações
                </ToastNotification>
              ),
              ...notificationTopRight,
            });
          });
      } else {
        this.alteraBanco();
      }
    } else {
      if (this.state.comum) {
        UsuarioService.adicionaPermissao(this.props.tokenLogin, obj)
          .then((resp) => {
            if (!resp.data.novo_banco) this.alteraBanco(true);
            store.addNotification({
              content: (
                <ToastNotification bg="primary" textoAuxiliar="">
                  Alterações feitas com sucesso! {ehContato ? "É necessário atualizar a página para validar as alterações." : ""}
                </ToastNotification>
              ),
              ...notificationTopRight,
            });
          })
          .catch((err) => {
            // SE ERRO FOR 403 N TEM ESPAÇO MAIS PRA USUÁRIO "COMUM"
            // if (err.response.status === 403) {
            // console.log("eee", err.response);
            store.addNotification({
              content: (
                <ToastNotification bg="secondary" textoAuxiliar="">
                  {err.response.data.descricao}
                </ToastNotification>
              ),
              ...notificationTopRight,
            });
            // } else {
            //   store.addNotification({
            //     content: (
            //       <ToastNotification bg="secondary" textoAuxiliar="">
            //         Não foi possível fazer as alterações
            //       </ToastNotification>
            //     ),
            //     ...notificationTopRight,
            //   });
            // }
            // console.log(err);
          });
      } else {
        this.alteraBanco();
      }
    }
  };

  alteraBanco(atualizouPermissao = false) {
    // SE É O BANCO ATUAL N FAZ NADA
    // console.log(this.props.banco, this.state.selectedOption.value)
    if (
      this.props.banco &&
      this.state.selectedOption &&
      this.props.banco.value === this.state.selectedOption.value
    ) {
      if(atualizouPermissao){
        this.props.atualizaBancosUsuarios();
      }
      return
    }

    let obj = {
      email: this.props.nome,
      banco: this.state.selectedOption ? this.state.selectedOption.value : null,
    };

    if (!obj.banco) return;

    UsuarioService.alterarBancoUsuario(this.props.tokenLogin, obj)
      .then((resp) => {
        // ATUALIZAR TODOS OS DADOS DO PAI
        this.props.atualizaBancosUsuarios();
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Banco alterado com sucesso, atualize a página.{" "}
            </ToastNotification>
          ),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível alterar o banco do usuário.
            </ToastNotification>
          ),
          ...notificationTopRight,
        });
      });
  }

  handlePermChange = () => {
    this.setState(
      (prevState, prevProps) => ({
        comum: !prevState.comum,
      }),
      () => {
        this.carregaOpcoesBancos();
      }
    );
  };

  handleBancoChange = (selectedOption, e) => {
    this.setState({
      isLoading: true,
    });
    // //console.log(`Option selected:`, selectedOption);
    this.setState((prevState, prevProps) => ({
      selectedOption,
    }));

    // //console.log(this.state.selectedOption.value);
  };

  render() {
    return (
      <Container
        leitor={this.props.permissao === "leitor"}
        contato={this.props.permissao.toLowerCase() === "contato"}
      >
        <div className="flex-wrapper">
          <p className="email">
            {" "}
            {this.props.nome}{" "}
            {this.props.permissao.toLowerCase() === "contato" && (
              <b>(sua conta)</b>
            )}
            :{" "}
          </p>{" "}
          {this.props.permissao.toLowerCase() !== "contato" && (
            <Button
              link
              background="primary"
              onClick={() => this.toggleModalAdm(this.props.nome)}
            >
              Tornar administrador{" "}
            </Button>
          )}
        </div>
        <div className="metricas">
          <p>
            <b>Último login:</b> {this.props.ultimoLogin}
          </p>
          <p>
            <b>Qtde. logins:</b> {this.props.qtdLogins}
          </p>
          {/* <p><b>Última operação:</b> {this.props.ultimaOper} ({this.props.dataUltimaOper})</p> */}
        </div>
        {
          <div className="wrapper">
            <span> Leitor </span>{" "}
            <SwitchButton
              id={this.props.id}
              onChange={this.handlePermChange}
              checked={this.state.comum}
              small
            />
            <span> Escritor </span>{" "}
          </div>
        }
        <div className="select-wrapper">
          <div className="select">
            <Select
              name={this.props.id}
              isClearable={true}
              isSearchable={true}
              value={this.state.selectedOption}
              onChange={this.handleBancoChange}
              options={this.state.bancos}
              isLoading={this.props.isLoading}
              placeholder="Selecione o banco"
              noOptionsMessage={()=>"Nenhum banco encontrado"}
            />
          </div>
          <FlexWrapper>
            <Button background="primary" onClick={this.salvaAlteracoes}>
              <SaveIcon className="icon" /> Salvar alterações{" "}
            </Button>
            {this.props.permissao.toLowerCase() !== "contato" && (
              <Button
                mt={0.5}
                link
                background="danger"
                onClick={() => this.toggleModalDelete(this.props.nome)}
              >
                Apagar usuário
              </Button>
            )}
          </FlexWrapper>
        </div>
      </Container>
    );
  }
}

export default AlteraTudo;
