import React, { useEffect, useState } from "react";

import { ReactComponent as PlusIcon } from "./add.svg";
import { ReactComponent as MinusIcon } from "./remove.svg";

import * as S from "./style";

export default function InputNumber({
  defaultValue = 0,
  label,
  step = 1,
  minValue,
  maxValue,
  mt = 0,
  mb = 0,
  handleChange,
  small
}) {
  const [number, setNumber] = useState(defaultValue);

  const addStep = () => {
    if (maxValue && number + step > maxValue) {
      setNumber(maxValue);
      return;
    }

    setNumber(number + step);
  };

  const subtractStep = () => {
    if (minValue && number - step < minValue) {
      setNumber(minValue);
      return;
    }
    setNumber(number - step);
  };

  useEffect(() => {
    handleChange(number);
  }, [number]);

  return (
    <S.Container small={small} mt={mt} mb={mb}>
      <p className="label">{label}</p>
      <S.Input small={small}>
        <S.Button small={small} onClick={subtractStep}>
          <MinusIcon />
        </S.Button>
        <span>{number}</span>
        <S.Button small={small} onClick={addStep}>
          <PlusIcon />
        </S.Button>
      </S.Input>
    </S.Container>
  );
}
