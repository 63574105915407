import axios from "axios"

export default class StatusService {
    static async getApplicationStatus() {
        return axios.get(`${process.env.REACT_APP_URL_KUBERNETS}/health`).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        }); 
    }

    static async getApiStatus() {
        return axios.get(`${process.env.REACT_API_URL_KUBERNETS}/api/health`).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        }); 
    }

    static async getAzureStatus() {
        return axios.get(`${process.env.REACT_APP_URL_KUBERNETS}/health/azure`).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        }); 
    }
}