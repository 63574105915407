import React from "react";
import styled from "styled-components/macro";
// import { useBlockLayout } from "react-table";

const FormInputStyled = styled.div`
  position: relative;
  max-width: ${(props) =>
    props.size === "short"
      ? "188px"
      : props.size === "medium"
      ? "320px"
      : "100%"};
  margin-top: ${(props) => (props.mt ? props.mt : 1)}rem;
  ${(props) => {
    if (props.size === "short") {
      return `
      display: inline-block;
    `;
    }
  }}

  label {
    color: ${(props) => props.theme.text};
    font-size: 0.75rem;
    display: block;
    width: 100%;
    text-align: left;
  }

  input {
    max-width: ${(props) =>
      props.size === "short"
        ? "188px"
        : props.size === "medium"
        ? "320px"
        : "100%"};
    width: ${(props) =>
      props.size === "short"
        ? "188px"
        : props.size === "medium"
        ? "320px"
        : "100%"};
    border-width: 0 0 1px 0;
    border-color: #c8c8c8;
    border-style: solid;
    padding: 0.5rem 0.75rem;
    margin: 0;
    box-sizing: border-box;
    font-size: 1rem;
    color: ${(props) => (props.readonly ? "#7E7E7E" : props.theme.text)};

    &:focus {
      outline: none;
      background: ${(props) => props.theme.grayLight};
    }

    &:valid {
      background: ${(props) =>
        props.readonly
          ? props.theme.background
          : props.error
          ? "#fff3f2"
          : "#e6edfa"};

      span.block {
        background: ${(props) => props.theme.success};
      }
    }
    background: ${(props) =>
      props.error
        ? "#fff3f2"
        : props.bgFilled
        ? props.theme.grayLight3
        : props.theme.background};
  }

  span.block {
    display: block;
    position: absolute;
    right: 0;
    background: ${(props) =>
      props.readonly
        ? props.theme.text
        : props.error
        ? props.theme.danger
        : props.theme.sidenav};
    height: 4px;
    width: 32px;
    transform: translateY(-4px);
    transition: all 150ms ease;
  }

  span.error {
    font-size: 0.75rem;
    color: ${(props) => props.theme.danger};
    margin-top: 0.5rem;
    display: ${(props) => (props.error ? "block" : "none")};
  }

  &:focus-within {
    label {
      color: ${(props) =>
        props.readonly ? props.theme.text : props.theme.sidenav};
    }

    span.block {
      transform: ${(props) =>
        props.readonly ? "translateY(0)" : "translateY(-2px)"};
      width: ${(props) => (props.readonly ? "32px" : "100%")};
      height: ${(props) => (props.readonly ? "4px" : "2px")};
      background: ${(props) => props.theme.primary};
    }
  }
`;

export const FormInput = (props) => {
  const {
    inputText,
    readonly,
    size,
    errorMsg,
    error,
    label,
    id,
    required,
    inputRef,
    mt,
    bgFilled,
    ...rest
  } = props;
  return (
    <>
      <FormInputStyled
        inputRef={inputText}
        readonly={readonly}
        size={size}
        error={error}
        mt={mt}
        bgFilled={bgFilled}
      >
        <label htmlFor={id}>{label}</label>
        <input
          ref={inputRef}
          name={id}
          id={id}
          required={required}
          {...rest}
        ></input>
        <span className="block"></span>
        <span className="error">{errorMsg}</span>
      </FormInputStyled>
    </>
  );
};
