import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100px) scale(1.1);
  }

  75% {
    opacity: 1;
    transform: translateY(0) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const FloatingContainer = styled.div`
    position: fixed;
    top: 88px;
    right: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    justify-items: center;
    z-index: 100;
    animation: ${slideIn};
    animation-duration: 450ms;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0;
`

export class FloatingButtons extends React.Component {
    render() {
        return (
            <FloatingContainer>
                {this.props.children}
            </FloatingContainer>
        )
    }
}