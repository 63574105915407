import React from "react";
import styled from "styled-components/macro";

import { CSSTransition } from "react-transition-group";

import SwitchButton from "../SwitchButton";

const TwofactorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
  margin-top: 3em;

  h4 {
    font-size: 1.75rem;
  }
`;

const GAuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
  overflow: hidden;
  margin-top: 1.75rem;
  padding: 0.25em;

  h5 {
    display: block;
    font-size: 1.25rem;
    margin-left: 1em;
  }

  &.transition-enter {
    opacity: 0;
    height: 0;
    margin-top: 0;
    padding: 0;
  }
  &.transition-enter-active {
    opacity: 1;
    height: auto;
    margin-top: 1em;
    padding: 0.25em;
    transition: all 300ms;
  }
  &.transition-exit {
    opacity: 1;
    height: auto;
    padding: 0em;
    margin-top: 1em;
  }
  &.transition-exit-active {
    opacity: 0;
    height: 0;
    margin-top: 0;
    padding: 0;
    transition: all 300ms;
  }
`;

const TwoFactor = (props) => (
  <>
    <TwofactorContainer>
      <h4>Autenticação em 2-fatores</h4>
      <SwitchButton
        id="twofactor"
        onChange={props.handleTwoFactorChange}
        checked={props.twofactor}
      />
    </TwofactorContainer>
    <CSSTransition
      in={props.twofactor}
      timeout={500}
      classNames="transition"
      unmountOnExit
    >
      <GAuthContainer>
        <h5>Google Authenticator</h5>
        <SwitchButton
          id="gauth"
          onChange={props.handleGAuthChange}
          checked={props.gAuth}
          small
        />
      </GAuthContainer>
    </CSSTransition>
  </>
);

export default TwoFactor;
