import React from 'react';

import Button from "../Button";
import AppStoreSvg from "../images/appstore.svg"
import GooglePlaySvg from "../images/google-play.svg";


const GAuthInstalacao = props => (
    <>
        <h2>Baixe o app</h2>
        <p>Baixe e instale o aplicativo Google Authenticator no seu celular</p>
        <img className="gauth-logo" src={require('../images/gauthlogo.png')} alt='Logo do aplicativo Google Authenticator' />
        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
            <img src={AppStoreSvg} alt='logo da Apple Appstore' />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank" rel="noopener noreferrer">
            <img src={GooglePlaySvg} alt='logo da Google playstore' />
        </a>
        <div className="footer">
            <Button background="gray" onClick={props.prevStep}>Cancelar</Button>
            <Button background="primary" onClick={props.nextStep}>Próximo</Button>
        </div>
    </>
);

export default GAuthInstalacao;