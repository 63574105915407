import React, { useEffect, useState } from "react";
import * as S from "./style";

import DSButton from "../../../@DesignSystem/Button";
import DSLink from "../../../@DesignSystem/StyledLink";
import DSFormInput from "../../../@DesignSystem/FormInput";
import DSInput from "../../../@DesignSystem/Input";
import Dropdown from "../../../@DesignSystem/Dropdown";

import { ReactComponent as EditIcon } from "../../../../icons/edit.svg";
import { AnimatePresence } from "framer-motion";
import { useInput } from "../../../../hooks/useInput";
import moment from "moment";
import VendaService from "../../../../services/VendaService";
import CadastroService from "../../../../services/CadastroService";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
} from "../../../ToastNotification";

import axios from "axios";
import LoadingIcon from "../../../LoadingIcon";

const client = axios.create({
  baseURL: process.env.REACT_APP_LICENSE_URL,
  headers: {
    "Content-Type": `application/x-www-form-urlencoded`,
  },
});

const planos = [
  {
    label: "Padrão",
    value: {
      usuarios: 3,
      capacidade: 10,
    },
  },
  {
    label: "Capacidade média",
    value: {
      usuarios: 5,
      capacidade: 30,
    },
  },
  {
    label: "Capacidade alta",
    value: {
      usuarios: 10,
      capacidade: 60,
    },
  },
  {
    label: "Personalizado",
    value: "Personalizado",
  },
];

export default function AprovaTeste({ pedido, tokenLogin, refreshLista }) {
  const [criandoTeste, setCriandoTeste] = useState(false);
  const [carregandoTeste, setCarregandoTeste] = useState(false);
  const [selectedPlano, setSelectedPlano] = useState(planos[0].value);
  const [editingData, setEditingData] = useState(false);
  const [dataExpiraTeste, setDataExpiraTeste] = useState("");
  const [editingNomeBanco, setEditingNomeBanco] = useState(false);
  const [planoPersonalizado, setPlanoPersonalizado] = useState(false);
  const [usuariosLeitores, setUsuariosLeitores] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copiar dados");
  const [criarBanco, setCriarBanco] = useState(true);

  const { value: qtdUsuarios, bind: bindQtdUsuarios } = useInput(
    "",
    "",
    "vazio"
  );
  const { value: qtdUsuariosLeitores, bind: bindQtdUsuariosLeitores } =
    useInput("", "", "vazio");
  const { value: capacidadeBd, bind: bindCapacidadeBd } = useInput(
    "",
    "",
    "vazio"
  );
  const { value: qtdDiasTeste, bind: bindQtdDiasTeste } = useInput(
    "15",
    "",
    "vazio"
  );
  const {
    value: nomeBancoValue,
    bind: bindNomeBancoValue,
    setValue: setNomeBanco,
  } = useInput("", "", "vazio");

  useEffect(() => {
    let date = moment().add(qtdDiasTeste, "days").format("DD/MM/YYYY");
    setDataExpiraTeste(date.toString());
  }, [qtdDiasTeste]);

  useEffect(() => {
    setNomeBanco(
      `Banco de ${pedido.email.substring(0, pedido.email.indexOf("@"))}`
    );
  }, []);

  const copyData = (pedido) => {
    navigator.clipboard
      .writeText(
        `${pedido.nomeEmpresa}\n\nemail: ${pedido.email},\ncontato: ${pedido.nome}\ntelefone: ${
          pedido.telefone
        },\n${pedido.cnpjNoMask.length === 14 ? "cnpj" : "cpf"}: ${pedido.cnpj}`
      )
      .then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
          setCopyButtonText("Copiado!");
          setTimeout(() => {
            setCopyButtonText("Copiar dados");
          }, 1500);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
  };

  const apagaTeste = () => {
    CadastroService.atualizaStatusCadastroTeste(
      tokenLogin,
      pedido.id,
      2
    )
      .then((resp) => {
        refreshLista();
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Teste apagado com sucesso!{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível apagar o teste no momento. Tente novamente mais
              tarde{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const criaTeste = () => {
    if (carregandoTeste) return;
    setCarregandoTeste(true);

    let data_fim = new Date(moment(dataExpiraTeste, "DD/MM/YYYY")).toISOString();;
    
    let obj = {
      cliente: {
        cnpj: pedido.cnpj.replace(/\D/g, ""),
        razao_social: pedido.nomeEmpresa,
        nome_contato: pedido.nome || "-",
        telefone: pedido.telefone.replace(/\D/g, ""),
        tipo: "1",
      },
      venda: {
        data_fim: data_fim,
        teste: 1,
      },
      usuario: {
        email: pedido.email,
        nome_banco: criarBanco ? nomeBancoValue : null,
        novo_banco: criarBanco,
      },
      plano: {
        nome: "Plano de " + pedido.nomeEmpresa,
        num_bancos:
          selectedPlano === "Personalizado"
            ? qtdUsuarios
            : selectedPlano.usuarios,
        capacidade_bd:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
        capacidade_storage:
          selectedPlano === "Personalizado"
            ? capacidadeBd
            : selectedPlano.capacidade,
      },
    };

    console.log(obj);

    VendaService.criarVenda(obj, tokenLogin)
      .then((res) => {
        CadastroService.atualizaStatusCadastroTeste(tokenLogin, pedido.id, 1).catch(
          (err) => {
            console.log(err);
          }
        );

        //integracao RD
        const form = new URLSearchParams();

        form.append("source", "Teste-TaxDashs-Aprovado");
        form.append("email", pedido.email);
        form.append("nome", pedido.nome);
        form.append("empresa", pedido.nomeEmpresa);
        form.append("celular", pedido.telefone);

        client.post(`/RD_Station.aspx`, form).then((resp) => {
          console.log(resp);
        });
        //-------------

        refreshLista();
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Teste criado com sucesso!{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
        setCarregandoTeste(false);
      })
      .catch((err) => {
        console.log(err);

        setCarregandoTeste(false);

        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível criar o teste no momento. Tente novamente mais
              tarde{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  return (
    <S.Pedido
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={pedido.id}
    >
      {pedido.novo && <div className="novo">Novo</div>}
      <div className="header">
        <p className="title">{pedido.nomeEmpresa}</p>
        {pedido.repetido && <div className="pill">Repetido</div>}
        <p className="text">Solicitado: {moment(pedido.date).fromNow()}</p>
      </div>
      <div className="body">
        <div className="dados">
          <p>{pedido.nome}</p>
          {/* <p>{moment(pedido.date).calendar()}</p> */}
          <p>{pedido.email}</p>
          <p>{pedido.telefone}</p>
          <p>{pedido.cnpj}</p>
        </div>
        <div>
          <DSButton
            fontSize={14}
            size="small"
            ghost
            bg="text"
            onClick={() => copyData(pedido)}
            color="text"
          >
            {copyButtonText}
          </DSButton>
        </div>
      </div>
      <div className="links">
        {pedido.linkedin?.includes("https://www.linkedin.com/") && (
          <DSLink newTab external to={pedido.linkedin}>
            LinkedIn
          </DSLink>
        )}
        {pedido.cnpjNoMask?.length === 14 && (
          <DSLink
            newTab
            external
            to={`https://cnpjs.rocks/cnpj/${pedido.cnpjNoMask}`}
          >
            CNPJ.Rocks
          </DSLink>
        )}
      </div>
      <AnimatePresence>
        {!criandoTeste ? (
          <S.FooterAprova
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            layout
            key="aprova"
          >
            <DSButton onClick={apagaTeste} secondary color="text" bg="dangerLight">
              Apagar
            </DSButton>
            <DSButton onClick={() => setCriandoTeste(true)}>
              Aprovar teste
            </DSButton>
          </S.FooterAprova>
        ) : (
          <S.FooterCria
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            layout
            key="confirma"
          >
            <div className="body-cria">
              <Dropdown
                label="Plano"
                defaultValue={planos[0]}
                options={planos}
                onChange={(selectedOption) => {
                  if (selectedOption.value === "Personalizado")
                    setPlanoPersonalizado(true);
                  else setPlanoPersonalizado(false);
                  setSelectedPlano(selectedOption.value);
                }}
              />
              {planoPersonalizado ? (
                <>
                  <S.AddUsuarios>
                    <DSFormInput
                      required
                      mt={24}
                      short
                      width={160}
                      label="Qtd. usuários"
                      {...bindQtdUsuarios}
                    />
                    {!usuariosLeitores && (
                      <DSButton
                        mt={24}
                        secondary
                        color="primary"
                        bg="primaryLight"
                        small
                        onClick={() => setUsuariosLeitores(true)}
                      >
                        Adicionar usuários leitores
                      </DSButton>
                    )}
                  </S.AddUsuarios>
                  {usuariosLeitores && (
                    <S.AddUsuarios>
                      <DSFormInput
                        required
                        mt={24}
                        short
                        width={160}
                        label="Qtd. usuários leitores"
                        {...bindQtdUsuariosLeitores}
                      />
                      <DSButton
                        mt={24}
                        secondary
                        color="danger"
                        bg="grayLight"
                        small
                        onClick={() => setUsuariosLeitores(false)}
                      >
                        Cancelar
                      </DSButton>
                    </S.AddUsuarios>
                  )}
                  <DSFormInput
                    required
                    mt={24}
                    short
                    width={160}
                    label="Capacidade BD em GB"
                    {...bindCapacidadeBd}
                  />
                </>
              ) : (
                <>
                  <div className="info">
                    <p>Quantidade de usuários:</p>
                    <p>{selectedPlano.usuarios} usuários</p>
                  </div>
                  <div className="info">
                    <p>Capacidade máxima:</p>
                    <p className="extra-margin">{selectedPlano.capacidade}GB</p>
                  </div>
                </>
              )}
              {editingData ? (
                <div className="editData">
                  <DSInput
                    short
                    label="Duração do teste em dias"
                    placeholder="15"
                    color="primary"
                    {...bindQtdDiasTeste}
                  >
                    <DSButton
                      size="small"
                      onClick={() => {
                        setEditingData(false);
                      }}
                    >
                      Confirmar
                    </DSButton>
                  </DSInput>
                </div>
              ) : (
                <div className="info-edit">
                  <p>Duração:</p>
                  <p>{qtdDiasTeste} dias</p>
                  <S.EditButton
                    onClick={() => setEditingData(true)}
                    title="Editar duração do teste"
                  >
                    <EditIcon />
                  </S.EditButton>
                </div>
              )}
              <div className="info no-margin">
                <p>Teste válido até:</p>
                <p>{dataExpiraTeste}</p>
              </div>
              <div className="flex-nao-criar">
                {editingNomeBanco ? (
                  <div style={{ marginTop: 16 }}>
                    <DSInput
                      short
                      label="Nome do banco"
                      placeholder="Banco de ..."
                      color="primary"
                      className
                      {...bindNomeBancoValue}
                    >
                      <DSButton
                        size="small"
                        onClick={() => {
                          setEditingNomeBanco(false);
                        }}
                      >
                        Confirmar
                      </DSButton>
                    </DSInput>
                  </div>
                ) : (
                  <>
                    <div className="info-edit">
                      <p>Nome do banco:</p>
                      {criarBanco ? (
                        <>
                          <p title={nomeBancoValue}>{nomeBancoValue}</p>
                          <S.EditButton
                            onClick={() => {
                              setEditingNomeBanco(true);
                            }}
                            title="Editar nome do banco"
                          >
                            <EditIcon />
                          </S.EditButton>
                        </>
                      ) : (
                        <p title="Sem banco">-</p>
                      )}
                    </div>
                    <DSButton
                      onClick={() => setCriarBanco(!criarBanco)}
                      secondary
                      color="text"
                      bg="primaryLight"
                    >
                      {criarBanco ? "Não criar banco" : "Criar banco"}
                    </DSButton>
                  </>
                )}
              </div>
            </div>
            <div className="footer">
              <DSButton
                onClick={() => setCriandoTeste(false)}
                secondary
                color="text"
                bg="grayLight"
              >
                Cancelar
              </DSButton>
              <DSButton onClick={criaTeste}>{carregandoTeste ? <LoadingIcon fill="white" /> : "Criar teste"}</DSButton>
            </div>
          </S.FooterCria>
        )}
      </AnimatePresence>
    </S.Pedido>
  );
}
