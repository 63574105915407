import React from "react";

import * as S from "./style";

export default function Checkbox({
  className,
  mt,
  mb,
  label,
  size = "default",
  checked,
  bg = "primary",
  disabled,
  ...props
}) {
  return (
    <S.Checkbox
      $checked={true}
      $size={size}
      className={className}
      $mt={mt}
      $mb={mb}
      $bg={bg}
      disabled={disabled}
    >
      <label>
        <S.CheckboxContainer>
          <S.HiddenCheckbox checked={checked} disabled={disabled} {...props} />
          <S.StyledCheckbox checked={checked} $size={size} $bg={disabled ? "textGray" : bg}>
            <S.Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </S.Icon>
          </S.StyledCheckbox>
        </S.CheckboxContainer>
        <span>{label}</span>
      </label>
    </S.Checkbox>
  );
}
