import React from 'react';

import * as S from "./style";

const LoadingIcon = props => (
    <S.StyledLoadingIcon fill={props.fill} size={props.size}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g transform="translate(-636 -312)"><path d="M-2090.024,2450.677h0l-2.611-1.507A8.933,8.933,0,0,0-2091,2444a9.01,9.01,0,0,0-9-9v-3a11.922,11.922,0,0,1,8.486,3.516A11.92,11.92,0,0,1-2088,2444a11.921,11.921,0,0,1-2.024,6.676Z" transform="translate(2748 -2120)" /><g transform="translate(636 312)" fill="none" stroke="rgba(112,112,112,0)" strokeWidth="1"><circle cx="12" cy="12" r="12" stroke="none"/><circle cx="12" cy="12" r="11.5" fill="none"/></g></g></svg>
    </S.StyledLoadingIcon>
);

export default LoadingIcon;