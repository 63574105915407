import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
import GerenciaTokenCliente from "../../services/config/GerenciaTokenCliente";
import { store } from "react-notifications-component";

import "animate.css";
import {
  ToastNotification,
  notificationTopCenter,
  notificationTopRight,
} from "../ToastNotification";

import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";
import { ReactComponent as CancelIcon } from "../../icons/cancel-icon.svg";

import LoadingIcon from "../LoadingIcon";

// @ts-ignore
import pLimit from "p-limit";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "rgb(187, 186, 186)";
};

const DropzoneContainer = styled.div`
  width: 100%;

  .zip {
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
  }
`;

const FilesList = styled.ul`
  box-sizing: border-box;
  margin-top: 1em;
  max-height: 250px;
  overflow: auto;
  word-wrap: break-word;

  li {
    padding: 1em;
    margin-bottom: 0.5em;
    background: lightgray;
    border-radius: 3px;
  }
`;

const FileUploadProgress = styled.div`
  margin-top: 1rem;
  display: flex;

  p {
    margin-left: 1rem;
  }
`;

const StyledDropzone = styled.div`
  min-height: 360px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px dashed #707070;
  border-color: ${(props) => getColor(props)};
  margin-top: 1.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  padding: 1em;
  color: #47484c;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 1;
  transition: all 200ms ease-in-out;

  svg.drop-img {
    height: 3rem;
    fill: ${(props) => props.theme.gray};
    margin-bottom: 1rem;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    background-color: ${(props) => props.theme.grayLight3};
    color: ${(props) => props.theme.primary};

    svg.drop-img {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

const OperacaoEmAndamento = styled.div`
  height: 360px;
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  display: flex;
  align-items: center;

  p {
    font-size: 1.125rem;
  }
`;

const ArquivosComErro = styled.div`
  color: ${(props) => props.theme.text};
  text-align: center;

  p {
    font-size: 1.2rem;
  }
`;

const ArquivosErroList = styled.div``;

let _isMounted = false;

function FileDropzone(props) {
  const maxSize = 1048576;
  const [arquivosCarregados, setCarregados] = useState(0);
  const [arquivosTotais, setTotais] = useState(0);
  const [terminado, setTerminado] = useState(false);
  const [errosList, setErrosList] = useState([]);
  const [mostrarLista, setMostrarLista] = useState(false);

  useEffect(function () {
    _isMounted = true;

    return function cleanup() {
      _isMounted = false;
    };
  }, []);

  let sendData = () => {
    props.parentCallBack((arquivosCarregados / arquivosTotais) * 100);
    let progress = (arquivosCarregados / arquivosTotais) * 100;
    //console.log("carregados------------------" + arquivosCarregados);
    //console.log("totais------------------" + arquivosTotais);
    //console.log("------------------" + progress);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    //console.log(acceptedFiles);
    if (_isMounted) {
      setTotais(acceptedFiles.length);
    }
    if (rejectedFiles.length > 0) {
      // alert(rejectedFiles.map((rejectedFile) => rejectedFile.name));
    }
    // acceptedFiles.forEach((file) => {
    let contadorArquivosUpados = 0;
    GerenciaTokenCliente.getUserToken()
      .then(async (token) => {
        //      console.log("----------------------------------------------" + token);

        //  let tamanho_lote = 0;
        //let max_lote = 25;
        let lote = [];
        let tamanhoTotal = 0;
        for (let file of acceptedFiles) {
          tamanhoTotal += file.size;
          lote.push({ file, token, tries: 0, uploading: false });
        }

        let resp = await executaPromise(
          lote,
          contadorArquivosUpados,
          tamanhoTotal
        );
        let { invalidos, conflito, operacao } = resp;
        // console.log(resp);
        setErrosList([...invalidos, ...conflito]);

        setTerminado(true);

        if (operacao.length > 0) {
          let erro = "Já há uma operação em andamento.";
          let texto =
            operacao.length < 5
              ? "Não foi possível fazer upload dos arquivos " +
                operacao.join(", ") +
                ". " +
                erro
              : "Diversos arquivos não puderam ser carregados. " + erro;
          notifica(texto, true);
        }
        if (invalidos.length > 0) {
          let erro =
            "Verifique se os arquivos são válidos ou tente novamente mais tarde.";
          let texto =
            invalidos.length < 5
              ? "Não foi possível fazer upload dos arquivos " +
                invalidos.join(", ") +
                ". " +
                erro
              : "Diversos arquivos não puderam ser carregados. " + erro;
          notifica(texto, true);
        }

        if (conflito.length > 0) {
          let erro =
            "Os arquivos já estão nos seus Arquivos Armazenados ou estão sendo carregados como tipo errado.";
          let texto =
            conflito.length < 5
              ? "Não foi possível fazer upload dos arquivos " +
                conflito.join(", ") +
                ". " +
                erro
              : "Diversos arquivos não puderam ser carregados. " + erro;
          notifica(texto, true);
        }

        if (
          conflito.length === 0 &&
          invalidos.length === 0 &&
          operacao.length === 0
        ) {
          notifica(
            "Os arquivos foram adicionados ao Storage com sucesso!",
            false
          );
        } else {
          notifica(
            "Upload finalizado. Alguns dos arquivos não foram adicionados ao storage.",
            false
          );
        }
      })
      .catch((err) => {
        // console.log(
        //   "-----------------sem token-----------------------------" + err
        // );
        // console.log(err);
      });
    // reader.readAsText(file);
  }, []);

  const uploadArquivo = function(obj) {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      if (obj.uploading) {
        //   console.log("tá upando e tentou dnv");
        return resolve(obj);
      }
      /* xhr.open(
        "POST",
        `${process.env.REACT_APP_URL_KUBERNETS}/api/comum/arquivos`
      );

//      console.log(process.env.MODULO_ARQUIVOS_URL);*/

      xhr.open("POST", process.env.REACT_APP_UPLOAD_URL);

      xhr.setRequestHeader("tipo-arquivo", props.arquivo);
      xhr.setRequestHeader("nome-arquivo", obj.file.name);
      xhr.setRequestHeader("Authorization", "Bearer " + obj.token);
      xhr.onerror = function () {
        obj.tryAgain = true;
        obj.uploading = false;
        obj.tries += 1;
        return resolve(obj);
      };

      xhr.ontimeout = function () {
        obj.tryAgain = true;
        obj.uploading = false;
        obj.tries += 1;
        return resolve(obj);
      };

      xhr.onabort = function () {
        obj.tryAgain = true;
        obj.uploading = false;
        obj.tries += 1;
        return resolve(obj);
      };

      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          let percentage = (e.loaded / e.total) * 100;
          if (percentage === 100) {
            //  console.log("arquivo " + obj.file.name + " finalizado");
          }
          /*     if (e.loaded === e.total) {
            setTimeout(() => {
              contadorArquivosUpados += 1;
              setCarregados(contadorArquivosUpados);
              obj.sucesso = true;
              obj.tryAgain = false;
              return resolve(obj);
            }, 5000);
          }*/
        }
      };

      xhr.onload = function () {
        obj.uploading = false;
        try {
          let resp = JSON.parse(xhr.responseText);
          // console.log(resp);
          if (resp.statusCode === 201) {
            obj.sucesso = true;
            obj.tryAgain = false;
            return resolve(obj);
          } else if (resp.statusCode === 400) {
            obj.extensao = true;
            obj.tryAgain = false;
            return resolve(obj);
          } else if (resp.statusCode === 409) {
            if (resp.status === "Já há uma operação em andamento") {
              obj.tryAgain = false;
              obj.operacao = true;
              return resolve(obj);
            } else {
              obj.conflito = true;
              obj.tryAgain = false;
              return resolve(obj);
            }
          } else if (resp.statusCode === 403) {
            reject("Limite");
          } else if (resp.statusCode === 500) {
            obj.tryAgain = true;
            obj.tries += 1;
            return resolve(obj);
          } else {
            obj.tryAgain = true;
            obj.tries += 1;
            return resolve(obj);
          }
        } catch (error) {
          //    console.log("erro de parse", error);
          obj.tryAgain = true;
          obj.tries += 1;
          return resolve(obj);
        }
      };
      obj.uploading = true;
      xhr.send(obj.file);
    });
  }

  const executaPromise = async function (
    lote,
    contadorArquivosUpados,
    tamanhoTotal
  ) {
    let invalidos = [];
    let conflito = [];
    let operacao = [];
    let limit = tamanhoTotal / lote.length < 2000000 ? pLimit(20) : pLimit(1);

    let i = 0;
    while (lote.length > 0) {
      let entrada = lote.map((l) => limit(async () => {
        let resp = await uploadArquivo(l);
        if(resp.conflito || resp.sucesso) setCarregados(++i);
        return resp;
      }));

      let results = await Promise.all(entrada);

      let tryAgain = [];
      for (let result of results) {
        if (result.uploading) continue;
        if (result.tries > 4) {
          invalidos.push(result.file.name);
        } else if (result.tryAgain) {
          tryAgain.push(result);
        } else if (result.conflito) {
          conflito.push(result.file.name);
        } else if (result.invalido || result.extensao) {
          invalidos.push(result.file.name);
        } else if (result.operacao) {
          operacao.push(result.file.name);
        }
      }

      lote = tryAgain;
    }
    return { invalidos, conflito, operacao };
  };

  const notifica = function (texto, erro) {
    store.addNotification({
      content: (
        <ToastNotification bg={erro ? "secondary" : "primary"}>
          {texto}{" "}
        </ToastNotification>
      ),
      onRemoval: (id, removedBy) => console.log(""),
      ...notificationTopRight,
    });
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    // accept: ".txt,text/plain",
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <DropzoneContainer>
      {acceptedFiles.length > 0 && (
        /* <FilesList>
                    {acceptedFiles.map(acceptedFile => (
                        <li>
                            {acceptedFile.name}
                        </li>
                    ))}
                </FilesList> */
        <FileUploadProgress>
          {terminado && arquivosCarregados < arquivosTotais ? (
            <CancelIcon className="icon" />
          ) : arquivosCarregados === arquivosTotais ? (
            <AcceptIcon className="icon" />
          ) : (
            <LoadingIcon />
          )}
          <p>
            Arquivos armazenados {arquivosCarregados} de {arquivosTotais}...
          </p>
        </FileUploadProgress>
      )}
      {!props.temOperacao ? (
        <>
          <StyledDropzone
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <svg
              className="drop-img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 38.272"
            >
              <path
                id="Union_2"
                data-name="Union 2"
                d="M-2088.149,2469.842v-23.15l-8.209,8.191-1.582-1.581,10.916-10.916,10.916,10.916-1.582,1.581-8.209-8.191v23.15Zm-3.74-8.271H-2100a11.8,11.8,0,0,1-4.677-.938,12.1,12.1,0,0,1-3.82-2.566,12.117,12.117,0,0,1-2.566-3.821,11.817,11.817,0,0,1-.938-4.677,11.813,11.813,0,0,1,.938-4.676,12.127,12.127,0,0,1,2.566-3.82,12.11,12.11,0,0,1,3.82-2.566,11.814,11.814,0,0,1,4.677-.938,11.661,11.661,0,0,1,2.179.21,14.161,14.161,0,0,1,2.168-2.613,13.2,13.2,0,0,1,2.684-1.958,13.4,13.4,0,0,1,3.094-1.219,13.6,13.6,0,0,1,3.375-.422,13.276,13.276,0,0,1,4.909.915,13.485,13.485,0,0,1,4.091,2.52,13.686,13.686,0,0,1,2.941,3.808,13.97,13.97,0,0,1,1.488,4.758,8.949,8.949,0,0,1,3.528.7,9.271,9.271,0,0,1,2.883,1.91,8.985,8.985,0,0,1,1.945,2.848,8.8,8.8,0,0,1,.716,3.54,8.785,8.785,0,0,1-.7,3.5,9.134,9.134,0,0,1-1.933,2.859,9.094,9.094,0,0,1-2.871,1.933,8.811,8.811,0,0,1-3.492.7h-9.381v-3H-2073a5.87,5.87,0,0,0,2.332-.468,6.111,6.111,0,0,0,1.91-1.29,6.07,6.07,0,0,0,1.289-1.91,5.846,5.846,0,0,0,.469-2.332,5.853,5.853,0,0,0-.469-2.332,6.075,6.075,0,0,0-1.289-1.911,6.1,6.1,0,0,0-1.91-1.289,5.867,5.867,0,0,0-2.332-.469h-3v-1.5a10.3,10.3,0,0,0-.82-4.09,10.618,10.618,0,0,0-2.25-3.34,10.64,10.64,0,0,0-3.34-2.25,10.32,10.32,0,0,0-4.09-.82,10.264,10.264,0,0,0-3.164.492,10.543,10.543,0,0,0-2.8,1.383,10.56,10.56,0,0,0-2.262,2.145,10.646,10.646,0,0,0-1.571,2.8,8.685,8.685,0,0,0-3.7-.821,8.789,8.789,0,0,0-3.5.7,9.178,9.178,0,0,0-2.859,1.933,9.122,9.122,0,0,0-1.932,2.871,8.8,8.8,0,0,0-.7,3.492,8.776,8.776,0,0,0,.7,3.5,9.165,9.165,0,0,0,1.932,2.859,9.165,9.165,0,0,0,2.859,1.933,8.793,8.793,0,0,0,3.5.7h8.112v3h0Z"
                transform="translate(2112 -2431.57)"
              />
            </svg>
            {!isDragActive &&
              "Clique ou arraste arquivos aqui para fazer o upload"}
            {isDragActive && "Solte os arquivos aqui"}
          </StyledDropzone>
          {/* <ArquivosComErro>
            <p>Mostrar arquivos</p>
            <ChevronIcon
              onClick={() => {
                setMostrarLista(!mostrarLista);
              }}
            />
            {mostrarLista && (
              <ArquivosErroList>
                {errosList.map((arquivo) => (
                  <p>{arquivo}</p>
                ))}
              </ArquivosErroList>
            )}
          </ArquivosComErro> */}
        </>
      ) : (
        <OperacaoEmAndamento>
          <p>
            Não é possível subir arquivos agora pois tem uma operação em
            andamento
          </p>
        </OperacaoEmAndamento>
      )}
    </DropzoneContainer>
  );
}

export default FileDropzone;
