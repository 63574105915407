import React from "react";
import * as S from "./style";

export default function Input({
  className,
  negative,
  children,
  big,
  short,
  small,
  center,
  placeholder,
  mt,
  mb,
  error,
  errorMsg,
  color = "primary",
  label,
  ...props
}) {
  return (
    <>
    {label && <S.Label>{label}</S.Label>}
      <S.Input
        className={className}
        $big={big}
        $short={short}
        $negative={negative}
        $center={center}
        $color={color}
        $mt={mt}
        $mb={mb}
        {...props}
      >
        <input placeholder={placeholder} type="text" {...props} />
        {children}
      </S.Input>
      {error && <S.Error>{errorMsg}</S.Error>}
    </>
  );
}
