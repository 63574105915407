import moment from "moment";
import React, { useState, forwardRef, useEffect } from "react";
import OperacaoService from "../../services/OperacaoService";
import Table from "../@DesignSystem/Table";
import { ReactComponent as BlockIcon } from "../../icons/block.svg";
import { store } from "react-notifications-component";
import {
  ToastNotification,
  notificationTopRight,
} from "../../components/ToastNotification"

const Suporte = forwardRef((props, ref) => {
  const [operacoes, setOperacoes] = useState([]);

  const colunas = [
    {
      Header: " ",
      acessor: "id",
      stretch: 1,
      class: "tooltip",
      maxWidth: 0,
    },
    {
      Header: "Email",
      accessor: "email",
      stretch: 2,
      class: "tooltip",
      maxWidth: 900,
    },
    {
      Header: "Operação",
      accessor: "operacao",
      stretch: 2,
      class: "tooltip",
      maxWidth: 90,
    },
    {
      Header: "Status",
      accessor: "status",
      stretch: 2,
      maxWidth: 600,
    },
    {
      Header: "Situação",
      accessor: "situacao",
      stretch: 2,
      class: "tooltip",
      maxWidth: 600,
    },
    {
      Header: "Início",
      accessor: "data_inicio",
      stretch: 2,
      class: "tooltip",
      maxWidth: 600,
    },
    {
      Header: " ",
      accessor: "btn",
      stretch: 2,
      maxWidth: 600,
    },
  ];

  const atualizarOperacao = (id) => {
    OperacaoService.atualizaOperacao(props.tokenLogin, id)
      .then((res) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Operação finalizada com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível finalizar a operação.
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  useEffect(() => {
    OperacaoService.getOperacoes(props.tokenLogin).then((resp) => {
      let operacoes = resp.data.operacoes.map((op) => {
        let status = "Em andamento";
        if (op.status == 2) status = "Finalizada com sucesso";
        if (op.status == 3) status = "Finalizada com erro";

        let data_inicio = moment(op.createdAt).calendar();
        return {
          banco: op.usuario,
          email: op.email,
          situacao: op.msg,
          operacao: op.tipo,
          status: status,
          data_inicio: data_inicio,
          btn: (
            <button onClick={()=>atualizarOperacao(op.id_operacao)}>
              <BlockIcon color="#202124"></BlockIcon>
            </button>
          ),
        };
      });

      setOperacoes(operacoes);
    });
  }, []);

  return (
    <>
      <Table data={operacoes} propsColumns={colunas}></Table>
    </>
  );
});

export default Suporte;
