import { motion } from "framer-motion";
import styled from "styled-components";

export const CriaVenda = styled(motion.div)`
  background: #ffffff;
  border: 1px solid #eceff4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 32px;
  width: 800px;
  margin: 40px 0;
  display: grid;
  gap: 32px 48px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "header header"
    "dados plano"
    "config config"
    "footer footer";

  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    grid-area: header;

    h4 {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .dados {
    grid-area: dados;
  }

  .plano {
    grid-area: plano;

    .info {
      display: flex;
      margin-top: 16px;
      align-items: center;

      p {
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.65);
      }

      p:last-child {
        margin-left: 8px;
        font-weight: 600;
      }

      p.extra-margin {
        margin-left: 34px;
      }
    }

    .data-contrato {
      margin: 40px 0;
    }

    .tipoBanco {
      margin-bottom: 32px;

      .flexBanco {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p.titleBanco {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: ${(p) => p.theme.text};
          margin-right: 16px;
        }
      }
    }
  }

  .info-edit {
    display: flex;

    p:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: ${(p) => p.theme.text};
      flex-shrink: 0;
    }

    p:nth-child(2) {
      margin-left: 8px;
      flex-shrink: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .duracao {
    margin: 32px 0;

    p {
      font-weight: 600;
    }
  }

  input[type="date"] {
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.primary};
    margin-left: 8px;
  }

  .config {
    grid-area: config;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    background: ${(p) => p.theme.background};
    padding: 12px 20px;
    border-radius: 8px;
    margin: 8px 24px;
  }

  .footer {
    grid-area: footer;
    justify-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
`;

export const EditButton = styled.button`
  border: none;
  background: #dcebf7;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${(p) => p.theme.primary};
  cursor: pointer;
  padding: 0;
  margin-left: 16px;
  flex-shrink: 0;
`;

export const Avancado = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${(p) => p.theme.primary};
  background: none;
  border: none;
  margin: 8px 0;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  grid-column: 1 / span 3;
  justify-self: start;

  svg {
    transform: ${(p) =>
      p.$isOpened
        ? "rotate(90deg) translateX(4px)"
        : "rotate(-90deg) translateX(-4px)"};
    margin-right: 8px;
  }
`;

export const AddUsuarios = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  button {
    margin-left: 32px;
  }
`;

export const ContasPersonalizadas = styled.div`
  padding: 12px;
  margin-bottom: 24px;

  h5 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
  }

  .flex {
    display: flex;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }
`;

export const Pesquisando = styled.div`
  background: ${(p) => p.theme.background};
  border-radius: 8px;
  height: 340px;
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    text-align: center;

    p {
      margin-bottom: 8px;
    }
  }
`;

export const ClienteCadastrado = styled.div`
  background: ${(p) => p.theme.background};
  border-radius: 8px;
  height: 328px;
  margin: 16px 0;
  box-sizing: border-box;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: start;

  p {
    margin-top: 8px;

    span {
      font-weight: 600;
    }
  }

  p.color {
    color: ${(p) => p.theme.primary};
    font-weight: 600;
    margin-top: auto;
  }
`;

export const ModalRenova = styled.div`
  h5 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  h5 + p {
    font-size: 18px;
    line-height: 24px;
  }

  .buttons {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
