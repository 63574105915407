import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArquiveiService from "../../services/ArquiveiService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../@DesignSystem/Button";
import Dropdown from "../@DesignSystem/Dropdown";
import moment from "moment";
import ArquivosService from "../../services/ArquivosService";

const Wrapper = styled.div`
  p.legenda {
    font-size: 14px;
    color: ${(p) => p.theme.textDarkGray};
    margin-top: 32px;
  }

  .notFound {
    padding: 16px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 32px;
    background-color: #f8f9fa;

    p.info {
      font-size: 16px;
      margin-top: 64px;
      padding: 0 48px;
      text-align: center;
    }
  }

  .upload {
    min-height: 320px;
    padding: 20px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 32px;
    background-color: #f8f9fa;

    p.step {
      margin-top: 48px;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 600;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;

const DateDiv = styled.div`
  display: flex;
  gap: 32px;
`;

const DateWrapper = styled.div`
  label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(p) => p.theme.text};
  }

  &:focus-within {
    label {
      color: ${(p) => p.theme.primary};
    }
  }

  input[type="date"] {
    display: block;
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.input.border};

    &:active {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }

    &:focus {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }
  }
`;

// const mockResp = {
//   statusCode: 200,
//   msg: "Operação sucedida",
//   descricao: "Credenciais selecionados com sucesso",
//   credenciais: [
//     {
//       id: "174196b0-ff44-46b8-8ea5-a9702f58d231",
//       nome: "Credenciais Teste",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "732f5777-7156-47b6-ba18-73dd3f26c05a",
//       nome: "Credencial Teste",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "96411d0f-5ae2-45f6-a4c0-af5cb755a7f1",
//       nome: "Credencial Teste 4",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "ab2ffb9e-857b-46db-94a2-4deb8225ffef",
//       nome: "Credencial Teste 3",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "cf4647f3-aabd-4933-8b5e-b6bef595904a",
//       nome: "Credencial Teste 2",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//   ],
// };

const UploadComArquivei = ({
  tokenLogin,
  arquivo,
  toggleUploadModal,
  checkOperacao,
}) => {
  const history = useHistory();
  const [credenciais, setCredenciais] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCredencial, setSelectedCredencial] = useState(null);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const getCredenciais = () => {
    setIsLoading(true);
    // API call
    ArquiveiService.getCredenciais(tokenLogin)
      .then((response) => {
        console.log(response);
        setCredenciais(response.data.credenciais);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCredenciais([]);
        setIsLoading(false);
      });
  };

  const handleDateChange = (e) => {
    let date = moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY");
    if (e.target.name === "dtIni") {
      setDataIni(date);
    } else if (e.target.name === "dtFim") {
      setDataFim(date);
    }
  };

  const carregarArquivos = () => {
    console.log(dataIni, dataFim, selectedCredencial, arquivo, tokenLogin);
    ArquivosService.importarArquivosArquivei(
      dataIni + "00:00",
      dataFim + "23:59",
      selectedCredencial.value,
      arquivo,
      tokenLogin
    )
      .then((res) => {
        console.log(res);
        checkOperacao();
        toggleUploadModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCredenciais();
  }, []);

  return (
    <Wrapper>
      {credenciais.length === 0 ? (
        <div className="notFound">
          <p className="info">
            Você não possui credenciais cadastradas. Crie uma credencial de API
            da Arquivei para fazer o upload de arquivos.
          </p>
          <Button
            mt={16}
            mb={64}
            center
            onClick={() => history.push("/configuracoes#arquivei")}
          >
            Criar nova credencial
          </Button>
        </div>
      ) : (
        <div className="upload">
          <p className="step">
            1. Selecione a credencial que deseja utilizar para fazer o upload.
          </p>
          <Dropdown
            name="select-credencial"
            label="Credencial Arquivei"
            className="dropdown"
            placeholder="Selecione uma credencial"
            onChange={(selectedOption) => setSelectedCredencial(selectedOption)}
            options={credenciais.map((credencial) => ({
              value: credencial.nome,
              label: credencial.nome,
            }))}
            isLoading={isLoading}
            noOptionsMessage={() => "Nenhuma credencial encontrado"}
          />
          <p className="step">
            2. Selecione a data que os arquivos foram incluídos na Arquivei
          </p>
          <DateDiv>
            <DateWrapper>
              <label>
                Data Início
                <input
                  name={`dtIni`}
                  type="date"
                  onChange={(e) => handleDateChange(e)}
                />
              </label>
            </DateWrapper>
            <DateWrapper>
              <label>
                Data Fim
                <input
                  max={moment().format("YYYY-MM-DD")}
                  name={`dtFim`}
                  type="date"
                  onChange={(e) => handleDateChange(e)}
                />
              </label>
            </DateWrapper>
          </DateDiv>
          <Button
            mt={48}
            onClick={() => {
              if (selectedCredencial && dataIni && dataFim) {
                carregarArquivos();
              }
            }}
          >
            Importar arquivos
          </Button>
        </div>
      )}
      <p className="legenda">
        Arquivos aceitos pela API da Arquivei{" "}
        <b>NFe Entrada, NFe Saída, CFe, CTe e Evento NFe.</b>
      </p>
    </Wrapper>
  );
};

export default UploadComArquivei;
