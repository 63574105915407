import React, { useState } from "react";
import styled from "styled-components/macro";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "../ToastNotification";

import { useInput } from "../../hooks/useInput";

import UsuarioService from "../../services/UsuarioService";
import { verificaSenha } from "../../helpers/verificar-senha";

import DSFormInput from "../@DesignSystem/FormInput";
import DSButton from "../@DesignSystem/Button";

import RequisitosSenha from "../RequisitosSenha";

const StyledH4 = styled.h4`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  margin-top: 3rem;
`;

const StyledForm = styled.div`
  background: none;
  max-width: 316px;
`;

export default function MudarSenha(props) {
  const {
    value: password,
    bind: bindPassword,
    reset: resetPassword,
  } = useInput("", "");
  const {
    value: newPassword,
    bind: bindNewPassword,
    reset: resetNewPassword,
  } = useInput("", "");

  const {
    value: newPasswordConfirm,
    bind: bindNewPasswordConfirm,
    reset: resetNewPasswordConfirm,
  } = useInput("", "");

  const [senhasIguais, setSenhasIguais] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [senhasNovasDiferentes, setSenhasNovasDiferentes] = useState(false);
  
  const handleClick = () => {
    if (!verificaSenha(newPassword).ehForte) {
      setInvalidNewPassword(true);
      setIncorrectPassword(false);
      return;
    }
    if (password === newPassword) {
      // console.log("Mudar Senha")
      setSenhasIguais(true);
      setInvalidNewPassword(true);
      setIncorrectPassword(false);
      return;
    } 
    if(newPassword !== newPasswordConfirm){
      setSenhasNovasDiferentes(true);
      return;
    }
    setSenhasIguais(false);
    setInvalidNewPassword(false);
    setSenhasNovasDiferentes(false);
    
    UsuarioService.alteraSenha(props.tokenLogin, password, newPassword)
      .then((resp) => {
        //console.log(resp);
        setIncorrectPassword(false);

        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Senha alterada com sucesso!{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        //console.log(err);
        setIncorrectPassword(true);
      });
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      return handleClick();
    }

    setInvalidNewPassword(false);
    setSenhasNovasDiferentes(false);
  };

  return (
    <>
      <StyledH4>Mudar senha:</StyledH4>
      <StyledForm>
        <DSFormInput
          type="email"
          id="email"
          label="Email"
          errorMsg=""
          readonly
          required
          value={props.usuario}
        />
        <DSFormInput
          mt={24}
          type="password"
          id="senha"
          label="Senha Atual"
          errorMsg="Senha incorreta"
          error={incorrectPassword}
          required
          {...bindPassword}
        />
        <DSFormInput
          mt={24}
          type="password"
          id="novasenha"
          label="Nova senha"
          errorMsg={senhasIguais ? "A nova senha é igual a senha atual" : "Complete todos os requisistos de senha"}
          error={invalidNewPassword}
          required
          onKeyUp={handleKeyUp}
          {...bindNewPassword}
        />
        <RequisitosSenha password={newPassword} />
        <DSFormInput
          mt={24}
          type="password"
          id="novasenha"
          label="Confirme a nova senha"
          errorMsg={"As senhas não coincidem"}
          error={senhasNovasDiferentes}
          required
          onKeyUp={handleKeyUp}
          {...bindNewPasswordConfirm}
        />
        <DSButton mt={24} onClick={handleClick}>
          Mudar senha
        </DSButton>
      </StyledForm>
    </>
  );
}
