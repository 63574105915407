import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as CancelIcon } from "../../icons/cancel-icon.svg";

const NotificationBubbleStyled = styled.div`
    background: #FFFFFF;
    padding: 1em 1em .5em;
    border-radius: 0;
    position: relative;
    margin-bottom: 1em;


    /* //hover na notificacao */

    &:hover {
        button.dismiss {
            cursor: pointer;
            background: ${props => props.theme.danger};
            height: 48px;
            width: 48px;
            font-size: 1.5em;

            svg.icon {
                opacity: 1;
            }
        }
    }

    p {
        line-height: 1.3;
    }

    button.dismiss {
        background: ${props => (props.type === "error" ? props.theme.secondary : props.theme.primary)};
        border: none;
        width: 48px;
        height: 8px;
        font-size: 1em;
        position: absolute;
        top: 0;
        right: 0;
        color: #FFFFFF;
        overflow: hidden;
        transition: all 150ms ease;

        svg.icon {
            opacity: 0;
            margin-top: 6px;
        }

        /* //hover no block */

        /* &:hover {
            cursor: pointer;
            background: ${props => props.theme.danger};
            height: 48px;
            width: 48px;
            font-size: 1.5em;

            svg.icon {
                opacity: 1;
            }
        } */
    }

    div {
        display: flex;
        justify-content: space-between;
        font-size: .9em;
        margin-top: .75em;

        span {
            color: rgba(0, 0, 0, .65);
            text-align: right;
        }

        svg {
            color: ${props => props.type === 'error' ? props.theme.danger : props.theme.primary};
        }

    }

`

const NotificationBubble = props => (
    <NotificationBubbleStyled type={props.type}>
        <p>{props.children}</p>
        <div>
            <span>{props.tempo}</span>
        </div>
        <button onClick={props.handleClick} className="dismiss"><CancelIcon className="icon" /></button>
    </NotificationBubbleStyled>
);

export default NotificationBubble;