import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import * as S from "./style";

import { ReactComponent as MoreIcon } from "../../../icons/more_vert.svg";

export default function MoreButton(props) {
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  const showTip = () => setActive(true);
  const hideTip = () => setActive(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        hideTip();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <S.Wrapper $active={active} onClick={showTip}>
      <MoreIcon />
      <AnimatePresence>
        {active && (
          <S.Tooltip
            ref={ref}
            initial={{ opacity: 0, scale: 0.85, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: "-100%" }}
            exit={{ opacity: 0, y: "-100%" }}
          >
            {props.children}
          </S.Tooltip>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
}
