import React, { useState } from "react";

import { ReactComponent as ChevronIcon } from "../../icons/ChevronDown.svg";
import styled from "styled-components";

const Wrapper = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    .content {
      flex-grow: 1;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;

        button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            cursor: pointer;
            background: ${(props) => props.theme.branco};
            transition: all 0.3s ease;

            &:hover {
                background: ${(props) => props.theme.grayLight};
            }
        }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
      margin-top: 1rem;
      color: ${(props) => props.theme.text};
      cursor: pointer;
    }

    h5 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top 1.5rem;
        color: ${(props) => props.theme.text};
    }
`;

const Patch = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Wrapper>
      <div className="content">
        <h3 onClick={() => setIsCollapsed(() => !isCollapsed)}>Correções</h3>
        {!isCollapsed && (
          <>
            <h5>Nenhuma descrição adicionada</h5>
            <p></p>
          </>
        )}
      </div>
      <div className="button">
        <button onClick={() => setIsCollapsed(!isCollapsed)}>
          <ChevronIcon />
        </button>
      </div>
    </Wrapper>
  );
};

export default Patch;
