import React from "react";
import styled from "styled-components/macro";

import Button from "../Button";
import { FormInput } from "../FormInput";
import Modal from "../Modal";
import { CSSTransition } from "react-transition-group";

import LoadingIcon from "../../components/LoadingIcon";

import ProgressBar from "../ProgressBar";

import { store } from "react-notifications-component";
import {
  ToastNotification,
  notificationTopRight,
} from "../../components/ToastNotification";
import { Link as ScrollLink, animateScroll } from "react-scroll";

import { ReactComponent as SearchIcon } from "../../icons/search-icon.svg";
import moment from "moment";
import UsuarioService from "../../services/UsuarioService";
const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

const SidenavConfig = styled.div`
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: 56px;
  width: 320px;
  height: calc(100vh - 56px);
  border-right: 1px solid #d9dee0;
  flex-grow: 0;
  margin: -40px 4rem -40px -40px;
  padding: 0;

  @media (max-width: 1440px) {
    width: 240px;
  }

  h5 {
    font-size: 1.75rem;
    margin: 2.25rem 2rem 1.25rem;
  }

  a {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    color: ${(props) => props.theme.text};
    text-decoration: none;
    background: ${(props) => props.theme.grayLight};
  }
`;

const UserLinks = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  a {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.grayDark};
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    background: none;

    &:hover {
      color: ${(props) => props.theme.text};
      text-decoration: underline;
    }

    &.active {
      color: ${(props) => props.theme.text};
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;

const Section = styled.section`
  margin: 3rem 0 6rem;
  border: 1px solid ${(props) => props.theme.grayLight};
  background: ${(props) => props.theme.background};
  padding: 3rem 4rem;
  border-radius: 8px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 2.375rem;
    margin-bottom: 2rem;
  }

  .mini-section {
    position: relative;
    /* background: ${(props) => props.theme.grayLight};
    padding: 1rem;
    border-radius: 4px; */
  }

  .separator {
    height: 1px;
    margin: 4rem 0;
    background: ${(props) => props.theme.grayLight2};
  }
`;

const UsuariosContainer = styled.div`
  flex-grow: 1;
  h2 {
    font-size: 3.125rem;
  }
`;

const ConsultaUsuarios = styled.div`
  margin-top: 1rem;
  display: flex;
  div:first-child {
    margin-right: 1rem;
  }

  .btn-container {
    margin-top: 1rem;
  }
`;

const UsuarioVisualizacao = styled.div`
  margin-top: 4rem;
  h3.section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  div.user-header {
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 2rem;
    }
  }
`;

const LoadingBox = styled.div`
  padding-left: 8rem;
  padding-top: 4rem;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  svg {
    width: 3rem;
  }
`;

const UserEmail = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
`;

const MetricasUsuario = styled.div`
  margin-top: 2rem;

  p {
    margin-bottom: 1rem;
  }
`;

const MetricasBancos = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  div.progress-container {
    width: 200px;
  }
`;

const BancoDeDados = styled.div`
  &:first-child {
    margin-right: 4rem;
  }

  p {
    margin-bottom: 2rem;
  }
`;

const Dataset = styled.div`
  button {
    margin-top: 1rem;
  }
`;

const ModalContent = styled.div`
  p {
    line-height: 1.4;
    font-size: 1.25rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;

export class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      userEmail: "usuario@mail.com",
      progressBD: 80,
      progressStorage: 85,
      isModalOpen: false,
      modalContent: "",
      lastLogin: "",
      acessos: 0,
      confirmado: 0,
      emailError: false,
      errorMsg: "",
      permissao: "",
      loading: true,
    };
    this.pesquisaUsuario = this.pesquisaUsuario.bind(this);
    this.searchOnEnter = this.searchOnEnter.bind(this);
    this.reenviarEmailConfirmacao = this.reenviarEmailConfirmacao.bind(this);
  }

  toggleModal = (e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  checkEmail(email) {
    return email.match(/\S+@\S+\.\S+/);
  }

  toggleLimpaModal(content) {
    this.setState({
      modalContent: content,
    });
    this.toggleModal();
  }

  pesquisaUsuario() {
    let inputValue = document.querySelector("#usuarioId").value;
    if (!this.checkEmail(inputValue)) {
      this.setState({
        loading: false,
        errorMsg: "Email inválido",
        emailError: true,
        userId: null
      });
      return;
    }
    this.setState({ emailError: false, loading: true });
    this.getInformacoesUsuario(inputValue);
  }

  getInformacoesUsuario(email) {
    UsuarioService.getSessaoUsuarioPorEmail(email, this.props.tokenLogin)
      .then((res) => {
        let ultimaSessao = moment(res.data.ultimaSessao);
        let permissao =
          res.data.permissao.charAt(0).toUpperCase() +
          res.data.permissao.slice(1);
        this.setState({
          lastLogin:
            ultimaSessao < moment("01/01/2019")
              ? "Nunca"
              : ultimaSessao.format("DD/MM/YYYY HH:MM"),
          acessos: res.data.acessos,
          confirmado: res.data.confirmado,
          userId: email,
          userEmail: email,
          permissao: permissao === "Comum" ? "Escritor" : permissao,
          loading: false,
        });
        this.props.history.push(`/adm/usuarios/${email}`);
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          loading: false,
          errorMsg: "Usuário não encontrado",
          emailError: true,
          userId: null
        });
        //console.log(err);
      });
  }

  reenviarEmailConfirmacao() {
    if (!this.state.userEmail) return;
    UsuarioService.reenviarEmail(this.props.tokenLogin, this.state.userEmail)
      .then((res) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Email reenviado com sucesso.{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        let detalhes =
          err.response.status === 403
            ? "Usuário já confirmado."
            : "Usuário não encontrado.";

        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível enviar o email. {detalhes}{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  }

  limpaPesquisa = () => {
    this.setState({
      userId: "",
    });
    this.props.history.push(`/adm/usuarios/`);
  };

  searchOnEnter(e) {
    //console.log(e.key);
    if (e.key === "Enter") {
      this.pesquisaUsuario();
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({
      userId: id,
    });
    if (id) this.getInformacoesUsuario(id);
  }

  render() {
    return (
      <Wrapper>
        <SidenavConfig>
          <h5>Vendas</h5>
          <a
            className=""
            href="#consultar-usuario"
            onClick={() => animateScroll.scrollToTop()}
          >
            Consultar Usuario
          </a>
          {this.state.userId && (
            <UserLinks>
              <ScrollLink
                activeClass="active"
                to="info"
                spy={true}
                smooth={true}
                offset={-500}
                duration={500}
              >
                Informações
              </ScrollLink>
              {/* <ScrollLink
                activeClass="active"
                to="uso-de-dados"
                spy={true}
                smooth={true}
                offset={-400}
                duration={500}
              >
                Uso de dados
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="datasets"
                spy={true}
                smooth={true}
                offset={-350}
                duration={500}
              >
                Datasets
              </ScrollLink> */}
            </UserLinks>
          )}
        </SidenavConfig>
        <UsuariosContainer>
          <h2>Usuários</h2>
          <Section title="Consultar Usuários" id="consultar-usuario">
            <h3>Consultar Usuários</h3>
            <ConsultaUsuarios>
              <FormInput
                onKeyDown={this.searchOnEnter}
                id="usuarioId"
                required
                placeholder="Digite o email"
                type="text"
                size="medium"
                errorMsg={this.state.errorMsg}
                error={this.state.emailError}
              ></FormInput>
              <div class="btn-container">
                <Button
                  background="primary"
                  onClick={this.pesquisaUsuario}
                  class="pesquisar-btn"
                >
                  <SearchIcon className="icon" /> Pesquisar
                </Button>
                <Button
                  onClick={this.limpaPesquisa}
                  ml="1"
                  link
                  background="primary"
                >
                  Limpar consulta
                </Button>
              </div>
            </ConsultaUsuarios>
            <CSSTransition
              in={this.state.userId}
              timeout={250}
              classNames="topDownGrow"
              unmountOnExit
            >
              <UsuarioVisualizacao>
                {/* <div className="user-header">
                  <h3>Usuário#{this.state.userId}</h3>
                </div> */}
                <section title="Informações" id="info" className="mini-section">
                  <h3 className="section-title">Informações</h3>
                  {this.state.loading ? (
                    <LoadingBox>
                      <LoadingIcon big />
                    </LoadingBox>
                  ) : (
                    <></>
                  )}
                  <UserEmail>
                    <p className="email">
                      {this.state.userEmail}
                      {this.state.confirmado ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          (
                          <Button
                            onClick={this.reenviarEmailConfirmacao}
                            link
                            background="primary"
                          >
                            Reenviar email de confirmação
                          </Button>
                          )
                        </>
                      )}
                    </p>
                  </UserEmail>
                  <MetricasUsuario>
                    <div>
                      <p>Última visita: {this.state.lastLogin}</p>
                      <p>Número de acessos: {this.state.acessos}</p>
                      <p>
                        Email confirmado:{" "}
                        {this.state.confirmado == 1 ? "Sim" : "Não"}
                      </p>
                      <p>Permissão: {this.state.permissao}</p>

                      {/* <p>Última operação: </p>
                      <p>Última operação com sucesso: </p>
                      <p>Operação em andamento: </p> */}
                    </div>
                    {/* <Button link background="danger">
                      Encerrar operações em andamento
                    </Button>
                    <Button
                      block
                      mt="2"
                      background="danger"
                      onClick={() => this.toggleLimpaModal("resetCompleto")}
                    >
                      Reset Completo
                    </Button> */}
                  </MetricasUsuario>
                </section>
                <div className="separator"></div>
                {/* <section
                  title="Uso de dados"
                  id="uso-de-dados"
                  className="mini-section"
                >
                  <h3 className="section-title">Uso de dados</h3>
                  <MetricasBancos>
                    <BancoDeDados>
                      <h4>Banco de dados</h4>
                      <div className="progress-container">
                        <ProgressBar progress={this.state.progressBD} />
                      </div>
                      <p>1.5GB de 5GB do utilizados</p>
                      <Button background="danger">Apagar arquivo do BD</Button>
                      <Button
                        block
                        mt="1"
                        background="danger"
                        link
                        onClick={() => this.toggleLimpaModal("banco de dados")}
                      >
                        Limpar banco de dados
                      </Button>
                    </BancoDeDados>
                    <BancoDeDados>
                      <h4>Storage</h4>
                      <div className="progress-container">
                        <ProgressBar progress={this.state.progressStorage} />
                      </div>
                      <p>1.5GB de 5GB do utilizados</p>
                      <Button background="danger">
                        Apagar arquivo do Storage
                      </Button>
                      <Button
                        block
                        mt="1"
                        link
                        background="danger"
                        onClick={() => this.toggleLimpaModal("storage")}
                      >
                        Limpar Storage
                      </Button>
                    </BancoDeDados>
                  </MetricasBancos>
                  <Button background="primary" mt="2">
                    Concilar BD e Storage
                  </Button>
                </section>
                <div className="separator"></div>
                <section
                  title="Datasets"
                  id="datasets"
                  className="mini-section"
                >
                  <h3 className="section-title">Datasets</h3>
                  <Dataset>
                    <p>
                      Tamanho: <b>1.5GB</b>
                    </p>
                    <Button background="primary">
                      Forçar refresh no template
                    </Button>
                  </Dataset>
                </section> */}
                {this.state.isModalOpen && (
                  <Modal
                    id="modal"
                    isOpen={this.state.isModalOpen}
                    onClose={this.toggleModal}
                    modalSize="md"
                    modalTipo="default"
                  >
                    {this.state.modalContent === "resetCompleto" ? (
                      <ModalContent>
                        <p>
                          Tem certeza de que desejar fazer o reset completo
                          deste usuario?
                        </p>
                        <div className="buttons">
                          <Button background="gray" onClick={this.toggleModal}>
                            Cancelar
                          </Button>
                          <Button background="danger">Reset</Button>
                        </div>
                      </ModalContent>
                    ) : (
                      <ModalContent>
                        <p>
                          Tem certeza de que deseja limpar o{" "}
                          {this.state.modalContent}? Todos os dados serão
                          apagados.
                        </p>
                        <div className="buttons">
                          <Button background="gray" onClick={this.toggleModal}>
                            Cancelar
                          </Button>
                          <Button background="danger">Limpar</Button>
                        </div>
                      </ModalContent>
                    )}
                  </Modal>
                )}
              </UsuarioVisualizacao>
            </CSSTransition>
          </Section>
        </UsuariosContainer>
      </Wrapper>
    );
  }
}
