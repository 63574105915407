import styled from "styled-components";

export const Container = styled.div`
  margin-top: ${(p) => p.mt}px;
  margin-bottom: ${(p) => p.mb}px;
  box-sizing: border-box;

  svg {
    width: ${(p) => p.small ? "16px" : "24px"	};
    height: ${(p) => p.small ? "16px" : "24px"	};
  }

  p.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #909090;
  }
`;

export const Input = styled.div`
  display: flex;
  width:  ${(p) => p.small ? "120px" : "160px"	};
  padding: 2px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #dcdee0;
  border-radius: 0px 16px;

  span {
    font-weight: 700;
    font-size: ${(p) => (p.small ? "16px" : "20px")};
    line-height: ${(p) => (p.small ? "20px" : "28px")};
    color: ${(p) => p.theme.text};
    text-align: center;
  }
`;

export const Button = styled.button`
  background: ${(p) => p.theme.primary};
  border-radius: 0px 12px;
  width: ${(p) => (p.small ? "32px" : "42px")};
  height: ${(p) => (p.small ? "32px" : "42px")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.branco};
  font-size: ${(p) => (p.small ? "16px" : "20px")};
  border: none;
  cursor: pointer;
`;
