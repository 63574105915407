import styled from "styled-components";

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 100%;
`;
export const Tooltip = styled.div`
  background: white;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: 1000;
  width: 320px;
`;
