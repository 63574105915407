import React from "react";
import styled from "styled-components/macro";
import DSButton from "../@DesignSystem/Button";
import { ReactComponent as MSLogoIcon } from "../../icons/mssymbol.svg";
import { ReactComponent as Accept } from "../../icons/check_circle.svg";
import { ReactComponent as Reject } from "../../icons/cancel-circle.svg";
import Button from "../Button";
import { FormInput } from "../FormInput";

const DesativaTwofactorStyled = styled.div`
  padding: 1rem;

  h4 {
    font-size: 1.25rem;
  }

  div.flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  button {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

class DesativaTwofactor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      senhaValue: "",
      tokenValue: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeToken = this.handleChangeToken.bind(this);
  }

  handleChange(e) {
    this.setState({
      senhaValue: e.target.value,
    });
  }

  handleClick = () => {
    this.props.desativaTwofactor(this.state.senhaValue, this.state.tokenValue);
  };

  handleClickMsal = () => {
    //console.log(this.state.inputSenha);
    this.props.handleValidaocaoMsal(this.state.inputSenha, this.state.inputCodigo);
  };

  handleChangeToken(e) {
    this.setState({
      tokenValue: e.target.value,
    });
  }

  handleKeyUp = (event) => {
    if (event.key === "Enter") {
      this.handleClick();
    }
  };

  render() {
    return (
      <DesativaTwofactorStyled>
        <h4>
        {this.props.usuarioSso
                ? "Entre em sua conta da Microsoft/Google e informe"
                : "Digite abaixo sua senha e "}{" "} o código do Google Authenticator para desativar o login em 2-fatores:
        </h4>
        {!this.props.usuarioSso ? (
          <FormInput
            label="Senha"
            onChange={this.handleChange}
            value={this.state.senhaValue}
            placeholder="Sua senha"
            type="password"
            errorMsg="Senha incorreta"
            error={this.props.senhaIncorreta}
          />) 
          :               
          this.props.msalValidado ? (
            <DSButton
              
              fluid
              fullWidth
              color="branco"
              bg="textDarkerGray"
              iconLeft
              //onClick={this.props.handleValidaocaoMsal}
            >
              <Accept /> Conta autenticada
            </DSButton>
          ) : (
            !this.props.erroMsal ? (
              <DSButton
                ghost
                fluid
                fullWidth
                color="textDarkerGray"
                bg="gray"
                iconLeft
                onClick={this.props.handleValidaocaoMsal}
              >
                Entrar com Microsoft/Google
              </DSButton>
              ) : (
              <DSButton
                ghost
                fluid
                fullWidth
                color="textDarkerGray"
                bg="gray"
                iconLeft
                onClick={this.props.handleValidaocaoMsal}
              >
                <Reject /> Erro ao autenticar
              </DSButton>
            )
          )
        }
        
        <FormInput
          label="Código"
          onChange={this.handleChangeToken}
          value={this.state.tokenValue}
          placeholder="123123"
          type="text"
          onKeyUp={this.handleKeyUp}
          errorMsg="Código incorreto"
          error={this.props.codeGauthIncorreto}
        />
        <Button stretch background="primary" onClick={this.handleClick}>
          Desativar
        </Button>
      </DesativaTwofactorStyled>
    );
  }
}

export default DesativaTwofactor;
