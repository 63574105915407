import React from "react";
import styled from "styled-components/macro";

import Button from "../Button";
import DSButton from "../@DesignSystem/Button";
import InlineMessage from "../@DesignSystem/InlineMessage";

const BDContainer = styled.div`
  margin-top: 3em;

  h3 {
    font-size: 2.375rem;
  }

  span {
    font-size: 0.9em;
    color: gray;
    margin-top: 0.25rem;
    display: block;
  }

  button {
    display: block;
    font-size: 1.25rem;
    margin-top: 1rem;
  }
`;

const ResetBD = (props) => (
  <>
    <BDContainer>
      <DSButton
        mb={16}
        secondary
        color="danger"
        bg="dangerLight"
        onClick={props.handleClick}
      >
        Resetar banco de dados
      </DSButton>
      <InlineMessage type="system">
        Todos os dados do banco vinculado ao seu usuário serão apagados
      </InlineMessage>
    </BDContainer>
  </>
);

export default ResetBD;
