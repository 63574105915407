import api from './config/Axios';
export default class VendaService {
    static async getVenda(id, token) {
        return api({
            method: 'get',
            url: `/vendas/${id}/`,
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
    }

    static async getCliente(id, token) {
        id = id.replace(/\D/g, "");
        return api({
            method: 'get',
            url: `/clientes/${id}/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async getClienteCadastrado(id, token) {
        id = id.replace(/\D/g, "");
        return api({
            method: 'get',
            url: `/vendas/${id}/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async getPlanos(token) {
        return api({
            method: 'get',
            url: '/planos/',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async criarVenda(obj, token) {
        // NEEDFIX (retirar coisas do objeto)
        // num_bancos / num_contas / cliente (obj) / id_plano
        //console.log(obj);
        return api({
            method: 'post',
            url: '/vendas/',
            data: obj,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async criarVendaMarketplace(obj, tokenMarketplace) {
        return api({
            method: 'post',
            url: '/venda_marketplace/',
            data: obj,
            headers: {
                'token-marketplace': tokenMarketplace,
            },
        });
    }

    static async criarPlano(obj, token) {
        // n usa mais
        return;
    }

    static async desativarVenda(id, token) {
        return api({
            method: 'delete',
            url: '/vendas/' + id,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
    
    static async getUsuariosVenda(id, token) {
        return api({
            method: 'get',
            url: `/vendas/${id}/usuarios/`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async getListaVendas(teste, token) {
        return api({
            method: 'get',
            url: '/vendas/',
            headers: {
                Authorization: `Bearer ${token}`,
                teste: teste
            }
        });
    }

    static async adiarTeste(token, id_venda) {
        return api({
            method: 'patch',
            url: `/vendas/${id_venda}/adiar`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async verificaVendaSSO(token) {
        return api({
            method: 'get',
            url: `/vendas/verificar/sso`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async contratarUsuarios(token, qtd_usuarios) {
        return api({
            method: 'patch',
            url: `/venda_marketplace`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                usuarios: qtd_usuarios
            }
        });
    }

    static async contratarCapacidade(token, capacidade) {
        return api({
            method: 'patch',
            url: `/venda_marketplace`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                capacidade: capacidade
            }
        });
    }
}