import React from "react";
import styled from "styled-components/macro";
import Button from "../Button";
import DSButton from "../@DesignSystem/Button";
import { ReactComponent as MSLogoIcon } from "../../icons/mssymbol.svg";
import { ReactComponent as Accept } from "../../icons/check_circle.svg";
import { ReactComponent as Reject } from "../../icons/cancel-circle.svg";
import { FormInput } from "../FormInput";

import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";

const StyledForm = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: center;
  align-items: baseline;

  button {
    margin-left: 1rem;
  }
`;

const ConfirmaAtivacao = styled.div`
  margin-top: 2rem;
  p {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const DivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
`;

const DivForms = styled.div`
  display: inline;
  width: 95%;
`;

class GAuthAtivacao extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.passInput = React.createRef();
    this.state = {
      inputCodigo: "",
      inputSenha: "",
      codigoVazio: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSenha = this.handleChangeSenha.bind(this);
  }

  handleChange(e) {
    this.setState({
      inputCodigo: e.target.value,
    });
  }

  handleChangeSenha(e) {
    this.setState({
      inputSenha: e.target.value,
    });
  }

  componentDidMount() {
    if (!this.props.codeGauthValido) {
      this.textInput.current.focus();
    } else {
      this.passInput.current.focus();
    }
  }

  handleClickVerifica = () => {
    //console.log(this.state.inputCodigo);
    if (this.state.inputCodigo === "") {
      this.setState({
        codeGauthVazio: true,
      });
    } else {
      this.props.handleClick(this.state.inputCodigo);
      this.setState({
        codeGauthVazio: false,
        inputCodigo: "",
      });
    }
  };

  handleClickAtivar = () => {
    //console.log(this.state.inputSenha);
    this.props.ativaTwofactor(this.state.inputSenha, this.state.inputCodigo);
  };

  handleClickMsal = () => {
    console.log(this.state.inputSenha);
    this.props.handleValidaocaoMsal(
      this.state.inputSenha,
      this.state.inputCodigo
    );
  };

  handleKeyUp = (event) => {
    if (event.key === "Enter") {
      this.handleClickVerifica();
    }
  };

  render() {
    return (
      <>
        {!this.props.codeGauthValido ? (
          <>
            <h2>Valide o código</h2>
            <p>Digite abaixo o código de 6 dígitos gerado pelo aplicativo:</p>
            <StyledForm>
              <FormInput
                short
                inputRef={this.textInput}
                value={this.state.inputCodigo}
                onChange={this.handleChange}
                placeholder="Código"
                type="text"
                onKeyUp={this.handleKeyUp}
                errorMsg={
                  this.state.codeGauthVazio
                    ? "Digite um código"
                    : "Código incorreto"
                }
                error={
                  this.state.codeGauthVazio || this.props.codeGauthInvalido
                }
              ></FormInput>
              <Button background="primary" onClick={this.handleClickVerifica}>
                Verificar
              </Button>
            </StyledForm>
            <img
              className="print"
              src={require("../images/print1.png")}
              alt="print de tela do aplicativo Google Authenticator mostrando código de 6 dígitos"
            />
          </>
        ) : (
          <ConfirmaAtivacao>
            <AcceptIcon className="icon" />
            <p>
              Código válido!{" "}
              {!this.props.usuarioSso
                ? "Entre em sua conta da Microsoft/Google"
                : "Digite abaixo sua senha"}{" "}
              para confirmar a ativação do twofactor pelo google authenticator:
            </p>
            <DivFlex>
              <DivForms>
                {!this.props.usuarioSso ? (
                  <FormInput
                    short
                    inputRef={this.passInput}
                    value={this.state.inputSenha}
                    onChange={this.handleChangeSenha}
                    placeholder="Senha"
                    type="password"
                    errorMsg="Senha incorreta"
                    error={this.props.senhaIncorreta}
                  ></FormInput>
                ) : this.props.msalValidado ? (
                  <DSButton
                    fluid
                    fullWidth
                    color="branco"
                    bg="textDarkerGray"
                    iconLeft
                    //onClick={this.props.handleValidaocaoMsal}
                  >
                    <Accept /> Conta autenticada
                  </DSButton>
                ) : (
                  !this.props.erroMsal ? (
                    <DSButton
                      ghost
                      fluid
                      fullWidth
                      color="textDarkerGray"
                      bg="gray"
                      iconLeft
                      onClick={this.props.handleValidaocaoMsal}
                    >
                       Entrar com Microsoft/Google
                    </DSButton>
                    ) : (
                    <DSButton
                      ghost
                      fluid
                      fullWidth
                      color="textDarkerGray"
                      bg="gray"
                      iconLeft
                      onClick={this.props.handleValidaocaoMsal}
                    >
                      <Reject /> Erro ao autenticar
                    </DSButton>
                  )
                )}
                <FormInput
                  short
                  inputRef={this.textInput}
                  value={this.state.inputCodigo}
                  onChange={this.handleChange}
                  placeholder="Código do Authenticator"
                  type="text"
                  onKeyUp={this.handleKeyUp}
                  errorMsg="Código incorreto"
                  error={this.props.codeGauthIncorreto}
                ></FormInput>

                <Button
                  stretch
                  mt="1"
                  background="primary"
                  onClick={this.handleClickAtivar}
                >
                  Ativar
                </Button>
              </DivForms>
            </DivFlex>
          </ConfirmaAtivacao>
        )}
        <p className="dica">
          *Caso o código não esteja funcionando{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.google.com/accounts/answer/185834"
          >
            {" "}
            clique aqui
          </a>{" "}
          e siga os passos da seção "Meus códigos do Google Authenticator não
          funcionam" para sincronizar o horário
        </p>
        <div className="footer">
          <Button background="gray" onClick={this.props.prevStep}>
            Voltar
          </Button>
          <Button background="danger" onClick={this.props.handleClickCancel}>
            Cancelar
          </Button>
        </div>
      </>
    );
  }
}

export default GAuthAtivacao;
