import styled, { createGlobalStyle } from "styled-components";

import DinNormal2 from "../fonts/5591097/6ceed230-b2b3-4422-b048-4aa11687430a.woff2";
import DinNormal from "../fonts/5591097/80b0143f-6f0d-4dce-aafd-f3c81b85d177.woff";
import DinMedium from "../fonts/5591111/4ba8e512-e6fb-494f-afd3-a7b68b2e5efb.woff";
import DinMedium2 from "../fonts/5591111/398e3e8c-3bf0-4af1-9791-f426a7992711.woff2";

export const GlobalStyle = createGlobalStyle`

${"" /* reset.css */}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

${"" /* global.css */}

  @font-face{
    font-family:"DIN W01 Regular";
    src:url(${DinNormal2}) format("woff2"),url(${DinNormal}) format("woff");
    font-display: swap;
  }
  @font-face{
    font-family:"DIN W01 Medium";
    src:url(${DinMedium2}) format("woff2"),url(${DinMedium}) format("woff");
    font-display: swap;
  }

    body {
        font-size: 16px;
        font-family: 'Segoe UI', 'Verdana', sans-serif;
        ${
          "" /* font-family: 'DIN W01 Regular', 'Segoe UI', 'Verdana', sans-serif; */
        }
        background-color: ${(props) => props.theme.background};
        color: ${(props) => props.theme.text};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'DIN W01 Regular', 'Segoe UI', 'Verdana', sans-serif;
        font-weight: 400;
    }

    b {
      font-family: 'Segoe UI', 'Verdana', sans-serif;
      ${
        "" /* font-family: 'DIN W01 Medium', 'Segoe UI', 'Verdana', sans-serif; */
      }
      font-weight: 500;
    }

    .bold {
      font-family: 'Segoe UI', 'Verdana', sans-serif;
      ${
        "" /* font-family: 'DIN W01 Medium', 'Segoe UI', 'Verdana', sans-serif; */
      }
      font-weight: 500;
    }

    button {
        font-family: 'Segoe UI', 'Verdana', sans-serif;
        ${
          "" /* font-family: 'DIN W01 Regular', 'Segoe UI', 'Verdana', sans-serif; */
        }
    }

    input {
        font-family: 'Segoe UI', 'Verdana', sans-serif;
    }

    input[type=email] {
        font-family: 'Segoe UI', 'Verdana', sans-serif;
    }

    .email {
        font-family: 'Segoe UI', 'Verdana', sans-serif;
    }

    p {
        line-height: 1.4;
    }

    .icon {
      width: 1em;
      height: 1em;
      display: inline-block;
      transform: translateY(1px);
    }

    .icon-big {
      width: 1.25em;
      height: 1.25em;
    }

    .topDownGrow-enter {
      font-size: 0;
      opacity: 0;
    }

    .topDownGrow-enter-active {
      font-size: 1em;
      opacity: 1;
      transition: font-size 250ms 0ms ease-in, opacity 250ms 250ms ease-in;
    }

    .topDownGrow-exit {
      font-size: 1em;
      opacity: 1;
    }

    .topDownGrow-exit-active {
      font-size: 0;
      opacity: 0;
      transition: font-size 250ms 250ms ease-out, opacity 250ms 0ms ease-out;
    }

`;
// #f8f9fa
export const themeDefault = {
  text: "#202124" /*preto*/,
  background: "#F7F9FA" /*branco*/,
  backgroundGray: "#f8f9fa" /*cinza claro*/,
  topnav: "#FFFFFF" /*branco*/,
  sidenav: "#0046D7" /*azul*/,
  primary: "#0046D7" /*azul*/,
  primaryLight: "#96B4F0" /*azul claro*/,
  primaryLighter: "#CDDCF5" /*azul mais claro*/,
  primaryDark: "#00329A" /*azul escuro*/,
  secondary: "#FF6E4B" /*vermelho claro*/,
  secondaryLight: "#ff9a81",
  secondaryDark: "#e66344",
  terciary: "#FFFF00" /*amarelo*/,
  danger: "#d70024" /*vermelho*/,
  dangerLight: "#e34d66",
  dangerDark: "#970019",
  success: "#3CFF96" /*verde*/,
  successDark: "#3CFF96" /*verde*/,
  successLight: "#bfffdd" /*verde*/,
  gray: "#7E7E7E",
  grayLight: "#efefef",
  grayLight2: "#c7ced1",
  grayLight3: "#F2F3F5",
  grayDark: "#707070",
  pbiDashBackground: "#E6E6E6",

  //breakpoints
  bp: {
    mobile: "481px",
    tablet: "769px",
    smallDesktop: "970px",
    desktop: "1025px",
    bigDesktop: "1200px",
    veryBigDesktop: "1600px",
  },

  //cores
  azul: "#0046D7",
  verde: "#3CFF96",
  branco: "#FFFFFF",
  laranja: "#FD6E4B",
  amarelo: "#FFFF00",
  amareloForte: "#FFD700",
  amareloEscuro: "#8F7100",
  taxdashs: "#FFD700",

  shadow: {
    flat: "0px 0px 75px rgba(0, 0, 0, 0.15)",
    primary: "0px 10px 20px rgba(0, 70, 215, 0.35)",
    taxdashs: "0px 10px 20px rgba(255, 215, 0, 0.25);",
    secondary: "0px 10px 20px rgba(232, 103, 72, 0.35)",
  },

  input: {
    bg: " #f0f4fe",
    border: "#dcdee0",
    shadow: {
      primary: "0px 8px 16px -8px rgba(0, 70, 215, 0.5)",
    },
  },

  hover: {
    primary: "#003299",
    secondary: "#E86748",
    verde: "#00CC5E",
    laranja: "#FC3403",
    taxdashs: "#FFE600",
    dangerLight: "#FFEAEE",
    grayLight: "#F2F3F5",
    primaryLight: "#CDDCF5",
    white: "#FFFFFF",
  },

  scrollbarModal: "#B9BDC2",

  bgGray: "#F7F9FA",

  textLightGray: "#909090",
  textGray: "#7E7E7E",
  textDarkGray: "#727272",
  textDarkerGray: "#5E5E5E",
};

export const DefaultButton = styled.button`
  border: none;
  padding: 0.75em 1.5em;
  border-radius: 0;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  box-shadow: ${(props) =>
    ({
      primary: "0px 12px 20px -12px rgba(0, 70, 215, 0.35);",
      secondary: "0px 12px 20px -12px rgba(255, 110, 75, 0.35);",
      danger: "0px 12px 20px -12px rgba(151, 0, 25, 0.35);",
      gray: "0px 12px 20px -12px rgba(239, 239, 239, 0.35);",
    }[props.background])};
  background-color: ${(props) =>
    ({
      primary: props.theme.primary,
      secondary: props.theme.secondary,
      danger: props.theme.dangerDark,
      gray: props.theme.grayLight,
    }[props.background])};
`;

export const LinkButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: underline;
`;

export const PillContainer = styled.div`
  padding: 4px;
  background-color: #eff0f2;
  box-sizing: border-box;
  border-radius: ${(props) => (props.rounded ? "64px" : "8px")};
  margin: 2rem -0.25rem 1rem;
  display: inline-block;
  border: 1px solid #eaeaeb;
`;

export const PillButton = styled.button`
  margin-right: 1rem;
  padding: 12px 24px;
  background-color: ${(props) =>
    props.active ? props.theme.background : "transparent"};
  color: ${(props) => (props.active ? props.theme.primary : props.theme.text)};
  box-shadow: ${(props) => (props.active ? "0px 4px 8px #D5D9E1" : "none")};
  border-radius: ${(props) => (props.rounded ? "64px" : "4px")};
  outline: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-family: ${(props) =>
    props.active
      ? '"DIN W01 Medium", "Segoe UI", "Verdana", sans-serif'
      : '"DIN W01 Regular", "Segoe UI", "Verdana", sans-serif'};
  font-weight: ${(props) => (props.active ? "500" : "400")};

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
