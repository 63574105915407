import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { store } from "react-notifications-component";
import { ToastNotification, notificationTopCenter } from "../ToastNotification";
import "animate.css";

import AuthService from "../../services/AuthService";
import { verificaSenha } from "../../helpers/verificar-senha";

import DSButton from "../@DesignSystem/Button";
import DSFormInput from "../@DesignSystem/FormInput";

import RequisitosSenha from "../RequisitosSenha";
import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";
import LoadingIcon from "../LoadingIcon";

const HeaderLogin = styled.h4`
  font-size: 1.5rem;
  text-align: left;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  a:last-child {
    text-align: center;

    img {
      height: 36px;
      margin-top: 1.5rem;
      text-align: center;
    }
  }
`;

const LoadingBox = styled.div`
 text-align: center;

svg {
  width: 4rem;
}
`

const SenhaCriada = styled.div`
  text-align: center;
  /* background-color: ${(props) => props.theme.successLight}; */
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;

  svg {
    height: 32px;
    width: 32px;
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.2rem;
  }
`;

export default (props) => {
  const [token, setToken] = useState(props.token);
  const [usuario, setUsuario] = useState("");
  const [passValue, setPassValue] = useState("");
  const [senhaAlterada, setSenhaAlterada] = useState("");
  const [invalidPassword, setInvalidPassword] = useState("");
  const [senhasDiferentes, setSenhasDiferentes] = useState("");
  const [confirmPassValue, setConfirmPassValue] = useState("");
  const [loading, setLoading] = useState(true);
  const passInput = useRef(null);
  const confirmPassInput = useRef(null);
  let history = useHistory();

  useEffect(() => {
    if (passInput.current) {
      passInput.current.focus();
    }
    setLoading(true);
    AuthService.validarTokenRecuperacao(token)
      .then((resp) => {
        //console.log(resp);
        // setUsuario(resp.data.email);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Token expirado
            </ToastNotification>
          ),
          //onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopCenter,
        });
        setLoading(false);
        history.push("/");
      });
  }, [history, token]);

  const criaNovaSenha = () => {
    if (!verificaSenha(passValue).ehForte) {
      setInvalidPassword(true);
      return;
    }

    if (passValue !== confirmPassValue) {
      setSenhasDiferentes(true);
      return;
    }

    setInvalidPassword(false);
    setSenhasDiferentes(false);

    AuthService.criarNovaSenhaPorToken(token, usuario, passValue)
      .then((resp) => {
        //console.log(resp);
        setSenhaAlterada(true);
      })
      .catch((err) => {
        let msg = "tente novamente mais tarde";

        if(err.response.data.statusCode ===  403) msg = "token de recuperação expirado";

        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Não foi possível criar nova senha, {msg}.
            </ToastNotification>
          ),
          //onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopCenter,
        });
        // console.log(err);
      });
  };

  const handlePassChange = (event) => {
    setPassValue(event.target.value);
    setInvalidPassword(false);
  };

  const handleConfPassChange = (event) => {
    setConfirmPassValue(event.target.value);
    setSenhasDiferentes(false);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      criaNovaSenha();
    }
  };

  return (
    <>
      {loading ? (
        <LoadingBox>
        <LoadingIcon/>
        </LoadingBox>
      ) : (
        <>
          <HeaderLogin>Criar nova senha</HeaderLogin>
          <FormGroup>
            {!senhaAlterada ? (
              <>
                {/* <DSFormInput
              readonly
              type="text"
              label="Email"
              value={usuario}
              mt={24}
            /> */}
                <DSFormInput
                  mt={24}
                  inputRef={passInput}
                  required
                  type="password"
                  id="password"
                  label="Nova Senha"
                  onChange={handlePassChange}
                  value={passValue}
                  onKeyUp={handleKeyUp}
                  errorMsg="Complete todos os requisitos de senha"
                  error={invalidPassword}
                />
                <RequisitosSenha password={passValue} />
                <DSFormInput
                  mt={0}
                  mb={24}
                  inputRef={confirmPassInput}
                  required
                  type="password"
                  id="password"
                  label="Confirmar nova senha"
                  onChange={handleConfPassChange}
                  value={confirmPassValue}
                  onKeyUp={handleKeyUp}
                  errorMsg="As senhas não coincidem"
                  error={senhasDiferentes}
                />
                <DSButton
                  mt={0}
                  fluid
                  background="primary"
                  onClick={criaNovaSenha}
                >
                  Criar senha
                </DSButton>
              </>
            ) : (
              <>
                <>
                  <SenhaCriada>
                    <AcceptIcon />
                    <p>Nova senha criada com sucesso!</p>
                  </SenhaCriada>
                  <DSButton fluid onClick={() => history.push("/")}>
                    Fazer Login
                  </DSButton>
                </>
              </>
            )}
          </FormGroup>
        </>
      )}
    </>
  );
};
