import React from "react";
import styled from "styled-components";

import { ReactComponent as ToolsIcon } from "../icons/tools-icon.svg";

import taxdashsVertical from "../icons/taxdashs_vertical.png";

const Manutencao = styled.div`
  max-width: 860px;
  margin: 64px auto;
  text-align: center;

  div {
    margin-left: ${(props) => (props.marginNegativa ? "-216px" : 0)};
  }

  svg {
    height: 128px;
    width: 128px;
    opacity: 0.5;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 64px;
    margin: 32px 0;
    color: ${(props) => props.theme.text};
    text-align: center;
  }

  p {
    font-size: 24px;
    text-align: center;
    opacity: 0.85;
  }

  img {
    width: 120px;
    margin-top: 96px;
  }
`;

export default (props) => {
  return (
    <Manutencao marginNegativa={props.marginNeg}>
      <div>
        <ToolsIcon />
        <h1>Estamos em manutenção</h1>
        <p>
          Desculpe o transtorno! Estamos em manutenção para melhorar sua
          experiência com o <b>TaxDashs</b>, não é possível utilizar a
          ferramenta enquanto isso.
        </p>
        <p>Tente novamente mais tarde.</p>
        <img src={taxdashsVertical} alt="Logo TaxDashs" />
      </div>
    </Manutencao>
  );
};
