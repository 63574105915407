import api from './config/Axios';
export default class NotificacaoService {
    static async getNotificacoes(limit, token, visualizadas) {
        return api({
            method: 'get',
            url: `/notificacoes/${limit}/`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "visualizadas": visualizadas,
            }
        });
    }

    static async trocaFlagVisualizado(id, token) {
        return api({
            method: 'patch',
            url: `/notificacoes/${id}/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}