import React from "react";
import styled from "styled-components/macro";

const StyledSwitch = styled.label`
  display: inline-block;

input {
    display: none;

    &:checked + label {
        background: #648CE6;
    }
    
    &:checked + label:after {
        left: 50%;
    }
}
    
label {
    outline: 0;
    display: block;
    width: ${props => props.small ? '3em' : '4em'};
    height: ${props => props.small ? '1.5em' : '2em'};
    position: relative;
    cursor: pointer;
    user-select: none;
    background: lightgray;
    border-radius: ${props => props.small ? '1.5em' : '2em'};
    padding: 3px;
    transition: all .4s ease;

    &:after,
    &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
        box-shadow:0 3px 5px rgba(0, 0, 0, .35);
    }

    &:after {
        left: 0;
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
    }

    &:before {
        display: none;
    }
}
`;

const SwitchButton = (props) => {
  return (
  <StyledSwitch small={props.small}>
    <input
      type="checkbox"
      id={props.id}
      name={props.id}
      {...props}
    />
    <label htmlFor={props.id}></label>
  </StyledSwitch>
)
};

export default SwitchButton;
