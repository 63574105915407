import styled from "styled-components";

export const RadioGroup = styled.div`
  display: flex;
  margin-top: ${(p) => p.$mt}px;

  p {
    font-weight: 600;
    font-size: ${(p) =>
      p.$size === "small" ? "12px" : p.$size === "big" ? "18px" : "18px"};
    line-height: 21px;
    margin-right: 24px;
  }
`;
