import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import SwitchButton from "../@DesignSystem/SwitchButton";
import FormInput from "../@DesignSystem/FormInput";
import Button from "../@DesignSystem/Button";
import Checkbox from "../@DesignSystem/Checkbox";
import InlineMessage from "../@DesignSystem/InlineMessage";

import Modal from "../Modal";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "../ToastNotification";
import UsuarioService from "../../services/UsuarioService";
import BancoService from "../../services/BancoService";
import VendaService from "../../services/VendaService";

import { useInput } from "../../hooks/useInput";
import Dropdown from "../@DesignSystem/Dropdown";

const StyledH4 = styled.h4`
  font-size: 1.75rem;
  margin-bottom: 1em;
  margin-top: 2rem;
`;

const ModalContent = styled.div`
  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .buttons {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  .max-width {
    max-width: 316px;
  }

  .renomeia-buttons {
    display: flex;
    justify-content: space-between;
  }

  margin-bottom: 4rem;
`;

export default function CriarUsuario(props) {
  const [comum, setComum] = useState(false);
  const [usuarioSSO, setUsuarioSSO] = useState(false);
  const [vendaSSO, setVendaSSO] = useState(0);
  const [bancosNaoDisponiveis, setBancosNaoDisponiveis] = useState([]);
  const [bancosDisponiveis, setBancosDisponiveis] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [bancodefault, setBancodefault] = useState([]);
  const [criarBanco, setCriarBanco] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionEdit, setSelectedOptionEdit] = useState(null);
  const [renomeiaBanco, setRenomeiaBanco] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [escolheBanco, setEscolheBanco] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [bancoNovoNome, setBancoNovoNome] = useState("");
  const [todosBancos, setTodosBancos] = useState([]);
  const [isSynapse, setIsSynapse] = useState(false);

  const refCriaBanco = useRef(null);
  const refCriaNovoBanco = useRef(null);
  const refRenomeiaBanco = useRef(null);
  const refCriarBtn = useRef(null);

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
    check: checkEmail,
  } = useInput("", "email");

  const {
    value: novoBanco,
    isInvalid: isInvalidNovoBanco,
    bind: bindNovoBanco,
    reset: resetNovoBanco,
    setValue: setNovoBanco,
    check: checkNovoBanco,
  } = useInput("", "vazio");

  const {
    value: banco,
    isInvalid: isInvalidBanco,
    bind: bindBanco,
    reset: resetBanco,
    check: checkBanco,
  } = useInput("");

  const {
    value: bancoRenomeia,
    isInvalid: isInvalidBancoRenomeia,
    bind: bindBancoRenomeia,
    reset: resetBancoRenomeia,
    setValue: setBancoRenomeia,
    check: checkBancoRenomeia,
  } = useInput("");

  const verificaSso = () => {
    VendaService.verificaVendaSSO(props.tokenLogin)
      .then((resp) => {
        setVendaSSO(resp.data.sso);
        if (resp.data.sso == 1) {
          console.log("vendaSSO");
          setUsuarioSSO(true);
        }
      })
      .catch((err) => {
        console.log("Não foi possível verificar se a venda permite SSO");
        console.err(err);
      });
  };

  const handleChangeCheckbox = () => {
    setUsuarioSSO(!usuarioSSO);
  };

  const handleClick = () => {
    let btn = refCriarBtn.current;
    btn.disabled = true;

    setTimeout(function () {
      btn.disabled = false;
    }, 2000);

    let token = props.tokenLogin;
    let obj = {
      email: email,
      permissao: comum ? "Comum" : "Leitor",
      novo_banco: !escolheBanco,
      sso: usuarioSSO,
    };
    if (!escolheBanco) {
      obj.nome_banco = novoBanco ? novoBanco : `Banco de ${email}`;
    } else {
      obj.id_banco = selectedOption ? selectedOption.value : null;
    }
    //console.log("-------------")
    //console.log(obj);

    if (escolheBanco && !obj.id_banco) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary">
            É preciso selecionar um banco para criar o usuário.
          </ToastNotification>
        ),
        onRemoval: (id, removedBy) => console.log(""),
        ...notificationTopRight,
      });
      return;
    }

    if (!escolheBanco && novoBanco.length === 0) {
      return;
    }

    setSelectedOption(null);
    resetEmail();
    resetNovoBanco();

    if (vendaSSO == 1) {
      obj.sso = true;
    }
    // console.log(obj);
    UsuarioService.criarUsuario(token, obj)
      .then((resp) => {
        //alert("sucesso");
        store.addNotification({
          content: (
            <ToastNotification>
              Criando usuário. Em até 10 minutos o usuário receberá um email de
              confirmação.
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => console.log(""),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        if (err.response.status === 409) {
          let errorMsg;
          if (
            err.response.data &&
            err.response.data.descricao === "Este banco já existe"
          )
            errorMsg =
              "Não foi possível criar o banco de dados, pois ela já existe.";
          else
            errorMsg =
              " Não foi possível criar o usuário. Usuário já cadastrado na aplicação.";
          store.addNotification({
            content: (
              <ToastNotification bg="secondary">{errorMsg}</ToastNotification>
            ),
            onRemoval: (id, removedBy) => console.log(""),
            ...notificationTopRight,
          });
        } else {
          store.addNotification({
            content: (
              <ToastNotification bg="secondary">
                Erro ao criar usuário. Verifique se os dados são válidos ou se
                seu plano permite um novo usuário.
              </ToastNotification>
            ),
            onRemoval: (id, removedBy) => console.log(""),
            ...notificationTopRight,
          });
        }
      });
  };

  const handleBancoChange = (selectedOption, e) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
  };

  const handleBancoChangeEdit = (selectedOption, e) => {
    setIsLoading(true);
    setSelectedOptionEdit(selectedOption);
  };

  const handleRenomeia = () => {
    console.log(selectedOptionEdit.label);
    setRenomeiaBanco(true);
    setBancoRenomeia(selectedOptionEdit.label);
    refRenomeiaBanco.current.focus();
  };

  useEffect(() => {
    getBancos();
    verificaSso();
  }, [comum]);

  useEffect(() => {
    setNovoBanco(`Banco de ${email.substring(0, email.indexOf("@"))}`);
  }, [email, setNovoBanco]);

  const getBancos = () => {
    BancoService.getListaBancos(props.tokenLogin).then((resp) => {
      //console.log(resp);
      let bancos1 = resp.data.bancos.bancos_disponiveis.map((banco) => ({
        label: banco.nome,
        value: banco.uuid,
      }));

      let bancos2 = resp.data.bancos.bancos_nao_disponiveis.map((banco) => ({
        label: banco.nome,
        value: banco.uuid,
      }));

      let opcoesBancos = null;
      if (comum) {
        let bancosDisabled = bancos2.map((data) => ({
          ...data,
          isDisabled: true,
        }));
        opcoesBancos = [
          {
            label: "Disponiveis",
            options: bancos1,
          },
          {
            label: "Não Disponiveis",
            options: bancosDisabled,
            color: "#d4d4d4",
          },
        ];
      } else {
        opcoesBancos = [...bancos1, ...bancos2];
      }
      //console.log(opcoesBancos);

      setBancosDisponiveis(bancos1);
      setBancosNaoDisponiveis(bancos2);
      setTodosBancos([...bancos1, ...bancos2]);
      setBancos(opcoesBancos);
    });
  };

  const criaBanco = () => {
    let obj = {
      nome: banco,
      synapse: isSynapse ? 1 : 0,
    };

    BancoService.criarBanco(props.tokenLogin, obj)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary">
              Sucesso! Em até 10 minutos o banco será criado.
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        let msg = "";
        if (err.response && err.response.status === 403) {
          msg = "Você atingiu o limite de bancos na conta.";
        }

        if (err.response && err.response.data && err.response.data.descricao) {
          msg = err.response.data.descricao + ".";
        }
        store.addNotification({
          content: (
            <ToastNotification bg="secondary">
              Não foi possível criar o banco.{" "}
              {msg ? msg : "Tente novamente mais tarde."}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });
      });
    //Repopula select com novo banco se for criado
    getBancos();
  };

  const apagaBanco = () => {
    toggleModal();

    let obj = {
      id_banco: selectedOptionEdit.value,
    };

    BancoService.deletarBanco(props.tokenLogin, obj)
      .then((res) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary">
              Banco de dados apagado com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });

        getBancos();
        setSelectedOption(null);
        props.atualizar();
      })
      .catch((err) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary">
              Não foi possível apagar o banco de dados.
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });
      });

    //Repopula select com novo banco se for criado
    getBancos();
  };

  const renomeiarBanco = () => {
    let obj = {
      id_banco: selectedOptionEdit.value,
      nome: bancoRenomeia,
    };

    setSelectedOptionEdit(null);

    BancoService.renomearBanco(props.tokenLogin, obj)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary">
              Banco de dados renomeado!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });

        getBancos();
        let selected = selectedOptionEdit;
        selected.label = bancoRenomeia;
        setSelectedOptionEdit(selected);
        props.atualizar();
      })
      .catch((err) => {
        let msg =
          "Não foi possível renomear o banco de dados. Tente novamente...";
        if (err.response && err.response.data && err.response.data.descricao) {
          msg = `Não foi possível renomear o banco de dados. ${err.response.data.descricao}`;
        }
        store.addNotification({
          content: <ToastNotification bg="secondary">{msg}</ToastNotification>,
          // onRemoval: (id, removedBy) => console.log(removedBy),
          ...notificationTopRight,
        });
      });

    //Repopula select com novo banco se for criado
    getBancos();

    //volta pro modo de select-dropdown
    setRenomeiaBanco(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEscolheBanco = () => {
    setEscolheBanco(!escolheBanco);
  };

  const handleChangeComum = (index) => {
    setComum(!(index === 0));
  };

  const handleChangeSynapse = (index) => {
    setIsSynapse(!(index === 0));
  };

  return (
    <>
      <StyledH4>Criar novo usuário</StyledH4>
      <Container leitor={!comum}>
        <SwitchButton
          variant={1}
          selection={!comum ? 0 : 1}
          handleChange={handleChangeComum}
          options={["Criar Leitor", "Criar Escritor"]}
        />
        <div className="max-width">
          <FormInput
            type="text"
            id="email"
            label="Email"
            error={isInvalidEmail}
            errorMsg="Email inválido"
            required
            placeholder="Email"
            mt={24}
            mb={16}
            {...bindEmail}
          />
        </div>
        {vendaSSO != 0 && (
          <div>
            <Checkbox
              label="Permitir login com conta Microsoft/Google"
              mt={0}
              mb={24}
              size="small"
              checked={vendaSSO == 1 || usuarioSSO}
              onChange={handleChangeCheckbox}
              disabled={vendaSSO == 1}
            />
          </div>
        )}
        {!comum ? (
          <Dropdown
            name="select-bancos"
            label="Banco de dados"
            isClearable={true}
            isSearchable={true}
            className=""
            placeholder="Selecione um banco de dados"
            onChange={(selectedOption) => handleBancoChange(selectedOption)}
            options={bancos}
            isLoading={props.isLoading}
            noOptionsMessage={() => "Nenhum banco encontrado"}
          />
        ) : (
          <div>
            {escolheBanco ? (
              <Dropdown
                label="Banco de dados"
                name="select-bancos"
                isClearable={true}
                isSearchable={true}
                className=""
                placeholder="Selecione um banco de dados"
                onChange={(selectedOption) => handleBancoChange(selectedOption)}
                options={bancos}
                isLoading={props.isLoading}
                noOptionsMessage={() => "Nenhum banco encontrado"}
              />
            ) : (
              <div className="max-width">
                <FormInput
                  inputRef={refCriaNovoBanco}
                  type="text"
                  id="novoBanco"
                  label="Nome do novo banco de dados"
                  error={isInvalidNovoBanco}
                  errorMsg="Digite um nome"
                  required
                  placeholder="Digite o nome do novo banco"
                  {...bindNovoBanco}
                />
              </div>
            )}
            <Button
              link
              bg="primaryLight"
              onClick={toggleEscolheBanco}
              secondary
              color="text"
              mt={16}
            >
              {escolheBanco
                ? "Criar novo banco de dados"
                : "Escolher banco de dados existente"}
            </Button>
          </div>
        )}
        <Button
          forwardRef={refCriarBtn}
          background="primary"
          onClick={handleClick}
          mt={16}
        >
          Criar Usuário {comum ? "Escritor" : "Leitor"}
        </Button>
      </Container>
      <StyledH4>Criar novo banco de dados</StyledH4>
      <Container>
        <div className="max-width">
          <FormInput
            inputRef={refCriaBanco}
            type="text"
            id="banco"
            label="Nome do banco de dados"
            errorMsg=""
            required
            placeholder="Digite nome do banco"
            {...bindBanco}
          />
        </div>
        {props.planoSynapse && (
          <>
            <p
              style={{
                color: "#202124",
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "4px",
                marginTop: "24px",
              }}
            >
              Tipo do banco
            </p>
            <SwitchButton
              size="small"
              variant={1}
              selection={!isSynapse ? 0 : 1}
              handleChange={handleChangeSynapse}
              options={["SQL", "Synapse"]}
            />
          </>
        )}
        {isSynapse && (
          <InlineMessage size="small">
            Synapses são recomendados apenas para bancos com grande volume de
            dados
          </InlineMessage>
        )}
        <Button mt={16} background="primary" onClick={criaBanco}>
          Criar banco de dados
        </Button>
      </Container>
      <StyledH4>Editar banco de dados</StyledH4>
      <Container>
        <div>
          <div>
            <div>
              {!renomeiaBanco ? (
                <Dropdown
                  name={props.id}
                  isClearable={true}
                  isSearchable={true}
                  label="Banco de dados"
                  className=""
                  placeholder="Selecione um banco de dados"
                  onChange={(selectedOption) =>
                    handleBancoChangeEdit(selectedOption)
                  }
                  options={todosBancos}
                  isLoading={props.isLoading}
                  noOptionsMessage={() => "Nenhum banco encontrado"}
                />
              ) : (
                <div className="max-width">
                  <FormInput
                    inputRef={refRenomeiaBanco}
                    type="text"
                    id="bancoNovoNome"
                    label="Novo nome do banco"
                    errorMsg=""
                    required
                    placeholder="Digite o novo nome do banco de dados"
                    {...bindBancoRenomeia}
                  />
                </div>
              )}
            </div>

            {selectedOptionEdit && !renomeiaBanco && (
              <Button
                mt={16}
                ghost
                color="primary"
                bg="primary"
                onClick={handleRenomeia}
              >
                Renomear esse banco
              </Button>
            )}
            {renomeiaBanco && (
              <div className="renomeia-buttons max-width">
                <Button
                  bg="gray"
                  secondary
                  color="text"
                  mt={16}
                  onClick={() => {
                    setRenomeiaBanco(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button bg="primary" mt={16} onClick={renomeiarBanco}>
                  Renomear
                </Button>
              </div>
            )}
          </div>

          {selectedOptionEdit && !renomeiaBanco && (
            <Button mt={16} link bg="danger" onClick={toggleModal}>
              Apagar banco de dados
            </Button>
          )}
        </div>
      </Container>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={toggleModal}
          modalSize="md"
          modalTipo="default"
        >
          <div className="box-body">
            <ModalContent>
              <h2>
                Tem certeza de que deseja <u>apagar o banco de dados</u>{" "}
                {selectedOptionEdit.label}?
              </h2>
              <p>Todos os dados serão apagados e perdidos</p>
              <div className="buttons">
                <Button bg="gray" onClick={toggleModal}>
                  Cancelar
                </Button>
                <Button bg="danger" onClick={apagaBanco}>
                  Apagar
                </Button>
              </div>
            </ModalContent>
          </div>
        </Modal>
      )}
    </>
  );
}
