import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Dropdown from "../@DesignSystem/Dropdown";
import Button from "../@DesignSystem/Button";
import Checkbox from "../@DesignSystem/Checkbox";
import InlineMessage from "../@DesignSystem/InlineMessage";
import Title from "../@DesignSystem/Title";
import ToggleButton from "../@DesignSystem/ToggleButton";
import FormInput from "../@DesignSystem/FormInput";
import LoadingIcon from "../LoadingIcon";

import { useInput } from "../../hooks/useInput";

import { cnpjMask } from "../../helpers/masks";

import { ReactComponent as UploadIcon } from "../../icons/backup.svg";
import { ReactComponent as AddIcon } from "../../icons/add_black_24dp.svg";

import CertificadoService from "../../services/CertificadoService";

import UserContext from "../../context/user-context";
import { store } from "react-notifications-component";
import { notificationTopRight, ToastNotification } from "../ToastNotification";
import mixpanel from "mixpanel-browser";

const Certificado = styled.div`
  h3.title {
    h3 {
      font-size: 2.375rem;
      margin-bottom: 2rem;
    }
  }
`;

const SectionProcurador = styled.div`
  padding: 16px 24px;
  background-color: ${(p) => p.theme.background};
  border-radius: 8px;

  .flex {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: start;
    grid-gap: 16px;
  }

  .flex-docs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 412px;
    margin-top: 8px;

    p {
      font-size: 20px;
      margin-right: 16px;
    }

    button {
      opacity: 0;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
`;

const CertificadosCadastrados = styled.div`
  padding: 8px 16px;
  background-color: ${(p) => p.theme.background};
  border-radius: 4px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: 400px;

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header {
    margin-top: 8px;
    text-overflow: ellipsis;

    p {
      margin-right: 8px;
      font-size: 20px;
      font-weight: 600;
      text-overflow: ellipsis;
    }
  }

  .docs {
    margin: 24px 0;

    p.label {
      font-size: 14px;
      font-weight: 600;
      color: ${(p) => p.theme.textDarkerGray};
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .flex {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: start;
    grid-gap: 16px;
  }

  .align-center {
    align-items: flex-end;
  }

  .actions-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-docs {
    display: flex;
    align-items: center;
    width: 412px;
    margin-top: 8px;

    p {
      margin-right: 16px;
      font-size: 16px;
    }

    button {
      opacity: 0;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
`;

export default function CertificadoDigital({
  bancos,
  isLoading,
  tokenLogin,
  permissao,
  certificadosCadastrados,
  setCertificadosCadastrados,
}) {
  //mixpanel

  mixpanel.init("b0e23bded11ea4857ace89778a304db0");

  const [selectedBanco, setSelectedBanco] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcurador, setIsProcurador] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [adicionaNovo, setAdicionaNovo] = useState(true);
  // const [certificadosCadastrados, setCertificadosCadastrados] = useState([]);
  const [senhasDiferentes, setSenhasDiferentes] = useState(false);
  const [criandoCertificado, setCriandoCertificado] = useState(false);

  const {
    value: cnpjValue,
    bind: bindCnpjValue,
    reset: resetcnpjValue,
    check: checkCnpj,
    isInvalid: invalidCnpj,
  } = useInput("", "cnpj", "cnpj");

  const {
    value: passwordValue,
    bind: bindPasswordValue,
    reset: resetPasswordValue,
    check: checkPassword,
    isInvalid: invalidPassword,
  } = useInput("", "");

  const {
    value: confirmaValue,
    bind: bindConfirmaValue,
    reset: resetConfirmaValue,
    check: checkConfirma,
    isInvalid: invalidConfirma,
  } = useInput("", "");

  const {
    value: nomeValue,
    bind: bindNomeValue,
    reset: resetNomeValue,
    check: checkNome,
    isInvalid: invalidNome,
    setValue: setNomeValue,
  } = useInput("", "");

  const handleChangeCheckbox = () => {
    setIsProcurador(!isProcurador);
  };

  const removeDoc = (index) => {
    const list = [...documentos];

    // list[index].num_doc = "Documento removido";
    // list[index].removendo = true;

    list.splice(index, 1);
    setDocumentos(list);

    //handle undo da deleção
  };

  const addNewDoc = () => {
    setAdicionaNovo(true);
    if (invalidCnpj || checkCnpj()) return;

    let ehCnpj = cnpjValue.length > 14;

    resetcnpjValue();
    setDocumentos([
      ...documentos,
      { num_doc: cnpjValue, ehCnpj: ehCnpj, removendo: false },
    ]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addNewDoc();
    }
  };

  const newDocRef = useRef(null);

  const deletarCertificado = (nome) => {
    CertificadoService.deletarCertificado(tokenLogin, nome)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Certificado excluído!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
        atualizaCertificados();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  const atualizaCertificados = () => {
    CertificadoService.getCertificados(tokenLogin)
      .then((resp) => {
        setCertificadosCadastrados(resp.data.certificados);
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const criarCertificado = () => {
    if (criandoCertificado) return;

    if (!selectedFile) {
      alert("Selecione o arquivo do certificado");
      return;
    }

    if (invalidPassword || passwordValue.length === 0) {
      setSenhasDiferentes(true);
      return;
    }

    setCriandoCertificado(true);

    let nomeComPfx = nomeValue + ".pfx";

    CertificadoService.criarCertificado(
      tokenLogin,
      nomeComPfx,
      passwordValue,
      selectedFile
    )
      .then((resp) => {
        console.log(resp);

        setSelectedFile(null);
        resetNomeValue();
        resetPasswordValue();
        setIsProcurador(false);

        setCriandoCertificado(false);

        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Certificado cadastrado com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });

        if (documentos[0]?.num_doc && isProcurador) {
          for (let [index, doc] of documentos.entries()) {
            console.log(doc, index);
            CertificadoService.adicionarDocumento(
              tokenLogin,
              nomeComPfx,
              doc.ehCnpj,
              doc.num_doc
            )
              .then((resp) => {
                console.log(resp);
                if (index === documentos.length - 1) {
                  setDocumentos([]);
                }
                atualizaCertificados();
                mixpanel.track("Cadastrou certificado", {
                  nome: nomeComPfx,
                  arquivo: selectedFile.name,
                  num_docs: documentos.length,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }

        atualizaCertificados();
      })
      .catch((err) => {
        console.log(err);
        console.error(err.response.data.descricao);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        mixpanel.track("Falha ao cadastrar certificado", {
          error: err.response.data.descricao,
        });
        setCriandoCertificado(false);
      });
  };

  useEffect(() => {
    atualizaCertificados();
  }, [selectedBanco]);

  return (
    <Certificado>
      {/* <Title variant={3}>Certificado Digital</Title> */}
      <h3 className="title">Certificado Digital</h3>
      <Title headingLevel="h5" mt={32}>
        Certificados cadastrados
      </Title>
      {!certificadosCadastrados.length ? (
        <p>Nenhum certificado cadastrado</p>
      ) : (
        certificadosCadastrados.map((certificado, index) => (
          <CertificadosCadastrado
            certificado={certificado}
            deletarCertificado={deletarCertificado}
            tokenLogin={tokenLogin}
          />
        ))
      )}
      <Title headingLevel="h5" mt={32}>
        Adicionar novo certificado
      </Title>
      <FileUploader
        onFileSelectSuccess={(file) => {
          setSelectedFile(file);
          setNomeValue(file.name.substring(0, file.name.length - 4));
        }}
        onFileSelectError={({ error }) => {
          setSelectedFile(null);
          alert(error);
        }}
        fileName={selectedFile?.name}
      />
      <FormInput
        mt={24}
        width={320}
        type="text"
        id="nome-certificado"
        label="Identificação do certificado"
        errorMsg=""
        error={invalidNome}
        required
        {...bindNomeValue}
      />
      <FormInput
        mt={24}
        width={320}
        type="password"
        id="senha"
        label="Senha do certificado"
        errorMsg=""
        error={invalidPassword}
        required
        {...bindPasswordValue}
      />
      <Checkbox
        label="Procurador"
        mt={24}
        mb={16}
        checked={isProcurador}
        onChange={handleChangeCheckbox}
      />
      {isProcurador && (
        <SectionProcurador>
          <Title headingLevel="h5">Adicionar empresa</Title>
          {documentos.length && documentos[0].num_doc ? (
            documentos.map((novoDoc, index) => (
              <div className="flex-docs">
                <p>
                  {novoDoc.removendo
                    ? novoDoc.num_doc
                    : cnpjMask(novoDoc.num_doc)}
                </p>
                <Button
                  onClick={() => removeDoc(index)}
                  secondary
                  size="small"
                  bg="dangerLight"
                  color="danger"
                >
                  {novoDoc.removendo ? "Desfazer" : "Remover empresa"}
                </Button>
              </div>
            ))
          ) : (
            <></>
          )}
          {adicionaNovo && (
            <div className="flex">
              <FormInput
                ref={newDocRef}
                required
                mt={16}
                label="CNPJ ou CPF"
                error={invalidCnpj}
                errorMsg="Digite um documento válido"
                width={200}
                onKeyDown={handleKeyDown}
                {...bindCnpjValue}
              />
              <Button
                onClick={() => setAdicionaNovo(false)}
                mt={36}
                secondary
                small
                bg="dangerLight"
                color="text"
              >
                Cancelar
              </Button>
            </div>
          )}
          <Button
            onClick={addNewDoc}
            mt={16}
            secondary
            color="primary"
            bg="primaryLight"
            size="small"
            iconLeft
          >
            <AddIcon />
            {documentos.length ? "Adicionar empresa" : "Adicionar empresa"}
          </Button>
        </SectionProcurador>
      )}
      <Button onClick={criarCertificado} mt={24}>
        {criandoCertificado ? (
          <LoadingIcon fill="white" />
        ) : (
          "Adicionar certificado"
        )}
      </Button>
    </Certificado>
  );
}

const StyledFileInput = styled.div`
  margin: 16px 0;

  input[type="file"] {
    display: none;
  }

  .wrapper {
    display: flex;
    align-items: baseline;

    *:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

const FileUploader = ({ onFileSelectError, onFileSelectSuccess, fileName }) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    const fileType = file.name.substring(file.name.length - 4);

    if (fileType !== ".pfx")
      onFileSelectError({ error: "O arquivo deve ser do tipo .pfx" });
    else onFileSelectSuccess(file);
  };

  return (
    <StyledFileInput>
      <input type="file" ref={fileInput} onChange={handleFileInput} />
      <div className="wrapper">
        {fileName && <Title headingLevel="h6">{fileName}</Title>}
        <Button
          secondary={fileName}
          iconLeft
          bg="grayLight"
          color="text"
          onClick={(e) => fileInput.current && fileInput.current.click()}
        >
          {!fileName && <UploadIcon />}
          {fileName ? "Escolher outro arquivo" : "Escolher arquivo"}
        </Button>
      </div>
    </StyledFileInput>
  );
};

const CertificadosCadastrado = ({
  certificado,
  deletarCertificado,
  tokenLogin,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [documentos, setDocumentos] = useState(certificado.docs);
  const [adicionaNovo, setAdicionaNovo] = useState(false);
  const [attSenha, setAttSenha] = useState(false);

  const newDocRef = useRef(null);

  const {
    value: cnpjValue,
    bind: bindCnpjValue,
    reset: resetcnpjValue,
    check: checkCnpj,
    isInvalid: invalidCnpj,
  } = useInput("", "cnpj", "cnpj");

  const {
    value: passwordValue,
    bind: bindPasswordValue,
    reset: resetPasswordValue,
    check: checkPassword,
    isInvalid: invalidPassword,
  } = useInput("", "");

  const removeDoc = (index) => {
    let doc = documentos[index]?.num_doc;

    CertificadoService.deletarDocumento(tokenLogin, certificado.nome, doc)
      .then((resp) => {
        const list = [...documentos];

        list.splice(index, 1);
        setDocumentos(list);

        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Documento removido!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        console.error(err.response.data.descricao);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  const addNewDoc = (doc) => {
    setAdicionaNovo(true);
    if (invalidCnpj || checkCnpj()) return;

    let ehCnpj = cnpjValue.length > 14;

    mixpanel.track("Adiciona Doc", {
      source: "Configuracoes",
    });

    CertificadoService.adicionarDocumento(
      tokenLogin,
      certificado.nome,
      ehCnpj,
      cnpjValue
    )
      .then((resp) => {
        resetcnpjValue();

        setDocumentos([
          ...documentos,
          { num_doc: cnpjValue, ehCnpj: ehCnpj, removendo: false },
        ]);

        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Documento adicionado!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        console.error(err.response.data.descricao);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addNewDoc();
    }
  };

  const atualizarSenha = () => {
    console.log(tokenLogin, certificado.nome, passwordValue);

    mixpanel.track("Atualizou senha certificado", {
      certificado: certificado.nome,
    });

    CertificadoService.atualizarSenhaCertificado(
      tokenLogin,
      certificado.nome,
      passwordValue
    )
      .then(() => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Senha do certificado atualizada!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        console.error(err.response.data.descricao);

        if (err.response && err.response.data)
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                {err.response.data.descricao}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
      });
  };

  return (
    <CertificadosCadastrados key={certificado.id}>
      {isEditing ? (
        <>
          <div>
            <div className="header">
              <p>{certificado.nome}</p>
            </div>
            <div className="flex align-center">
              {attSenha ? (
                <>
                  <FormInput
                    mt={16}
                    // width={320}
                    type="password"
                    id="senha"
                    label="Nova senha"
                    errorMsg=""
                    {...bindPasswordValue}
                  />
                  <Button mt={16} onClick={atualizarSenha}>
                    Atualizar senha
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => setAttSenha(true)}
                  secondary
                  color="primary"
                  bg="primaryLight"
                  mt={16}
                >
                  Atualizar senha
                </Button>
              )}
            </div>
            <div className="docs">
              {documentos.length && documentos[0].num_doc ? (
                documentos.map((novoDoc, index) => (
                  <div className="flex-docs">
                    <p>
                      {novoDoc.removendo
                        ? novoDoc.num_doc
                        : cnpjMask(novoDoc.num_doc)}
                    </p>
                    <Button
                      onClick={() => removeDoc(index)}
                      secondary
                      size="small"
                      bg="dangerLight"
                      color="danger"
                    >
                      {novoDoc.removendo ? "Desfazer" : "Remover empresa"}
                    </Button>
                  </div>
                ))
              ) : (
                <></>
              )}
              {adicionaNovo && (
                <div className="flex">
                  <FormInput
                    ref={newDocRef}
                    required
                    mt={16}
                    label="CNPJ ou CPF"
                    error={invalidCnpj}
                    errorMsg="Digite um documento válido"
                    width={200}
                    onKeyDown={handleKeyDown}
                    {...bindCnpjValue}
                  />
                  <Button
                    onClick={() => setAdicionaNovo(false)}
                    mt={36}
                    secondary
                    small
                    bg="dangerLight"
                    color="text"
                  >
                    Cancelar
                  </Button>
                </div>
              )}
              <Button
                onClick={addNewDoc}
                mt={16}
                secondary
                color="primary"
                bg="primaryLight"
                size="small"
                iconLeft
              >
                <AddIcon /> Adicionar empresa
              </Button>
            </div>
            <div className="actions-flex">
              <Button
                onClick={() => setIsEditing(false)}
                secondary
                small
                bg="dangerLight"
                color="text"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </>
      ) : isDeleting ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="header">
            <p>{certificado.nome}</p>
          </div>
          <p style={{marginTop: 32, marginBottom: 16}}>Tem certeza que deseja excluir esse certificado?</p>
          <div className="flex">
            <Button
              onClick={() => setIsDeleting(false)}
              secondary
              small
              bg="grayLight"
              color="text"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => deletarCertificado(certificado.nome)}
              size="small"
              secondary
              bg="dangerLight"
              color="danger"
              mt={4}
            >
              Excluir
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="header">
              <p>{certificado.nome}</p>
            </div>
            <div className="docs">
              <p className="label">Documentos de Procurador</p>
              {!certificado.docs.length ? (
                <p>Nenhum documento</p>
              ) : (
                certificado.docs.map((doc, index) => (
                  <p>{cnpjMask(doc.num_doc)}</p>
                ))
              )}
            </div>
          </div>
          <div className="actions">
            <Button
              onClick={() => setIsDeleting(true)}
              size="small"
              secondary
              bg="dangerLight"
              color="danger"
              mt={4}
            >
              Excluir
            </Button>
            <Button
              onClick={() => setIsEditing(true)}
              size="small"
              secondary
              bg="primaryLight"
              color="primary"
            >
              Editar
            </Button>
          </div>
        </>
      )}
    </CertificadosCadastrados>
  );
};
