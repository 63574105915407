import styled, { keyframes } from "styled-components"

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    
    50% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
`

export const StyledLoadingIcon = styled.div`
  display: inline-block;

  svg {
    width: ${props => props.size === "big" ? "48px" : props.size === "md" ? "32px" : "16px"};
    animation: ${spin} 850ms linear infinite;
    fill: ${props => 
        props.fill ? props.theme[props.fill] : props.theme.text};
  }
`