import React, { useState } from "react";

import * as S from "./style";

export default function RadioInput({
  name,
  label,
  value,
  isChecked,
  handleChange,
  size
}) {
  const handleRadioChange = (e) => {
    const { id } = e.currentTarget;
    handleChange(id);
  };

  return (
    <S.RadioButton $size={size} $checked={isChecked}>
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={isChecked}
        onChange={handleRadioChange}
      />
      {label}
    </S.RadioButton>
  );
}
