import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 32px 72px;
  background: ${(p) => p.theme.background};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
    line-height: 48px;
    color: ${(p) => p.theme.text};

    span {
      margin-left: 16px;
      color: ${(p) => (p.$vendas ? p.theme.primary : p.theme.secondary)};
    }
  }
`;

export const ModalProrroga = styled.div`
  h3 {
    font-size: 28px;
    margin-bottom: 24px;
  }

  .info-prorroga {
    p {
      font-size: 18px;
      color: ${(p) => p.theme.textDarkGray};
      margin-top: 16px;

      span {
        color: ${(p) => p.theme.text};
      }
    }
  }

  .flex {
    font-weight: 600;
    margin-top: 24px;
    display: flex;
    align-items: center;
    font-size: 18px;

    p:first-child {
      margin-right: 8px;
    }
    p:last-child {
      margin-left: 8px;
    }
  }

  .flex + p {
    margin-top: 8px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
`;

export const PedidosWrapper = styled.div`
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${(p) => p.theme.text};
    margin-bottom: 24px;
  }
`;

export const LoadingBox = styled.div`
  height: 100%;
  width: 100%;
  margin: -1.5em -3em;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Pedidos = styled(motion.div)`
  margin-top: 24px;
`;

export const PedidosButton = styled.button`
  background: #ffffff;
  border: 1px solid #eceff4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 20px;
  line-height: 28px;
  color: ${(p) => p.theme.text};
  cursor: pointer;
  transition: all 200ms ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  }

  span.novo {
    position: absolute;
    top: 0;
    left: 0;
    background: ${(p) => p.theme.primary};
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    padding: 2px 4px;
    transform: translate(-15%, -65%);
  }

  span.numPedidos {
    margin-left: 10px;
    color: ${(p) => p.theme.primary};
    background: #c0e1ff;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    padding: 4px;
    display: inline-block;
  }
`;

export const TableSection = styled.section`
  display: flex;
  margin: 64px 0 32px;
  align-items: center;

  button:first-child {
    margin-right: 32px;
  }

  .alignLeft {
    justify-self: flex-end;
    margin-left: auto;
  }
`;

export const ExpiredPill = styled.span`
  font-size: 14px;
  color: #81051a;
  background: rgba(189, 0, 0, 0.15);
  border-radius: 4px;
  padding: 2px 8px;
  line-height: 20px;
  display: inline-block;
  transform: translateY(-6px);
`;

export const ActionButton = styled.button`
  display: inline-block;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  padding: 4px;
  transition: all 200ms ease-in-out;
  color: #909090;
  cursor: pointer;

  &:hover {
    color: ${(p) => (p.color ? p.theme[p.color] : p.theme.text)};
  }
`;

export const AditionalInfo = styled.span`
  font-size: 14px;
  color: ${(p) => (p.$error ? p.theme.danger : "#5a5c63")};
  display: block;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ModalConsulta = styled.div`
  h3 {
    font-size: 28px;
    margin-bottom: 24px;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 24px;
  }

  .info {
    margin-bottom: 16px;
  }

  box-sizing: border-box;
`;

export const UsuariosVenda = styled.p`
  font-size: 18px;
  width: 100%;
  font-weight: ${(p) => (p.contato ? "600" : "450")};
  margin-bottom: 16px;
  padding: 8px 16px;
  background: ${(p) => p.theme.grayLight3};
  border-radius: 0 12px;
  border: 2px solid ${(p) => p.theme.input.border};
  color: ${(p) => p.theme.text};
  box-sizing: border-box;
`;
