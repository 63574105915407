import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { set } from "date-fns";
import ArquiveiService from "../../services/ArquiveiService";
import { useInput } from "../../hooks/useInput";
import FormInput from "../@DesignSystem/FormInput";
import Button from "../@DesignSystem/Button";

import { store } from "react-notifications-component";
import { notificationTopRight, ToastNotification } from "../ToastNotification";
import CredencialArquivei from "./CredencialArquivei";

const Wrapper = styled.div`
  .title {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 2.375rem;
      margin-bottom: 4px;
    }
    p {
      color: #6c757d;
      font-size: 14px;
    }
  }

  .novaCredencial {
    padding: 16px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 64px;
    background-color: #f8f9fa;
    max-width: 400px;

    h4 {
      font-size: 1.25rem;
      margin-bottom: 32px;
      font-weight: 600;
    }
  }
`;

// const mockResp = {
//   statusCode: 200,
//   msg: "Operação sucedida",
//   descricao: "Credenciais selecionados com sucesso",
//   credenciais: [
//     {
//       id: "174196b0-ff44-46b8-8ea5-a9702f58d231",
//       nome: "Credenciais Teste",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "732f5777-7156-47b6-ba18-73dd3f26c05a",
//       nome: "Credencial Teste",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "96411d0f-5ae2-45f6-a4c0-af5cb755a7f1",
//       nome: "Credencial Teste 4",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "ab2ffb9e-857b-46db-94a2-4deb8225ffef",
//       nome: "Credencial Teste 3",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//     {
//       id: "cf4647f3-aabd-4933-8b5e-b6bef595904a",
//       nome: "Credencial Teste 2",
//       id_api: "4cb7e8d38bfb246a76d2fff36d8a86484230f06c",
//     },
//   ],
// };

const Arquivei = ({ tokenLogin }) => {
  const [credenciais, setCredenciais] = useState([]);
  const {
    value: apiValue,
    bind: bindApiValue,
    reset: resetApiValue,
    check: checkApi,
    isInvalid: invalidApi,
  } = useInput("", "");
  const {
    value: nomeValue,
    bind: bindNomeValue,
    reset: resetNomeValue,
    check: checkNome,
    isInvalid: invalidNome,
  } = useInput("", "");
  const {
    value: chaveValue,
    bind: bindChaveValue,
    reset: resetChaveValue,
    check: checkChave,
    isInvalid: invalidChave,
  } = useInput("", "");

  const getCredenciais = () => {
    // API call
    ArquiveiService.getCredenciais(tokenLogin)
      .then((response) => {
        console.log(response);
        setCredenciais(response.data.credenciais);
      })
      .catch((error) => {
        console.log(error);
        setCredenciais([]);
      });
  };

  const criarCredencial = () => {
    // API call
    ArquiveiService.criarCredencial(tokenLogin, nomeValue, chaveValue, apiValue)
      .then((response) => {
        console.log(response);
        getCredenciais();
        resetApiValue();
        resetNomeValue();
        resetChaveValue();
        store.addNotification({
          ...notificationTopRight,
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Credencial nova cadastrada!
            </ToastNotification>
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        store.addNotification({
          ...notificationTopRight,
          content: (
            <ToastNotification
              bg="danger"
              textoAuxiliar="Tente novamente mais tarde"
            >
              Erro ao cadastrar nova credencial
            </ToastNotification>
          ),
        });
      });
  };

  useEffect(() => {
    getCredenciais();
  }, []);

  return (
    <Wrapper>
      <div className="title">
        <h3>Arquivei</h3>
        <p>
          A integração com a Arquivei permite que você tenha acesso a todas as
          suas notas fiscais de forma rápida e segura.
          Permite fazer upload de arquivos <b>NFe Entrada, NFe Saída, CFe, CTe e Evento NFe.</b>
        </p>
      </div>
      {credenciais.length === 0 ? (
        <p>
          Nenhuma credencial da Arquivei cadastrada. Crie uma nova credencial
          abaixo para importar arquivos.
        </p>
      ) : (
        credenciais.map((credencial) => (
          <CredencialArquivei credencial={credencial} tokenLogin={tokenLogin} getCredenciais={getCredenciais} />
        ))
      )}
      <div className="novaCredencial">
        <h4>Criar credencial</h4>
        <FormInput
          required
          mt={16}
          label="ID API"
          error={invalidApi}
          errorMsg=""
          {...bindApiValue}
        />
        <FormInput
          required
          mt={16}
          label="Chave"
          error={invalidChave}
          type="password"
          errorMsg=""
          {...bindChaveValue}
        />
        <FormInput
          required
          mt={16}
          label="Nome da credencial"
          error={invalidNome}
          errorMsg=""
          {...bindNomeValue}
        />
        <Button
          mt={32}
          onClick={() => {
            checkApi();
            checkNome();
            checkChave();
            if (
              !invalidApi &&
              !invalidNome &&
              !invalidChave &&
              apiValue &&
              nomeValue &&
              chaveValue
            ) {
              criarCredencial();
            }
          }}
        >
          Criar credencial
        </Button>
      </div>
    </Wrapper>
  );
};

export default Arquivei;
