import { useState } from "react";

export const useInput = (initialValue, maskType = null, errorType = maskType) => {
  const [value, setValue] = useState(initialValue);
  const [isInvalid, setIsInvalid] = useState(false);

  const error = {
    email: function (value) {
      return !value.match(/\S+@\S+\.\S+/);
    },
    tel: function (value) {
      return value.length < 14;
    },
    cnpj: function (value) {
      return value.length !== 14 && value.length !== 18;
    },
    cep: function (value) {
      return value.length < 9;
    },
    estado: function (value) {
        return value.length !== 2;
    },
    vazio: function (value) {
      return value.length < 1;
    },
    alwaysValid: function () {
      return false;
    },
  };

  const mask = {
    tel: function (value) {
      let v = value.replace(/\D/g, "");
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

      if (value.length < 15) v = v.replace(/(\d{4})(\d)/, "$1-$2");
      else v = v.replace(/(\d{5})(\d)/, "$1-$2");

      v = v.substring(0, 15);

      return v;
    },
    cnpj: function (value) {
      let v = value.replace(/\D/g, "");
      if (value.length < 15) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        // v = v.replace(/(\d{2})(\d)/, "$1-$2");
        v = v.substring(0, 18);
        return v;
      }

      v = v.replace(/(\d{2})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
      v = v.substring(0, 18);

      return v;
    },
    cep: function (value) {
      let v = value.replace(/\D/g, "");
      v = v.replace(/(\d{5})(\d)/, "$1-$2");
      v = v.substring(0, 9);

      return v;
    },
    estado: function (value) {
      let v = value.replace(/[^a-zA-Z]/g, "");
      // v = v.replace(/(\d{2})(\d)/, "$1-$2");
      v = v.substring(0, 2);
      v = v.toUpperCase();

      return v;
    },
  };

  return {
    value,
    setValue,
    isInvalid,
    setIsInvalid,
    check: () => {
      setIsInvalid((errorType in error) ? error[errorType](value || "") : (value.length < 1));
      return (errorType in error) ? error[errorType](value || "") : (value.length < 1);
    },
    reset: () => {
      setValue("");
      setIsInvalid(false);
    },
    bind: {
      value,
      onChange: (event) => {
        setValue(
          maskType in mask
            ? mask[maskType](event.target.value)
            : event.target.value
        );
        if (isInvalid) {
          setIsInvalid((errorType in error) ? error[errorType](event.target.value) : (event.target.value.length < 1));
        }
      },
      onBlur: (event) => {
        setIsInvalid((errorType in error) ? error[errorType](event.target.value) : (event.target.value.length < 1));
      },
    },
  };
};
