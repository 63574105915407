import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  border: none;
  white-space: nowrap;
  padding: ${props => props.link ? ".125rem .25rem" : "0.5em 1.5em"};
  border-radius: 0;
  cursor: pointer;
  color: ${(props) =>
    props.background === "gray" || props.background === "secondary"
      ? props.theme.text
      : props.theme.background};
  font-size: 1rem;
  transition: all 0.3s ease;
  display: ${props => props.block ? "block" : "inline-block"};
  margin-top: ${props => props.mt ? props.mt : 0}rem;
  margin-left: ${props => props.ml ? props.ml : 0}rem;
  margin-right: ${props => props.mr ? props.mr : 0}rem;
  margin-bottom: ${props => props.mb ? props.mb : 0}rem;
  box-shadow: ${(props) =>
    ({
      primary: "0px 12px 20px -12px rgba(0, 70, 215, 0.40);",
      secondary: "0px 12px 20px -12px rgba(255, 110, 75, 0.40);",
      danger: "0px 12px 20px -12px rgba(151, 0, 25, 0.40);",
      gray: "0px 12px 20px -12px rgba(239, 239, 239, 0.40);",
      success: `0px 12px 20px -12px ${props.theme.successLight};`,
    }[props.background])};
  background-color: ${(props) =>
    ({
      primary: props.theme.primary,
      secondary: props.theme.secondary,
      danger: props.theme.dangerDark,
      gray: props.theme.grayLight,
      success: props.theme.success,
    }[props.background])};

  ${(props) => {
    if (props.stretch) {
      return `width: 100%`
    }
    if (props.invert) {
      return `
        background-color: ${props.theme.background};
        color: ${props.theme.text};
        box-shadow: none;
    `;
    }
    if (props.link) {
      return `
        background: none;
        color: ${
          {
            primary: props.theme.primaryDark,
            secondary: props.theme.secondaryDark,
            danger: props.theme.dangerDark,
            gray: props.theme.grayDark,
          }[props.background]
        };
        text-decoration: underline;
        box-shadow: none;
    `;
    }
  }}

  svg.icon {
    margin-right: 0.5rem;
    transform: translateY(2px);
  }

  &:hover {
    background-color: ${(props) =>
      ({
        primary: props.theme.primaryDark,
        secondary: props.theme.secondaryDark,
        danger: props.theme.danger,
        gray: props.theme.grayLight2,
      }[props.background])};

    ${(props) => {
      if (props.invert) {
        return `
      background-color: ${
        {
          primary: props.theme.primary,
          secondary: props.theme.secondary,
          danger: props.theme.danger,
          gray: props.theme.lightGray,
        }[props.background]
      };
        color: ${props.theme.background};
    `;
      }
      if (props.link) {
        return `
      background-color: ${props.theme.grayLight};
    `;
      }
    }}
  }
`;

const Button = (props) => (
  <StyledButton
    ref={props.forwardRef}
    onClick={props.onClick}
    background={props.background}
    invert={props.invert}
    link={props.link}
    stretch={props.stretch}
    block={props.block}
    mt={props.mt}
    ml={props.ml}
    mb={props.mb}
    mr={props.mr}
    disabled={props.disabled}
  >
    {props.children}
  </StyledButton>
);

export default Button;
