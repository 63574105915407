import api from "./config/Axios";

export default class ArquiveiService {
  static async getCredenciais(token) {
    //    {"credenciais": [
    //     {
    //         "id": "174196b0-ff44-46b8-8ea5-a9702f58d231",
    //         "nome": "Credenciais Teste",
    //         "id_api": "4cb7e8d38bfb246a76d2fff36d8a86484230f06c"
    //     },]}
    return api({
      method: "get",
      url: `/credenciais/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async criarCredencial(token, nome, chave, id_api) {
    return api({
      method: "post",
      url: `/credenciais/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_api: id_api,
        nome: nome,
        chave: chave,
      },
    });
  }

  static async atualizarSenhaCredenciais(token, nome, chave) {
    return api({
      method: "patch",
      url: `/credenciais/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        nome: nome,
        chave: chave,
      },
    });
  }

  static async deletarCredencial(token, nome) {
    return api({
      method: "delete",
      url: `/credenciais/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        nome: nome,
      },
    });
  }
}
