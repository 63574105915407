import api from './config/Axios';
export default class PowerBIService {
    static async getEmbedTokenPowerBi(token, report) {
        return api({
            method: 'get',
            url: `/reports/${report}/embed`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
    }

    static async getListaReports(token) {
        return api({
            method: 'get',
            url: `/reports`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
    }

    static async refreshPowerBI(token) {
        return api({
            method: 'post',
            url: `/refresh/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
    }

    static async downloadDashboard(token, onProgress, nome, formato) {
        return api({
            method: 'get',
            url: `/reports/download/`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "report-name": nome,
                "formato": formato,
            },
            onDownloadProgress: onProgress,
            responseType: 'blob',
        });
    }
}