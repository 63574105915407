/*
Venda do marketplace
Infos recebidas: 
email
plano
contrato

tipo de login SSO

Infos para preencher:
CNPJ ou CPF
Nome empresa
Nome contato
Telefone
Nome do banco

Sem config avançada
*/

import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";

import logoTaxcel from "./images/Logo TaxDashs.png";

import AOS from "aos";
import "aos/dist/aos.css";
import FormInput from "./@DesignSystem/FormInput";
import { useInput } from "../hooks/useInput";
import Button from "./@DesignSystem/Button";
import AuthService from "../services/AuthService";
import VendaService from "../services/VendaService";
import { format } from "date-fns";
import { store } from "react-notifications-component";
import { ToastNotification, notificationTopCenter } from "./ToastNotification";

import LoadingIcon from "./@DesignSystem/LoadingIcon";

import { ReactComponent as AcceptIcon } from "../icons/accept-icon.svg";

AOS.init();

const start = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 800px;
  margin: 3rem auto;
`;

const Loading = styled.div`
  text-align: center;
  padding: 2rem 0;
  grid-column: 1 / span 2;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  svg {
    width: 4rem;
  }
`;

const Logo = styled.a`
  display: block;
  width: 160px;
  margin: 3rem auto;
  text-align: center;

  img {
    width: 100%;
  }
`;

const Venda = styled.div`
  background: #ffffff;
  border: 1px solid #eceff4;
  padding: 24px 32px;
  width: 800px;
  border-radius: 1rem;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.05);
  margin: 2rem auto;
  box-sizing: border-box;
  opacity: 0;
  animation: ${start} 300ms ease-out 200ms forwards;

  display: grid;
  gap: 32px 48px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  .header {
    grid-column: 1 / span 2;

    h2 {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 27px;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }
`;

const Plano = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f5f5f5;
  margin-top: 32px;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    color: ${(p) => p.theme.text};
  }

  .grid {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 16px;
    gap: 8px;

    p {
      font-size: 16px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.65);
    }

    p:nth-child(even) {
      margin-left: 8px;
      font-weight: 600;
    }
  }

  .info {
    display: flex;
    align-items: center;

    p:last-child {
      margin-left: 8px;
      font-weight: 600;
    }

    p.extra-margin {
      margin-left: 34px;
    }
  }
`;

const Success = styled.div`
  grid-column: 1 / span 2;
  text-align: center;

  svg {
    width: 80px;
    height: 80px;
    color: ${(p) => p.theme.primary};
    margin: 2rem auto;
  }
  h5 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 48px;
    font-size: 16px;
  }
`;

const FooterCopyright = styled.footer`
  margin: 0;
  padding: 0.75em 0;
  text-align: center;
`;

export default function VendaMarketplace(props) {
  const [tokenVenda, setTokenVenda] = useState("");
  const [email, setEmail] = useState("");
  const [capacidade, setCapacidade] = useState();
  const [usuarios, setUsuarios] = useState();
  const [semBanco, setSemBanco] = useState(false);
  const [plano, setPlano] = useState("");
  const [duracao, setDuracao] = useState("");
  const [dataValidade, setDataValidade] = useState("");
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const [isCarregando, setCarregando] = useState(true);
  const [erroToken, setErroToken] = useState(false);
  const [vendaNaoEncontrada, setVendaNaoEncontrada] = useState(false);
  const [criandoVenda, setCriandoVenda] = useState(false);

  let history = useHistory();

  const year = new Date();

  const getInfoVenda = (token) => {
    AuthService.getInfoNovaVenda(token)
      .then((resp) => {
        console.log(resp);
        let venda = resp.data.venda;
        if(!venda.status) {
          setEmail(venda.email);
          setPlano(venda.nome);
          setCapacidade(venda.capacidade_maxima);
          setUsuarios(venda.qtd_usuarios);
          let duracao_anos = `${Math.floor(venda.duracao / 12)} ano(s)`;
          setDuracao(duracao_anos);
          let duracao_meses = venda.duracao % 12;
          if (duracao_meses > 0)
            setDuracao(duracao_anos + ` e ${duracao_meses} meses`);
          let validade = new Date();
          validade = validade.setMonth(validade.getMonth() + venda.duracao);
          setDataValidade(format(validade, "dd/MM/yyyy"));
        } else {
          setCadastroRealizado(true);
        }
        setCarregando(false);
        
      })
      .catch((err) => {
        console.log(err);
        setCarregando(false);
        setVendaNaoEncontrada(true);
        // store.addNotification({
        //   content: (
        //     <ToastNotification bg="danger" textoAuxiliar="">
        //       Não foi possível identificar uma venda. Verifique se a compra
        //       ainda está ativa e pendente de configuração.
        //     </ToastNotification>
        //   ),
        //   onRemoval: (id, removedBy) => {
        //     console.log(removedBy);
        //     if (removedBy === "click") {
        //       history.push("/");
        //     }
        //   },
        //   ...notificationTopCenter,
        // });
      });
  };

  const criarVenda = () => {
    let cliente = {
      cnpj: cnpjValue.replace(/\D/g, ""),
      nome_contato: nomeValue,
      razao_social: nomeEmpresaValue,
      telefone: telefoneValue.replace(/\D/g, ""),
      tipo: "1",
    };
    let usuario = {
      nome_banco: nomeBancoValue,
      novo_banco: !semBanco,
    };
    let obj = {
      cliente,
      usuario,
    };
    console.log(tokenVenda);
    setCriandoVenda(true);
    VendaService.criarVendaMarketplace(obj, tokenVenda)
      .then((resp) => {
        console.log(resp);
        setCriandoVenda(false);
        setCadastroRealizado(true);
      })
      .catch((err) => {
        console.log(err);
        setCriandoVenda(false);
        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Erro ao criar venda! Verifique se todas as informações são
              válidas.
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => {
            console.log(removedBy);
          },
          ...notificationTopCenter,
        });
      });
  };

  useEffect(() => {
    // const { token } = props.match.params;
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    console.log({token});

    // setIsModalOpen(localStorage.getItem("esquecerAviso") !== "true");

    setTokenVenda(token);

    getInfoVenda(token);
  }, []);

  const {
    value: cnpjValue,
    bind: bindCnpjValue,
    reset: resetcnpjValue,
    check: checkCnpj,
    isInvalid: invalidCnpj,
  } = useInput("", "cnpj", "cnpj");

  const {
    value: nomeValue,
    bind: bindNomeValue,
    reset: resetnomeValue,
    setValue: setnomeValue,
    isInvalid: invalidNome,
  } = useInput("", "", "vazio");

  const {
    value: nomeEmpresaValue,
    bind: bindNomeEmpresaValue,
    reset: resetnomeEmpresaValue,
    setValue: setnomeEmpresaValue,
    isInvalid: invalidNomeEmpresa,
  } = useInput("", "", "vazio");

  const {
    value: telefoneValue,
    bind: bindTelefoneValue,
    reset: resettelefoneValue,
    setValue: settelefoneValue,
    isInvalid: invalidTelefone,
  } = useInput("", "tel", "tel");

  const {
    value: nomeBancoValue,
    bind: bindNomeBancoValue,
    setValue: setNomeBanco,
    reset: resetnomeBancoValue,
  } = useInput("", "", "");

  const sendForm = () => {
    if (
      invalidCnpj ||
      !cnpjValue ||
      invalidNome ||
      !nomeValue ||
      invalidNomeEmpresa ||
      !nomeEmpresaValue ||
      invalidTelefone ||
      !telefoneValue
    )
      return;

    criarVenda();
  };

  return (
    <Container>
      <Logo href="https://www.taxcel.com.br/">
        <img src={logoTaxcel} alt="Logo Taxcel"></img>
      </Logo>
      <Venda>
        {(isCarregando || criandoVenda) ? (
          <Loading>
            <h4>{!criandoVenda ? "Pesquisando venda" : "Criando venda"}</h4>
            <LoadingIcon />
          </Loading>
        ) : vendaNaoEncontrada ? (
          <Loading>
            <h4>Venda não encontrada</h4>
            <p>
              Verifique se a compra ainda está ativa e pendente de configuração.
            </p>
            <Button center mt={32} onClick={() => history.push("/")}>
              Ir para login
            </Button>
          </Loading>
        ) : !cadastroRealizado ? (
          <>
            <div className="header">
              <h2>Venda do marketplace</h2>
            </div>
            <div className="dados">
              <h3>Dados de cadastro</h3>
              <FormInput
                mb={32}
                mt={16}
                label="Email do administrador"
                readonly={true}
                error={false}
                errorMsg=""
                value={email}
              />
              <FormInput
                required
                mb={32}
                label="CPF ou CNPJ"
                error={invalidCnpj}
                errorMsg="Campo obrigatório"
                {...bindCnpjValue}
              />
              <FormInput
                required
                mb={32}
                label="Nome"
                error={invalidNome}
                errorMsg="Campo obrigatório"
                {...bindNomeValue}
              />
              <FormInput
                required
                mb={32}
                label="Empresa"
                error={invalidNomeEmpresa}
                errorMsg="Campo obrigatório"
                {...bindNomeEmpresaValue}
              />
              <FormInput
                required
                mb={8}
                label="Telefone"
                error={invalidTelefone}
                errorMsg="Campo obrigatório"
                {...bindTelefoneValue}
              />
            </div>
            <div className="flex">
              <h3>Plano adquirido</h3>
              <Plano>
                <p className="title">{plano}</p>
                <div className="grid">
                  <p>Quantidade de usuários:</p>
                  <p>{usuarios} usuários</p>
                  <p>Capacidade máxima:</p>
                  <p className="extra-margin">{capacidade}GB</p>
                  <p>Duração:</p>
                  <p className="extra-margin">{duracao}</p>
                  <p>Venda válida até:</p>
                  <p className="extra-margin">{dataValidade}</p>
                </div>
              </Plano>
              <FormInput
                required
                mb={16}
                mt={28}
                label="Nome do banco"
                error={false}
                errorMsg="Campo obrigatório"
                readonly={semBanco}
                placeholder="Banco de Taxcel"
                {...bindNomeBancoValue}
                value={semBanco ? "" : nomeBancoValue}
              />
              <Button
                secondary
                color="primary"
                bg="primaryLight"
                center
                onClick={() => setSemBanco(!semBanco)}
              >
                {semBanco ? "Criar banco" : "Criar banco depois"}
              </Button>
              <Button mt={"auto"} mb={8} fullWidth onClick={sendForm}>
                Finalizar cadastro
              </Button>
            </div>
          </>
        ) : (
          <Success>
            <AcceptIcon />
            <h5>Cadastro realizado com sucesso!</h5>
            <p>
              Verifique seu email <b>{email}</b> para acessar sua conta.
            </p>
            <Button center mt={32} onClick={() => history.push("/")}>
              Ir para login
            </Button>
          </Success>
        )}
      </Venda>

      <FooterCopyright>
        <p>Taxcel © {year.getFullYear()} - Todos os direitos reservados <a href="https://api.taxcelapp.com/docs/">Documentação API</a></p>
      </FooterCopyright>
    </Container>
  );
}
