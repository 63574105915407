import React from "react";

import * as S from "./style";

export default function Title({
  children,
  headingLevel,
  variant = headingLevel,
  mt = 0,
  mb
}) {
  const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : "";

  const titleTag = validHeadingLevels.includes(safeHeading) ? safeHeading : "p";

  return (
    <S.StyledTitle as={titleTag} variant={variant} mt={mt} mb={mb}>
      {children}
    </S.StyledTitle>
  );
}
