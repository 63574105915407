import React, { useEffect } from "react";
import styled from "styled-components/macro";

import sound from "../sounds/notification.mp3";
// import UIfx from "uifx";

// const notSound = new UIfx({
//   asset: sound,
// });

const NotificationWrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 2rem;
  position: relative;
  word-wrap: break-word;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
  ${(props) => {
    if (props.textCenter) {
      return `text-align: center;`
    }
  }}

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: ${(props) =>
      ({
        primary: props.theme.primary,
        secondary: props.theme.secondary,
        danger: props.theme.dangerDark,
        gray: props.theme.lightGray,
        yellow: props.theme.terciary,
        success: props.theme.success,
      }[props.background])};
    left: 0;
    top: 0;
  }
`;

const Fechar = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray};
  margin-top: 1rem;
  margin-bottom: 0;
`;

export const notificationTopRight = {
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeInRightBig"],
  animationOut: ["animate__animated", "animate__fadeOutRightBig"],
  dismiss: {
    duration: 5000,
    pauseOnHover: true,
  },
};

export const notificationTopCenter = {
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeInDown"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 8000,
    pauseOnHover: true,
  },
  width: 420,
};

const audio = new Audio(sound);

export function ToastNotification(props) {
  useEffect(() => {
    // notSound.play();
    audio.volume = 0.35;
    // audio.play();
  }, []);

  return (
    <NotificationWrapper textCenter={props.textCenter} background={props.bg || "primary"}>
      <p>{props.children}</p>
      <Fechar>{props.textoAuxiliar || "Clique para fechar"}</Fechar>
    </NotificationWrapper>
  );
}
