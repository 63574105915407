import styled from "styled-components";

export const StyledSwitch = styled.label`
  display: inline-flex;
  align-items: center;

  input {
    display: none;

    &:checked + label {
      /* background: ${(p) => p.theme.primary}; */
      background: rgba(0, 70, 215, 0.75);
    }

    &:checked + label:after {
      left: 50%;
    }
  }

  span {
    color: ${(p) => p.theme.textDarkerGray};
    font-size: ${(props) => (props.size === "small" ? "16px" : "20px")};
    margin-left: 8px;
  }

  label {
    outline: 0;
    display: block;
    width: ${(props) => (props.size === "small" ? "72px" : "80px")};
    height: ${(props) => (props.size === "small" ? "32px" : "40px")};
    position: relative;
    cursor: pointer;
    user-select: none;
    background: ${(props) => props.theme.grayLight};
    border-radius: ${(props) => (props.size === "small" ? "0 4px" : "0 8px")};
    padding: 4px;
    transition: all 0.4s ease;
    box-sizing: border-box;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: ${(props) => (props.size === "small" ? "32px" : "36px")};
      height: 100%;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    }

    &:after {
      left: 0;
      border-radius: ${(props) => (props.size === "small" ? "0 4px" : "0 8px")};
      background: #fff;
      transition: all 0.2s ease;
    }

    &:before {
      display: none;
    }
  }
`;
