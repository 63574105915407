import React, { useEffect, useState } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components/macro";

import { GlobalStyle, themeDefault } from "./theme/GlobalStyle";

import AOS from "aos";
import "aos/dist/aos.css";

import logoTaxcel from "./components/images/Logo TaxDashs.png";
import { Login } from "./components/landing-page/Login";
import { DefaultButton } from "../src/theme/GlobalStyle";
import LoadingIcon from "./components/LoadingIcon";
import RecuperaSenha from "./components/landing-page/RecuperaSenha";
import CriaSenha from "./components/landing-page/CriaSenha";
import { Link } from "react-router-dom";

import DSButton from "./components/@DesignSystem/Button";
import Modal from "./components/Modal";
import Title from "./components/@DesignSystem/Title";
import Button from "./components/@DesignSystem/Button";

AOS.init();

const start = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 680px;
  margin: 3rem auto;
`;

const Logo = styled.a`
  display: block;
  width: 160px;
  margin: 3rem auto;
  text-align: center;

  img {
    width: 100%;
  }
`;

const LoginContainer = styled.div`
  border-radius: 1rem;
  width: 360px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.05);
  margin: 2rem auto;
  padding: 2rem;
  box-sizing: border-box;
  opacity: 0;
  animation: ${start} 300ms ease-out 200ms forwards;
`;

const Registrar = styled.div`
  margin-top: 4rem;
  text-align: center;
  font-size: 1.25rem;

  p {
    margin: 1.5rem;
  }
`;

const FooterCopyright = styled.footer`
  margin: 0;
  padding: 0.75em 0;
  text-align: center;

  a {
    margin-left: 48px;
  }
`;

const Loading = styled.div`
  text-align: center;

  svg {
    width: 4rem;
  }
`;

export default function Home(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tokenRecupera, setTokenRecupera] = useState("");
  
  const year = new Date();

  useEffect(() => {
    const { token } = props.match.params;
    // setIsModalOpen(localStorage.getItem("esquecerAviso") !== "true");

    setTokenRecupera(token);
    console.log(token);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const apagarAviso = () => {
    toggleModal();

    localStorage.setItem("esquecerAviso", "true");
  }

  return (
    <>
      <ThemeProvider theme={themeDefault}>
        <div>
          <GlobalStyle />
          {/* {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={toggleModal}>
              <Title headingLevel="h3">Aviso de manutenção</Title>
              <p>
                O TaxDashs começará uma manutenção no dia{" "}
                <b>01/07 (sexta feira)</b> e poderá ter instabilidades enquanto isso.
              </p>
              <Button secondary color="primary" bg="primaryLight" mt={24} center onClick={apagarAviso}>Não mostrar novamente</Button>
            </Modal>
          )} */}
          <Container>
            <Logo href="https://www.taxcel.com.br/">
              <img src={logoTaxcel} alt="Logo Taxcel"></img>
            </Logo>
            {props.tentouLogin ? (
              <>
                <LoginContainer>
                  {props.login ? (
                    <Login doLogin={props.doLogin} />
                  ) : props.confirmar ? (
                    <CriaSenha token={tokenRecupera} />
                  ) : (
                    <RecuperaSenha token={tokenRecupera} />
                  )}
                </LoginContainer>
                <Registrar>
                  <p>
                    Ainda não possui uma conta
                    <br /> mas tem interesse na ferramenta?
                  </p>
                  <DSButton
                    ghost
                    bg="primary"
                    color="primary"
                    center
                    onClick={() => window.location.replace("https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview")}
                  >
                    Fazer cadastro
                  </DSButton>
                </Registrar>
              </>
            ) : (
              <Loading>
                <LoadingIcon />
              </Loading>
            )}
          </Container>
          <FooterCopyright>
            <p>Taxcel © {year.getFullYear()} - Todos os direitos reservados <a href="https://api.taxcelapp.com/docs/">Documentação API</a><Link to="/status">Status</Link></p>
          </FooterCopyright>
        </div>
      </ThemeProvider>
    </>
  );
}
